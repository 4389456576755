import React, { useEffect, useState } from "react";
import Ovbanner, { Oeligibility } from "../components/Ovbanner";
import OvBenefits from "../components/Ovbenefits";
import Ovedge from "../components/Ovedge";
import Ovhistory from "../components/Ovhistory";
import Ovpostbanner from "../components/Ovpostbanner";
import Features from "../components/Features";
import OvTestimonials from "../components/Ovtestimony";
import axios from "axios";
import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";

const O1visa = () => {
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(
    () => fetchAllCountries(),
    // eslint-disable-next-line
    []
  );

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <NavBar />
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <Oeligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>
      <Ovbanner open={open} setOpen={setOpen} />
      <Ovpostbanner open={open} setOpen={setOpen} />
      <OvBenefits />
      <Ovedge />
      <Ovhistory open={open} setOpen={setOpen} />
      <Features />
      <OvTestimonials
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <Footer />
    </div>
  );
};

export default O1visa;
