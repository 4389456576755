import React, { useState, useEffect } from "react";
import { SelectCurrencyThree } from "../inputs/selectCurrency";
import { Platformbutton } from "../helpers/button/button";
import axios from "../api/axios";
import { Notificationmodal } from "../helpers/modal/simplifiedmodal";
import { FaTimes } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { GoArrowRight } from "react-icons/go";

export const PaymentSection = (props) => {
  const [rate, setRate] = useState(null);

  const [selected, setSelected] = useState({
    currencyFlag:
      "https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png",
    currency: "USD",
  });

  const LotteryRegistration = props.h1bFee?.fee[1].amount / 100;
  const RecruiterFee = props.isChecked ? 0 : props.h1bFee?.fee[2].amount / 100;

  const totalAmount = LotteryRegistration + RecruiterFee;

  const currencies = [
    {
      currencyFlag:
        "https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png",
      currency: "USD",
    },
    {
      currencyFlag: "https://flagcdn.com/ng.svg",
      currency: "NGN",
    },
    {
      currencyFlag: "",
      currency: "Others",
    },
  ];

  const submitApplication = async () => {
    var url =
      "https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents-no-auth";

    const formData = new FormData();
    formData.append("immigrationPassport", props.selectedFiles[0]);
    formData.append("visaType", "H1B");

    // formData.append("email", props.state.email);

    formData.append("submitted", false);
    formData.append("hasWatchedPathwayVideo", true);

    formData.append(
      "hasCompanyFilling",
      props.state.recruitingCompany === "I have company filing for me"
        ? true
        : false
    );
    formData.append("dateOfBirth", props.state.dob);
    formData.append("fullname", props.state.fullName);
    // formData.append("lastName", props.state.lastName);
    formData.append("email", props.state.email);

    formData.append(
      "hasBachelorsDegree",
      props.state.degree === "Yes" ? true : false
    );
    // formData.append("middleName", props.state.middleName);
    formData.append(
      "companyFilling",
      props.state.recruitingCompany === "I have company filing for me"
        ? props.state.companyName
        : props.state.recruitingCompany
    );

    if (props.state.recruitingCompany === "I have company filing for me") {
      formData.append("companyFillingName", props.state.companyName);
      formData.append("companyFillingEIN", props.state.Ein);
      formData.append("companyFillingEmail", props.state.employerEmail);
      formData.append("companyFillingPhoneNumber", props.state.employerContact);
      formData.append("companyFillingAddress", props.state.EmployerAddress);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      // props.setStep(2);
      // localStorage.removeItem("mainStackPayment");
    } catch (error) {
      console.error("Error uploading file:", error);
      // dispatch(updateUploadedFiles({}));
    }
  };

  useEffect(() => {
    const fetchpriceData = async () => {
      // setIsSuccess(false);
      try {
        const response = await axios.get(
          `v1/vesti-fx/lists?currency=${
            selected.currency == "Others" ? "USD" : selected.currency
          }
           `
        );
        const usdConversionRate = response.data?.usdConversionRate;
        setRate(usdConversionRate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchpriceData();
  }, [selected.currency]);

  // const toWholeCurrency = (num) => num / 100;

  const toWholeCurrency = (num) => {
    // Divide by 100 to get the correct value
    const value = num / 100;

    // Format the number with commas as thousand separators
    const formattedValue = value.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  const handleCurrencyChange = (selectedCurrency) => {
    // setSelected(selectedCurrency);
  };

  const [openIframeFull, setOpenIframeFull] = useState(false);

  const handlePayment = async (formId) => {
    setOpenIframeFull(true);

    // props.setIsEmptyFieldErrCheck(true);

    // if (
    //   props.selectedFiles[0]?.size &&
    //   props.state.fullName &&
    //   props.state.email &&
    //   props.state.dob &&
    //   props.state.degree !== "No" &&
    //   props.state.recruitingCompany &&
    //   (props.state.recruitingCompany === "I have company filing for me"
    //     ? props.state.EmployerAddress
    //     : true) &&
    //   (props.state.recruitingCompany === "I have company filing for me"
    //     ? props.state.companyName
    //     : true) &&
    //   (props.state.recruitingCompany === "I have company filing for me"
    //     ? props.state.Ein
    //     : true) &&
    //   (props.state.recruitingCompany === "I have company filing for me"
    //     ? props.state.employerEmail
    //     : true) &&
    //   (props.state.recruitingCompany === "I have company filing for me"
    //     ? props.state.employerContact
    //     : true)
    // ) {
    //   submitApplication();
    //   setOpenIframeFull(true);
    // }

    // setOpenIframeFull(true);
    // isEmptyFieldErrCheck={props.isEmptyFieldErrCheck}
    //       setIsEmptyFieldErrCheck={props.setIsEmptyFieldErrCheck}
    //     const totalAmount = LotteryRegistration + RecruiterFee;

    //     const payload = {
    //       email: props.state.email,
    //       fullname: props.state.fullName,
    //       currency: selected.currency,
    //       amount: totalAmount * rate * 100,
    //       visaType: "H1B",
    //       paymentPercent: 100,
    //       amountPaid: totalAmount * rate * 100,
    //       // id: props.formId,
    //       paymentOption: "card",
    //       totalCost: totalAmount * rate * 100,
    //     };

    //     const url = `https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-visa-document-no-auth
    // `;

    // try {
    //   const res = await axios.post(url, payload);
    //   const { data } = res;
    //   setTimeout(() => {
    //     window.open(data?.data?.url);
    //   }, 100);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div>
      <Notificationmodal
        open={openIframeFull}
        onClick={() => setOpenIframeFull(false)}
      >
        <LiaTimesSolid
          onClick={() => setOpenIframeFull(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />{" "}
        <div className="relative bg-white w-full h-screen pt-9">
          <div className="h-[40rem] z-[100] w-[30rem] xxs:w-[95vw] x-scroll shadow-[0_3px_3px_3px_rgba(0,0,0,0.15)] mx-auto flex items-center justify-center border-0  overflow-hidden">
            <iframe
              src="https://staging.cpay.theclockchain.io/paying/yeah"
              className="h-full w-full x-scroll "
            ></iframe>
          </div>
        </div>
        {/* )} */}
      </Notificationmodal>

      <div className="relative w-full h-full">
        <div className="Hb1paymentSection">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#1D2939",
              fontWeight: "600",
              padding: "10px 10px",
            }}
          >
            <p className="text-[14px]">INVOICE</p>
            <p className="text-[14px]">Download</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
              borderBottom: "1px solid #d0d5dd",
            }}
          >
            <p>Currency</p>

            {/* <p style={{ color: '#1D2939', fontWeight: '600' }}>USD</p> */}

            <div className="">
              <SelectCurrencyThree
                setSelected={setSelected}
                selected={selected}
                options={currencies.map((item) => ({
                  value: item.currency,
                  label: "",
                  // image: __renderWalletSvg(props.selected?.name),
                  image: item.currencyFlag ? item.currencyFlag : "",
                  ...item,
                }))}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
              borderBottom: "1px solid #d0d5dd",
            }}
          >
            {" "}
            <p>Lottery Registration & Clarity Fees</p>
            <p style={{ color: "#1D2939", fontWeight: "600" }}>
              {selected?.currency === "USD" || selected.currency == "Others"
                ? "$"
                : "₦"}
              {toWholeCurrency(LotteryRegistration * rate * 100)}
            </p>
          </div>{" "}
          <div
            // className="h1bOptioonalFee"
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
            }}
          >
            {" "}
            <p>Recruiter’s Fee</p>
            <p style={{ color: "#1D2939", fontWeight: "600" }}>
              {selected.currency === "USD" || selected.currency == "Others"
                ? "$"
                : "₦"}
              {toWholeCurrency(RecruiterFee * rate * 100)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#1D2939",
              fontWeight: "600",
              padding: "10px 10px",
            }}
          >
            {" "}
            <p>Total amount</p>
            <p>
              {selected.currency === "USD" || selected.currency == "Others"
                ? "$"
                : "₦"}
              {toWholeCurrency(totalAmount * rate * 100)}
            </p>
          </div>
        </div>

        <div
          onClick={() => {
            handlePayment();
          }}
          className="bg-vestigreen text-white p-4 w-full flex items-center justify-center cursor-pointer text-center rounded-md text-[16px] font-[500]"
        >
          <GoArrowRight className="pay-icon mr-8" />
          Pay{" "}
          {selected.currency === "USD" || selected.currency == "Others"
            ? "$"
            : "₦"}
          {toWholeCurrency(totalAmount * rate * 100)}{" "}
          {!selected.currency === "USD" ||
          !selected.currency == "Others" ||
          props.isChecked ||
          selected.currency === "NGN" ? (
            ""
          ) : (
            <s className="ml-3 mr-2">$1000</s>
          )}
          and Submit
        </div>

        {/* <Platformbutton
        name={`Pay ${selected.currency === "USD" ? "$" : "₦"}${toWholeCurrency(
          totalAmount * rate * 100
        )}  `}
        type="normal"
        click={() => {
          handlePayment();
        }}
        disabled={!props.state.email || !props.state.fullName ? true : false}
      /> */}
      </div>
    </div>
  );
};
