import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import CardAd from "../../views/CardAd";
import { Link } from "react-router-dom";
import VestiCard from "../../assets/images/cards.png";
import dollarHand from "../../assets/flight/dollarhand.png";
import creditScore from "../../assets/flight/creditscore.png";

export const Flightvirtualcardadvert = () => {
  return (
    <div className=" relative">
      <div className="mb-6 h-6">
        <div className="w-full absolute   px-4 md:px-8 lg:px-20 xxl:px-52  vlg:px-64 pt-24 lg:pt-20 ">
          <h1 className=" text-3xl text-headcol font-fontSemi text-center ">
            Do More with Vesti
          </h1>
        </div>
      </div>

      <div className="mt-20">
        <CardAd
          leftSide={
            <div className="lg:pl-16 p-6">
              <div className="rounded-[30px] bg-[#060E42] w-full">
                <p className="text-xs mt-8 text-white font-medium text-center p-1 font-fontReg">
                  AVAILABLE TO REQUEST
                </p>
              </div>
              <h1 className="text-white text-4xl w-full lg:w-[90%] pt-6 font-fontSemi">
                Introducing the new Vesti GlobalGeng Mastercard.
              </h1>
              <p className="text-base font-extralight text-white pt-6 font-fontReg w-full lg:w-[80%]">
                Vesti gives humans the power to move overseas to 110 Countries.
              </p>
              <div className="pt-5 pb-8 flex items-center text-[#ffffff] font-fontReg text-xl hover:text-headcol">
                <Link to="/globalgeng-card">Learn more</Link>
                <BsArrowUpRight />
              </div>
            </div>
          }
          image={VestiCard}
          addStyle="flex flex-col lg:flex-row gap-4 lg:justify-between items-center bg-[#67A948] rounded-[30px]"
        />
      </div>

      <div className="mb-6 px-4 md:px-8 lg:px-20 xxl:px-52  vlg:px-64  pt-4">
        <div className="flex flex-col lg:flex-row justify-between items-center xxl:container xxl:mx-auto gap-4">
          <div className="w-full  bg-[#14290A] flex px-12 py-4 rounded-[12px] items-center justify-between">
            <h1 className=" font-[600] text-[22px] max-w-[300px] text-white">
              Create Global Geng Card to Enjoy Exclusive Didcount
            </h1>
            <img src={dollarHand} alt="" />
          </div>

          <div className="w-full  bg-[#195742] flex px-12 py-4 rounded-[12px] items-center justify-between">
            <h1 className=" font-[600] text-[22px] max-w-[300px] text-white">
              Start Your Credit Journey by Importing Your Credit Score
            </h1>
            <img src={creditScore} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
