import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ConfHeroImg from "../../assets/ProsConfImg/ConfHeroImg.png";
import calImg from "../../assets/ProsConfImg/calederImg.png";
import ConfLocImg from "../../assets/ProsConfImg/confLocImg.png";
import emptyDot from "../../assets/ProsConfImg/emptyDot.png";
import { Link as AnchorLink } from "react-scroll";
import { Phonenumber } from "../inputs/phoneinput";
// import PhoneInput from 'react-phone-number-input';
// import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import "../../components/inputs/phoneinput.scss";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import { Success } from "../helpers/success/success";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegCircle } from "react-icons/fa";
import { options } from "./prosperData";
import { GiPlainCircle } from "react-icons/gi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RadioButton, { SingleRadioOption } from "../inputs/radiobutton";
import { ConferencePayment } from "./Payment";
import "./styles.css";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

// import { RadioOption } from '../inputs/radiobutton';

export const ProsConfHero = (props) => {
  // Modal
  const [showModal, setShowModal] = useState(false);

  // phone number

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("August 25, 2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const [registerForm, setRegisterForm] = useState({
    FullName: "",
    CompanyName: "",
    JobTitle: "",
    EmailAddress: "",
    PhoneNumber: "",
    // PhoneNumber: {
    //     code: "",
    //     number: ""
    // },
    paymentAction: "",
    countryCode: "",
    currencyFlag: "",
    ticketAction: "",
  });

  const handleChange = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  // drop down
  const [ticketDropDown, setTicketDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleTicketDropDown = () => {
    setTicketDropDown(!ticketDropDown);
  };

  const handleTicketChange = (option) => {
    setSelectedOption(option);
    setTicketDropDown(false);
  };

  //   validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    return (
      registerForm.FullName.trim() !== "" &&
      isValidEmail(registerForm.EmailAddress) &&
      registerForm.PhoneNumber.trim() !== "" &&
      selectedOption !== null
    );
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  //   const handlePhoneNumberChange = (value) => {

  return (
    <>
      <Simplemodal
        open={showModal}
        onClick={() => {
          setShowModal(false);
        }}
      >
        <ConferencePayment
          setShowModal={setShowModal}
          registerForm={registerForm}
          selectedOption={selectedOption?.value}
        />
      </Simplemodal>
      <div
        id="registerForm"
        className="relative bg-cover bg-center h-full py-[3.5rem] md:py-[6rem] xl:py-[6rem] xlg:py-[6rem]"
        style={{ backgroundImage: `url(${ConfHeroImg})` }}
      >
        <div className="block md:flex md:items-center xl:flex xl:items-center xlg:flex xlg:items-center px-4 md:px-[4%] xl:px-[6%] xlg:px-[6%] py-[6%]">
          <div className="block">
            <h1 className="text-[45px] md:text-[45px] xl:text-[55px] xlg:text-[55px] font-bold text-[#FFFFFF] pb-2 w-[100%] md:w-[70%] xl:w-[70%]">
              Prosper Conference
            </h1>
            <p className="text-[#FFFFFF] text-[20px] sm:text-[18px] md:text-[20px] xl:text-[22px] xlg:text-[22px] md:w-[88%] xl:w-[78%] xlg:w-[70%] leading-[2.2rem]">
              Join dreamers and achievers from all walks of life to discover how
              to go from where you are to where you want to be.
            </p>
            <div className="flex items-center gap-4 md:gap-4 xl:gap-8 xlg:gap-14 py-4 md:py-3 xl:py-4 xlg:py-6 w-[90%]">
              <div className="flex items-center gap-2 xlg:gap-3 ">
                <img src={calImg} alt="" />
                <p className="text-[#FFFFFF]">November 9, 2024</p>
              </div>
              <div className="flex items-center gap-2 xlg:gap-3">
                <img src={ConfLocImg} alt="" />
                <p className="text-[#FFFFFF]">The Wheatbaker, Ikoyi, Lagos</p>
              </div>
            </div>
            <div className="flex items-center gap-4 xlg:gap-6 pt-0 md:pt-2 xl:pt-4 xlg:pt-4">
              {/* <button className='border rounded-md border-[#ffffff] text-[#FFFFFF] py-3 px-[9%]'>Partner with us</button> */}

              <AnchorLink
                spy={true}
                smooth={true}
                to="gatewaySection"
                className="bg-vestigreen rounded-md text-[#020202] py-4 px-[9%] cursor-pointer"
              >
                Learn more
              </AnchorLink>
            </div>
          </div>

          <div className="bg-[#FFFFFF] md:w-[100%] lg:w-[70%] xlg:w-[50%] rounded-md p-4 mt-8 ">
            <h1 className="text-[30px] font-bold">Register Now</h1>
            <div className="my-2">
              <input
                type="text"
                value={registerForm.FullName}
                placeholder="Full name *"
                className="border border-gray-400 rounded-md px-2 py-5 w-[100%] mb-4 outline-none"
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, FullName: e.target.value })
                }
              />
              <div className="xl:flex xl:items-center xlg:flex xlg:items-center gap-4 mb-2">
                <input
                  type="text"
                  value={registerForm.CompanyName}
                  placeholder="Company name"
                  className="border border-gray-400 rounded-md px-2 py-5 w-[100%] outline-none"
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerForm,
                      CompanyName: e.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  value={registerForm.JobTitle}
                  placeholder="Job title"
                  className="border border-gray-400 rounded-md px-2 py-5 w-[100%] outline-none"
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerForm,
                      JobTitle: e.target.value,
                    })
                  }
                />
              </div>
              <input
                type="text"
                value={registerForm.EmailAddress}
                placeholder="Email address *"
                className="border border-gray-400 rounded-md px-2 py-5 w-[100%] mb-4 outline-none"
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    EmailAddress: e.target.value,
                  })
                }
              />
              {registerForm.EmailAddress &&
                !isValidEmail(registerForm.EmailAddress) && (
                  <p className="text-red-500 text-[12px]">
                    Please enter a valid email address.
                  </p>
                )}

              <Phonenumber
                value={registerForm.PhoneNumber}
                country="NG"
                setValue={
                  (value) => {
                    setRegisterForm({ ...registerForm, PhoneNumber: value });
                  }
                  //   setRegisterForm({ ...registerForm, PhoneNumber: value })
                }
                inputClass="border rounded-md w-full outline-none"
                containerClass="mb-4 opacity-62 px-2px py-3px"
              />

              {/* <div>
      <PhoneInput
        flags={flags}
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        defaultCountry="US"
      />
    </div> */}

              <div className="relative w-full h-full md:max-w-md mx-auto">
                <div className="relative">
                  <div
                    className="border border-gray-400 rounded-md px-2 py-5 outline-none text-[#AEB4BE] my-4 flex justify-between items-center cursor-pointer"
                    onClick={handleTicketDropDown}
                  >
                    <p>
                      {selectedOption
                        ? selectedOption.label
                        : "Select your ticket"}
                    </p>
                    <RiArrowDropDownLine className="text-[26px]" />
                  </div>
                  {ticketDropDown && (
                    <div className="absolute h-[25vh] overflow-y-scroll custom-scrollbar top-[-10px] sm:top-[-50px] md:top-[-50px] lg:top-0 left-0 w-full border border-[#AEB4BE80] rounded-md text-[#AEB4BE] p-4 mt-[20%] shadow-lg bg-white z-10">
                      {options.map((option, index) => (
                        <div>
                          <div
                            key={index}
                            className={`flex justify-between items-center border border-gray-300 rounded-md py-4 cursor-pointer p-4 ${
                              index < options.length - 1 ? "mb-3" : ""
                            }`}
                            onClick={() => handleTicketChange(option)}
                          >
                            <div>
                              <p className="font-semibold text-[14px]">
                                {option.label}
                              </p>
                              <div className="flex items-center gap-1 text-[14px]">
                                <p className="text-green-600 font-bold">
                                  {option.price}
                                </p>
                                {option.discount && (
                                  <p className="text-gray-500 text-sm">
                                    ({option.discount})
                                  </p>
                                )}
                              </div>
                              {/* {option.info && <p className="text-gray-500 text-xs mt-1">{option.info}</p>} */}
                            </div>
                            {selectedOption === option ? (
                              <p className="bg-white border border-green-600 p-1 rounded-full">
                                <GiPlainCircle className="text-[8px] text-[#67A948]" />
                              </p>
                            ) : (
                              <FaRegCircle className="text-[16px] text-gray-400" />
                            )}
                          </div>
                          {index !== 2 && option.info && (
                            <div className="flex items-center gap-1 pb-3">
                              <IoMdInformationCircleOutline className="w-[5%] h-[5%]" />
                              <p className="text-gray-500 text-xs mt-1 w-[80%]">
                                {option.info}
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* <input type="text" placeholder='Phone number' className='border rounded-md px-2 py-3 w-[100%] mb-4 outline-none' /> */}

              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className={`bg-vestigreen rounded-md text-[#FFFFFF] py-4 w-[100%] ${
                  !isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isFormValid()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center px-4">
          <div className="absolute flex items-center justify-around px-4 py-4 md:py-8 xl:py-8 xlg:py-8 bg-[#FFFFFF] rounded-md shadow-lg w-[95%] sm:w-[96%] md:w-[92%] xl:w-[88%] xlg:w-[88%] mt-[24%] sm:mt-[14%] md:mt-[20%] xl:mt-[14%] xlg:mt-[14%]">
            <div className="grid justify-items-center">
              <h1 className="font-bold text-[24px] md:text-[34px] xl:text-[34px] xlg:text-[34px]">
                {timerDays}
              </h1>
              <p className="text-[#060E42]">
                <small>DAYS</small>
              </p>
            </div>

            <div className="grid gap-2">
              <img src={emptyDot} alt="" />
              <img src={emptyDot} alt="" />
            </div>

            <div className="grid justify-items-center">
              <h1 className="font-bold text-[24px] md:text-[34px] xl:text-[34px] xlg:text-[34px]">
                {timerHours}
              </h1>
              <p className="text-[#060E42]">
                <small>HOURS</small>
              </p>
            </div>

            <div className="grid gap-2">
              <img src={emptyDot} alt="" />
              <img src={emptyDot} alt="" />
            </div>

            <div className="grid justify-items-center">
              <h1 className="font-bold text-[24px] md:text-[34px] xl:text-[34px] xlg:text-[34px]">
                {timerMinutes}
              </h1>
              <p className="text-[#060E42]">
                <small>MINUTES</small>
              </p>
            </div>

            <div className="grid gap-2">
              <img src={emptyDot} alt="" />
              <img src={emptyDot} alt="" />
            </div>

            <div className="grid justify-items-center">
              <h1 className="font-bold text-[24px] md:text-[34px] xl:text-[34px] xlg:text-[34px]">
                {timerSeconds}
              </h1>
              <p className="text-[#060E42]">
                <small>SECONDS</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
