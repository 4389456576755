const CardAd = (props) => {
  return (
    <div className="w-full  px-4 md:px-8 py-7 lg:py-6 lg:px-20  xxl:px-32 vlg:px-64 pt-8 lg:pt-10">
      <div className="vlg:container  vlg:mx-auto">
        <div className={props.addStyle}>
          {props.leftSide}

          <div className="text-center px-6">
            <img className="" src={props.image} alt="vesti-mastercard" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAd;
