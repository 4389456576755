import React from "react";

const MerchantBanner = () => {
  return (
    <div className="pt-12">
      <h2 className="text-[42px] md:text-[52px] text-white leading-[110%] font-[550]">
        Make <span className="text-vestigreen">Migration Fee</span>{" "}
        <br className="hidden md:block" /> Payments In Just{" "}
        <br className="hidden md:block" />{" "}
        <span className="text-vestigreen">Minutes</span>
      </h2>
      <p className="w-fulll md:w-[520px] text-white text-[18px] leading-[150%] mt-6">
        Want To migrate to live, for school or for work ? Conveniently pay your,
        SEVIS fee, WES fees, Visa fees, IHS fees and numerous migration fees
        without hassle.
      </p>
    </div>
  );
};

export default MerchantBanner;
