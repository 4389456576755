import VestiDocs from "../components/VestiDocs";
import DownloadApp from "../views/DownloadApp";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import { FaCircle } from "react-icons/fa6";

const Disclosures = () => {
  const underlinedText = {
    textDecoration: "underline",
  };
  return (
    <div>
      <NavBar />
      <VestiDocs
        title="Terms of Service"
        date="Last Updated: Oct 17, 2023"
        link1="TERMS OF SERVICE"
        link2="PRIVACY POLICY"
        link3="AML"
        link4="SAFELOCK TERMS OF SERVICE"
        path1="/tos"
        path2="/privacy-policy"
        path3="/aml"
        path4="/sltos"
        mainBody={
          <div className="text-justify text-[#14290A]">
            <h1 className="text-2xl font-fontReg">
              1. Introduction and Acceptance
            </h1>
            <p className="py-10 font-fontLight">
              Unless otherwise agreed to in writing by Vesti Technology
              Solutions Inc. (“VESTI”), by using the website and portals of
              VESTI, you agree to the following Terms and Conditions of Use
              Policy without limitation or qualification. Please read these
              terms and conditions carefully before using VESTI’s website or
              portals. If you do not agree with the Terms and Conditions of Use
              Policy, you are not granted permission to use VESTI’s website and
              portals. All references in this Terms and Conditions of Use Policy
              to “we,” “us,” or “our” shall refer to VESTI and its subsidiaries.
              All references in this Terms and Conditions of Use Policy to “you”
              or “your” shall refer to the users of VESTI’s website and portals.
            </p>
            <h1 className="text-2xl font-fontReg">
              2. Exclusion of Warranties and Limitations of Liability
            </h1>
            <p className="py-10 font-fontLight">
              VESTI’s website and portals are provided by VESTI on an “as is”
              and “as available” basis. VESTI makes no representations or
              warranties of any kind, express or implied, as to the operation of
              VESTI’s website or portals or the information, content, materials,
              or services included thereon, including, but not limited to,
              implied warranties of merchantability and fitness for a particular
              purpose. In particular, VESTI does not warrant that VESTI’s
              website or portals, its servers, or email sent from VESTI are free
              of viruses or other harmful components. VESTI will not be liable
              for any damages of any kind arising from the use of, or inability
              to use, VESTI’s website or portals, including, but not limited to,
              direct, indirect, incidental, punitive, and consequential damages,
              whether in contract, tort, or otherwise. Certain state laws do not
              allow limitations on implied warranties or the exclusion or
              limitation of certain damages; accordingly, the above limitations
              or exclusions may not apply to you.
            </p>
            <h1 className="text-2xl font-fontReg">3. No-Refund Policy</h1>
            <p className="py-10 font-fontLight">
              VESTI has a strict no-refund policy for all payments made toward
              any and all Migration Pathway Services, including the Vesti
              Education Pathway Service, US-Alien with Extraordinary Ability
              Visa Service, Visiting Visas, Permanent Residency Visa Service,
              Study Visa Service (collectively referred to as the ‘Services’).
              <br />
              Upon commencement of Services, no part of any and all payments
              made shall be refundable. <br /> Upon cancellation of the Services
              with Vesti by you for any reason, any and all payments made will
              be forfeited. No refunds will be provided for any amounts already
              paid to VESTI for any reason, including but not limited to
              dissatisfaction with the service, change in circumstances, or
              change in intention. <br /> Exception to No-Refund Policy <br />{" "}
              VESTI acknowledges the client’s right to cancel only bookings,
              reservations, or orders for our Services requested in advance.{" "}
              <br /> A request for cancellation of bookings, reservations, or
              orders for our Services requested in advance will be subject to a
              charge of 20% of the payment made. Cancellation requests for
              bookings, reservations, or orders for our Services requested in
              advance should be made via VESTI’s email address: help@wevesti.com
              or any Vesti staff email address previously contacted with respect
              to the requested service.
              <br /> VESTI is committed to providing exceptional Services to our
              clients and strives to meet and exceed their expectations. If you
              have any questions or concerns about our no- refund policy, please
              feel free to contact us before engaging our services. <br />
              By engaging our services, you acknowledge and agree to the terms
              of our no-refund policy.
            </p>
            <h1 className="text-2xl font-fontReg">
              4. General Use of Website and Portal
            </h1>
            <p className="py-10 font-fontLight">
              In order to access certain features of VESTI’s website and
              portals, you may be required to provide information about yourself
              (such as identification or contact details) as part of the
              registration process, including to obtain a VESTI account to
              access certain sections of the website and portals, or as part of
              your continued use of such website or portal. You agree that any
              registration information you give to VESTI will always be
              accurate, correct, and up to date.
              <br />
              You agree to use VESTI’s website and portals only for purposes
              that are permitted by (a) this Terms and Conditions of Use Policy,
              and (b) any applicable law, regulation, or generally accepted
              practices or guidelines in the relevant jurisdictions (including
              any laws regarding the export of data or software to and from the
              United States or other relevant countries). <br />
              You agree not to access (or attempt to access) VESTI’s website or
              portals by any means other than through the interface that is
              provided by VESTI. You specifically agree not to access (or
              attempt to access) any part of VESTI’s website or portal through
              any automated means (including use of scripts or web crawlers) and
              shall ensure that you comply with the instructions set out in any
              robots.txt file present on such website or portals.
              <br />
              You agree that you will not engage in any activity that interferes
              with or disrupts VESTI’s website or portals (or the servers and
              networks which are connected to such website or portals). You
              agree that you will not reproduce, duplicate, copy, republish,
              upload, post, transmit, distribute, modify, sell, trade, or resell
              VESTI’s website or portals, or any portion, feature, or content
              thereof, for any purpose; provided that you may download one copy
              of the materials on VESTI’s website or portals on any single
              computer for your non-commercial use only and you may print one
              copy of the materials on VESTI’s website or portals for your non-
              commercial use only; further provided that you keep intact all
              copyright and other proprietary notices on any such downloaded or
              printed materials.
              <br />
              You agree that you are solely responsible for (and that VESTI has
              no responsibility to you or to any third-party for) any breach of
              your obligations under the Terms and Conditions of Use Policy and
              for the consequences (including any loss or damage which VESTI may
              suffer) of any such breach.
              <br />
              You must be 18 years or older to use VESTI’s website or portals
              unsupervised. If you are under the age of 18, you may only use
              such website and portals in conjunction with, and under the
              supervision of, your parents or guardians. You must be a human.
              VESTI accounts registered by “bots” or other automated methods are
              not permitted.
              <br />
              Your VESTI account may only be used by one person – a single VESTI
              account shared by multiple people is not permitted. You agree and
              understand that you are responsible for maintaining the
              confidentiality and security of your VESTI account and password,
              if any. VESTI cannot and will not be liable for any loss or damage
              from your failure to comply with this security obligation. You
              agree that you will be solely responsible to VESTI for all
              activities that occur under your VESTI account.
              <br />
              One person or legal entity may not maintain more than one VESTI
              account. If you become aware of any unauthorized use of your VESTI
              account or password, you agree to notify VESTI immediately. <br />
              We reserve the right to modify or discontinue, temporarily or
              permanently, your access to VESTI’s website or portals, or your
              VESTI account with or without notice.
            </p>
            <h1 className="text-2xl font-fontReg">
              5. Comments, communications, and other content
            </h1>
            <p className="py-10 font-fontLight">
              Certain features within VESTI’s website or portals may allow you
              to post comments, send emails and other communications, and submit
              suggestions, ideas, comments, questions, or other information
              (together “Commenting”). You agree, understand and represent that
              all of your Commenting: (i) is accurate, (ii) is owned or
              otherwise controlled by you, (iii) is not illegal, obscene,
              threatening, defamatory, invasive of privacy, infringing of
              intellectual property rights, or otherwise injurious to
              third-parties, and (iv) does not consist of or contain software
              viruses, political campaigning, commercial solicitation, chain
              letters, mass mailings, or any form of &quot;spam.&quot; You may
              not use a false e-mail address, impersonate any person or entity,
              or otherwise mislead as to the origin of Commenting. VESTI
              reserves the right (but not the obligation) to monitor and edit or
              remove any Commenting activity or content. If you do post
              Commenting content or submit Commenting material, you grant VESTI
              a nonexclusive, royalty-free, perpetual, irrevocable, and fully
              sublicensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, and display
              such Commenting content throughout the world in any media,
              including the right to use the name that you submit in connection
              with such Commenting. VESTI takes no responsibility and assumes no
              liability for any Commenting content posted by you or any third-
              party.
            </p>
            <h1 className="text-2xl font-fontReg">6. T+1 Withdrawal Policy</h1>
            <p className="py-10 font-fontLight">
              Vesti’s Migration Savings Account Services is created purposely
              for saving towards migration pathways and making seamless
              migration payments.
              <br />
              Vesti has a strict T+1 withdrawal policy for all payments made
              using the Vesti Migrations Savings Account Services, (collectively
              referred to as the ‘ Account Services’).
              <br />
              This T+1 withdrawal policy prevents instant withdrawal on the
              Migration Savings Account until a minimum duration of 24 hours is
              reached after the initiation of the said withdrawal. This means
              that withdrawals are subject to at least a 24-hour waiting period
              before they are processed.
              <br />
              <span style={underlinedText}>Liability Clause</span>
              <br /> The company shall not be held liable for any delays,
              direct, indirect, incidental, consequential damages, or other
              unforeseen circumstances that may arise due to the minimum 24-hour
              withdrawal waiting period.
              <br /> <span style={underlinedText}>
                Additional Notes
              </span> <br /> This T+1 withdrawal policy covers withdrawals from
              the Naira (NGR), Cedis (GHC), Kwacha (ZMW), and Pounds (GBP) and
              subsequently created Wallets to external Financial Accounts.
              <br />
              Vesti reserves the right to modify or update the T+1 Withdrawal
              policy on the Migration Savings Account.
              <br /> If you have any questions or concerns about our T+1
              Withdrawal policy on the Migration Savings Account, please feel
              free to contact us at{" "}
              <span style={{ color: "#0080ff" }}>help@wevesti.com</span>. <br />
              By engaging our Services, you acknowledge and agree to the terms
              of our Migration Savings Account, T+1 Withdrawal Policy.
            </p>
            <h1 className="text-2xl font-fontReg">7. Safelock Terms of Use</h1>

            <ul className="py-10 flex flex-col gap-4 ">
              <li className=" flex gap-4 ">
                <FaCircle className="text-[8px] mt-2" />
                <p className="">
                  <span className="font-[600]">Saving Period and Access: </span>
                  <span className="font-fontLight">
                    Your savings will be locked for the duration you select in
                    your savings plan. Upon expiration of the locking period,
                    you will have full access to your saved amount plus any
                    accrued interest.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="w-[5px] h-[5px] mt-2" />
                <p className="">
                  <span className="font-[600]">Interest Accrual: </span>
                  <span className="font-fontLight">
                    Interest accrued will vary based on the Savings Type and
                    associated interest rate you select.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="text-[8px] mt-2" />
                <p className="">
                  <span className="font-[600]">Early Withdrawal: </span>
                  <span className="font-fontLight">
                    In exceptional circumstances, you may opt to withdraw funds
                    before the locking period expires. However, this will result
                    in loss of all accrued interest and may incur penalties.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="w-[4px] h-[5px] mt-2" />
                <p className="">
                  <span className="font-[600]">Loss of Interest: </span>
                  <span className="font-fontLight">
                    Early withdrawal results in forfeiture of any accrued
                    interest.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="text-[8px] mt-2" />
                <p className="">
                  <span className="font-[600]">User Responsibility: </span>
                  <span className="font-fontLight">
                    You are responsible for reviewing and understanding these
                    Terms of Use before creating a savings plan. Any account
                    actions taken, including early withdrawal, are at your own
                    discretion and risk.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="text-[8px] mt-2" />
                <p className="">
                  <span className="font-[600]">Modifications: </span>
                  <span className="font-fontLight">
                    Vesti reserves the right to modify these Terms of Use for
                    the Migration Savings Account at any time. Updated versions
                    will be posted on our website. You are advised to review
                    these terms periodically for changes.
                  </span>
                </p>
              </li>

              <li className=" flex gap-4 ">
                <FaCircle className="w-[5px] h-[5px] mt-2" />
                <p className="">
                  <span className="font-[600]">Contact Us: </span>
                  <span className="font-fontLight">
                    If you have any questions or concerns about these Terms of
                    Use or your Migration Savings Account, please contact us at
                    help@wevesti.com.
                  </span>
                </p>
              </li>
            </ul>

            <p className="pb-10 font-fontLight">
              Vesti reserves the right to modify or update the SafeLock policy
              on the Migration Savings Account. If you have any questions or
              concerns about our SafeLock policy on the Migration Savings
              Account, please feel free to contact us at help@wevesti.com. By
              using our services, you acknowledge and consent to the Terms of
              Use for Vesti’s Migration Savings Accounts and SafeLock policy
              herein.
            </p>

            <h1 className="text-2xl font-fontReg">8. Links</h1>
            <p className="py-10 font-fontLight">
              VESTI’s website and portals may be linked to other websites not
              maintained by us. We do not control and are not responsible for
              the content of and policies governing use of those websites not
              maintained by us. The inclusion of any link to such websites does
              not imply approval of or endorsement by us of the websites or the
              content thereof.
            </p>
            <h1 className="text-2xl font-fontReg">
              9. Ownership, Copyright and Trademark
            </h1>
            <p className="py-10 font-fontLight">
              All content included on VESTI’s website and portals, such as
              trademarks, service marks, trade names, text, graphics, logos,
              icons, button icons, images, data compilations, domain names, and
              software, is the property of VESTI or its content suppliers or
              used with permission. The compilation of all content on this site
              is the exclusive property of VESTI and may be protected by
              Nigerian and international copyright laws. All software used on
              this site is the property of VESTI or their software suppliers and
              may be protected by Nigerian and international copyright laws.
              Nothing on this site should be construed as granting any license
              or right to such content or software.
            </p>
            <h1 className="text-2xl font-fontReg">
              10. Copyright and Trademark Complaints
            </h1>
            <p className="py-10 font-fontLight">
              Vesti respects the intellectual property of others. If you believe
              that your work has been copied in a way that constitutes copyright
              infringement on VESTI’s website or portals, please notify us
              immediately on{" "}
              <span style={{ color: "#0080ff" }}>info@wevesti.com</span>
            </p>
            <h1 className="text-2xl font-fontReg">11. Site Technologies</h1>
            <p className="py-10 font-fontLight">
              VESTI does not provide the technologies used to build the website
              or portals, and therefore, neither recommends nor endorses the
              same. Any information regarding identified technologies, including
              their capabilities, limitations, and applications, should be
              sought directly from their manufacturers. VESTI hereby disclaims
              any rights to trademarks, service marks, trade names, logos,
              copyrights, patents, domain names, or other intellectual property
              interests of third-parties.
            </p>
            <h1 className="text-2xl font-fontReg">12. Applicable Laws</h1>
            <p className="py-10 font-fontLight">
              By visiting VESTI’s website or portals, you agree that the laws of
              Nigeria and the United States without regard to principles of
              conflict of laws, will govern this Terms and Conditions of Use
              Policy and any dispute of any sort that might arise between you
              and VESTI regarding such Policy.
            </p>
            <h1 className="text-2xl font-fontReg">13. Changes to the Terms</h1>
            <p className="py-10 font-fontLight">
              We reserve the right, at any time, to modify, alter, or update the
              terms and conditions of this Terms and Conditions of Use Policy
              without prior notice. Modifications shall become effective
              immediately upon being posted on our website and portals. Your
              continued use of the website and portals after modifications are
              posted constitutes an acknowledgement and acceptance of the Terms
              and Conditions of Use Policy and its modifications.
            </p>

            <h1 className="text-2xl font-fontReg">14. Disclaimer</h1>
            <p className="py-10 font-fontLight">
              Vesti operates differently from a traditional law firm. We offer
              limited immigration services through licensed attorneys, but our
              owners are not lawyers themselves. This may impact certain
              protections, such as attorney-client privilege. We are also
              independently owned and unaffiliated with any government agency.
              Before using our services, please carefully review our privacy
              policy, terms of use, and fee agreement. Remember, nothing on our
              website constitutes legal advice. For personalized legal guidance,
              always consult a licensed attorney.
            </p>
            {/* <h1 className="text-2xl font-fontReg">
              13. International Data Transfers
            </h1>
            <p className="py-10 font-fontLight">
              Our business is global with affiliates and service providers
              located around the world. As such, Personal Information may be
              stored and processed in any country where we have operations.
              Also, your Personal Data may be transferred to countries which may
              not have the same data protection laws as the country you
              initially provided your Personal Information, but whenever we
              transfer or transmit your Personal Information internationally, we
              will ensure or take reasonable steps to ensure your Personal
              Information is handled securely in line with the applicable data
              protection laws and standard contractual clauses.
            </p>
            <h1 className="text-2xl font-fontReg">
              14. Updates to our privacy policy
            </h1>
            <p className="py-10 font-fontLight">
              From time to time, we may change, amend, or review this Privacy
              Policy from time to time to reflect new services or changes in our
              Privacy Policy and place any updates on this page. All changes
              made will be posted on this page and where changes will materially
              affect you, we will notify you of this change by placing a notice
              online or via mail. If you keep using our Services, you consent to
              all amendments of this Privacy Policy.
            </p> */}
          </div>
        }
      />
      <DownloadApp />
      <Footer />
    </div>
  );
};

export default Disclosures;
