import React from "react";
import liberty from "../../assets/clarityCall/liberty.svg";
import ladyflag from "../../assets/clarityCall/ladywithflag.svg";
import { Link } from "react-router-dom";

function ClarityVisaTypes() {
  return (
    <div id="bookcallSection">
      <Visatypes
        toptitle="H1-B Visa Clarity Call Session"
        topDescription="Schedule a clarity call with our experts to guide you through the H-1B visa process. Whether it's understanding the application steps or adapting to a new culture, we're here to ensure a seamless transition."
        topButtonText="Book Call"
        onClick={`/clarity-session/H1B`}
        baseTitle="0-1 Visa Clarity Call Session"
        baseDescription="Book a clarity call with our experts to navigate your O-1 visa journey. From application details to cultural adjustment, we’ll ensure your transition is seamless and successful."
        baseButtonText="Book Call"
        click={`/clarity-session/0-1`}
      />
      <ReverseVisatypes
        toptitle="EB-1 Visa Clarity Call Session"
        topDescription="Book a clarity call with our experts to navigate the EB-1 visa process. From mastering the application requirements to settling into your new life, we’re here to support your journey every step of the way."
        topButtonText="Book Call"
        onClick={`/clarity-session/EB-1`}
        baseTitle="EB-2 NIW Visa Clarity Call Session"
        baseDescription="Schedule a clarity call with our experts to help you through the EB-2 NIW visa process. From meeting eligibility criteria to planning your move, we're here to ensure your journey is smooth and successful."
        baseButtonText="Book Call"
        click={`/clarity-session/EB-2 NIW`}
      />
    </div>
  );
}

const Visatypes = (props) => {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 lg:w-[90%]">
        <div className="flex flex-col gap-12">
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.toptitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.topDescription}
            </p>
            <Link
              to={props.onClick}
              className="normal-case text-white bg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center"
            >
              {props.topButtonText}
            </Link>
          </div>
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.baseTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.baseDescription}
            </p>
            <Link
              to={props.click}
              className="normal-case text-white w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center"
            >
              {props.baseButtonText}
            </Link>
          </div>
        </div>

        <div className="lg:w-[100%]">
          <img className="" src={liberty} alt="statue of liberty" />
        </div>
      </div>
    </div>
  );
};

const ReverseVisatypes = (props) => {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 lg:w-[90%]">
        <div className="lg:w-[100%]">
          <img className="" src={ladyflag} alt="statue of liberty" />
        </div>
        <div className="flex flex-col gap-12">
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.toptitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.topDescription}
            </p>
            <Link
              to={props.onClick}
              className="normal-case text-white bg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center"
            >
              {props.topButtonText}
            </Link>
          </div>
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.baseTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.baseDescription}
            </p>
            <Link
              to={props.click}
              className="normal-case text-white bg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center"
            >
              {props.baseButtonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClarityVisaTypes;
