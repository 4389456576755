import triple from "../../assets/images/triple.png";

const EB2edge = () => {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center py-20">
        <div className="flex flex-col ">
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-12 font-fontReg lg:w-[90%]">
              Cutting-edge approach to 
              <span className="text-[#67A948]"> EB2-NIW</span> ?
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              Experience the difference with our innovative platform and expert
              support. Say goodbye to the hassle of traditional EB2-NIW visa
              services.
            </p>
          </div>
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-12 font-fontReg lg:w-[90%]">
              Automated Immigration workflows Our advanced algorithms and team
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              Our advanced algorithms and team of experts make the EB2-NIW visa
              process easier and more efficient. Get the support you need, every
              step of the way.
            </p>
          </div>
        </div>

        <div className="lg:w-[100%]">
          <img className="" src={triple} alt="techstars" />
        </div>
      </div>
    </div>
  );
};

export default EB2edge;
