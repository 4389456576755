import React, { useRef, useState } from "react";
import dummyAvatar from "../../../assets/images/dummy-avatar.png";
import newsuccess from "../../../assets/logo-icons/newsuccess.svg";
import trans from "../../../assets/logo-icons/transto.svg";
import "./success.scss";
import { Platformbutton } from "../button/button";

export const Success = (props) => {
  return (
    <div className="success">
      <div className="success__inner">
        <img
          className="success__img w-[60%]"
          src={
            props.type === "new"
              ? newsuccess
              : props.image
              ? props.image
              : newsuccess
          }
          alt="success trophy svg"
        />
        <div
          className={`success__content ${
            props.type === "error" ? "--error" : ""
          }`}
        >
          <span className="success__body mt-10">
            <p>{props.title}</p>
            <p>{props.subtitle}</p>
            <p>{props.text}</p>
          </span>

          {props.onClick && (
            <Platformbutton
              name={props.button ? props.button : "Close"}
              type="normal"
              small="yes"
              click={() => props.onClick()}
            />
          )}
          {props.secondBtn && (
            <Platformbutton
              name={props.secondBtntext}
              type="secondary"
              small="yes"
              click={() => props.secondBtn()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const SuccessCopy = (props) => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyTransCodeToClipboard = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);
    props.onClick && props.onClick();
  };

  return (
    <div className="success">
      <div className="success__inner">
        <img
          className="success__img"
          src={
            props.type === "new"
              ? newsuccess
              : props.image
              ? props.image
              : newsuccess
          }
          alt="success trophy svg"
        />
        <div
          className={`success__content ${
            props.type === "error" ? "--error" : ""
          }`}
        >
          <span className="success__body">
            <p>{props.title}</p>
            <p>{props.subtitle}</p>
            {/* <p className="successdetail__title">{props.topic}</p> */}
            {props.link && (
              <div
                ref={board}
                className={`successdetail__box ${copied ? " copied" : ""}`}
                onClick={copyTransCodeToClipboard}
              >
                <p>{props.link}</p>
                <p>{copied ? "Copied" : " - Click to Copy Code - "}</p>
              </div>
            )}
          </span>

          {props.onClick && (
            <Platformbutton
              name={props.button ? props.button : "Close"}
              type="normal"
              small="yes"
              click={() => props.onClick()}
            />
          )}
          {props.secondBtn && (
            <Platformbutton
              name={props.secondBtntext}
              type="secondary"
              small="yes"
              click={() => props.secondBtn()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const Transuccess = (props) => {
  return (
    <div className="success">
      <div className="success__inner">
        <div className="relative flex items-center gap-[-150px] mb-2">
          <img
            src={trans}
            className="w-[40px] absolute top-[30%] bottom-0 left-0 right-0 mx-auto"
            alt="profile pic"
          />
          <img
            src={dummyAvatar}
            className="w-[80px] h-[80px]"
            alt="profile pic"
          />
          <div className=" w-[80px] h-[80px] ml-[-20px] flex items-center justify-center overflow-hidden">
            <img
              src={props.url}
              className="w-[100px] rounded-[100px]"
              alt="profile"
            />
          </div>
        </div>
        <div
          className={`success__content ${
            props.type === "error" ? "--error" : ""
          }`}
        >
          <span className="success__body">
            <p>{props.title}</p>
            <p>{props.subtitle}</p>
            <p>{props.text}</p>
          </span>

          {props.onClick && (
            <Platformbutton
              name={props.button ? props.button : "Close"}
              type="normal"
              small="yes"
              click={() => props.onClick()}
            />
          )}
          {props.secondBtn && (
            <Platformbutton
              name={props.secondBtntext}
              type="secondary"
              small="yes"
              click={() => props.secondBtn()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
