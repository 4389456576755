import Vector from "../assets/logo-icons/Vector.png";
import Vector1 from "../assets/logo-icons/Vector1.png";
import { ButtonTab } from "../components/Button";
import DoubleStrip from "../assets/logo-icons/doublestrip.png";
import Person from "../assets/logo-icons/person-icon.png";
import Badge from "../assets/logo-icons/kycbadge-icon.svg";
import Transact from "../assets/logo-icons/begintrans-icon.png";

const WhatWeOffer = () => {
  return (
    <div className="w-full px-4 md:px-8  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-12 lg:pt-28 bg-[#F7FFF3] py-20 relative">
      <div className="vlg:container  vlg:mx-auto">
        <img
          src={Vector}
          alt="shape"
          className="hidden md:block absolute top-0 left-0"
        />
        <ToCreateCard
          title="What we Offer"
          head1="Competitive Compensation"
          desc1="Be rewarded with competitive salary and benefits."
          head2="Meaningful Work"
          desc2="Make a difference with work that matters."
          head3="Career Growth"
          desc3="Chart your course with clear career growth paths."
          head4="Great Work Culture"
          desc4="Thrive in a culture of collaboration, support, and innovation."
          head5="Distributed Team"
          desc5="Join a globally connected workforce, embracing diversity and remote collaboration."
          head6="Health Insurance"
          desc6="Prioritize your well-being with comprehensive health coverage and wellness initiatives."
          head7="Learning & Development"
          desc7="Upskill with in-house training, mentorship, and conferences."
          head8="Paid Time Off"
          desc8="Recharge and reconnect with generous paid time off."
          head9="Work Tools"
          desc9="Work smarter with cutting-edge tools and technology."
          classname="flex flex-col justify-center items-center pt-10"
        />
        <div className="pb-4 md:pb-10 sm:pb-20 flex flex-col justify-center items-center">
          <ButtonTab
            // link="https://app.wevesti.com/auth/register"
            CTA="See all available roles"
            width="30%"
            styles="p-4 text-[#fff] mb-5"
          />
        </div>
        <img
          src={Vector1}
          alt="shape"
          className="hidden md:block absolute bottom-0 right-0"
        />
      </div>
    </div>
  );
};

const ToCreateCard = ({
  title,
  head1,
  desc1,
  head2,
  desc2,
  head3,
  desc3,
  head4,
  desc4,
  head5,
  desc5,
  head6,
  desc6,
  head7,
  desc7,
  head8,
  desc8,
  head9,
  desc9,
  classname,
}) => {
  return (
    <>
      <div className="container flex flex-col justify-center items-center">
        <div className={classname}>
          <h1 className="text-2xl lg:text-3xl text-center text-headcol font-fontReg w-[90%]">
            {title}
          </h1>
          <img src={DoubleStrip} alt="dashes" />
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-3 py-12 gap-y-10 font-fontReg">
          <div className="flex">
            <div>
              <img src={Transact} alt="person-icon" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head1}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc1}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Transact} alt="badge" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head2}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc2}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Badge} alt="transact" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head3}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc3}</p>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="grid md:grid-cols-3 lg:grid-cols-3 py-12 gap-y-10 font-fontReg"> */}
          <div className="flex">
            <div>
              <img src={Person} alt="person-icon" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head4}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc4}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Badge} alt="badge" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head5}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc5}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Transact} alt="transact" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head6}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc6}</p>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="grid md:grid-cols-3 lg:grid-cols-3 py-12 gap-y-10 font-fontReg"> */}
          <div className="flex">
            <div>
              <img src={Person} alt="person-icon" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head7}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc7}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Badge} alt="badge" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head8}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc8}</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={Transact} alt="transact" />
            </div>
            <div className="px-5">
              <h1 className="text-headcol text-lg font-fontReg">{head9}</h1>
              <p className="text-[#2B5219] font-fontLight text-sm">{desc9}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeOffer;
