// import FeaturedLogos from "../assets/logo-icons/feat-logos.png";
// import GlobalPageImage from "../assets/images/gpphone.png";
import React from "react";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Techstars from "../assets/logo-icons/techstars1.svg";
import Techcity from "../assets/logo-icons/techcity1.svg";
import Bloomberg from "../assets/logo-icons/bloomberg1.svg";
import { openLink } from "../helpers/helpers";
import heroGif from "../assets/images/showcaseGif.gif";
import { useNavigate } from "react-router-dom";
import { RxDotFilled } from "react-icons/rx";
import popUpIcon from "../assets/popUpIcon/H1bpopup.svg";

// import { MdArrowOutward } from "react-icons/md";
// import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
// import { NewsData } from "./newdata";
// import { MdCalendarMonth } from "react-icons/md";

const Showcase = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/h1b-lottery-payment");
  };
  return (
    <div className="bg-[#060E42] w-full px-4 md:px-8 pt-12 lg:pt-20 lg:px-20 xxl:px-32  vlg:px-64  text-white font-fontReg">
      <div className="vlg:container  vlg:mx-auto ">
        <Link to="/news-room">
          <div className="pt-12 lg:pt-6 text-center text-[14px] cursor-pointer hover:text-vestigreen">
            See Announcements 🎉
          </div>
        </Link>

        <div
          onClick={() => {
            handleClick();
          }}
          className="blur-bg w-[100%] mt-12 mb-[-3rem]  xlg:hidden cursor-pointer xlg:w-fit py-4"
        >
          <div className="text-[12px] text-vestigreen flex flex-col gap-2 justify-center items-center ">
            <div className="flex items-center gap-2">
              <img src={popUpIcon} alt="" className="h-6 w-6" />
              <p>Early H1B Registration with Vesti for 2025 is now open</p>
            </div>

            <p
              onClick={() => {
                handleClick();
              }}
              className=" cursor-pointer"
            >
              Click here to apply
            </p>
          </div>
        </div>

        <div className="grid bg:grid-cols-2 gap-20  lg:gap-0 xlg:gap-8">
          <div className="col-span-1 pt-6 sm:pt-20">
            <div
              onClick={() => {
                handleClick();
              }}
              className="blur-bg w-[100%] hidden xlg:block cursor-pointer xlg:w-fit py-4"
            >
              <div className="text-[13px]     text-vestigreen flex gap-2 justify-center items-center ">
                {/* <div className="flex items-center gap-2">
                    <p className="w-fit"> Move to the US </p>{" "}
                    <img src={popUpIcon} alt="" className="h-6 w-6" />{" "}
                    <p>as an H-1B Worker with minimum HND/Bachelors Degree </p>
                  </div> */}

                {/* <RxDotFilled className="text-white text-[9px]" /> */}

                <p>Early H1B Registration with Vesti for 2025 is now open</p>
                <RxDotFilled className="text-white text-[9px]" />

                <p
                  onClick={() => {
                    handleClick();
                  }}
                  className=" cursor-pointer"
                >
                  Click here to apply
                </p>
              </div>
            </div>
            <h1 className="text-4xl font-fontSemi lg:text-5xl bg:text-[36px] xl:text-[34px] pb-5  xlg:w-[80%] lg:font-fontBold !leading-[50px] lg:!leading-[60px] xlg:!leading-[50px]">
              {/* The */}
              <span className="text-[#67A948]">
                Financial services
              </span> for <br />
              <span className="text-[#67A948]"> 1 Billion </span> Immigrants
            </h1>
            <p className="text-lg pb-12 lg:w-[80%] lg:text-[22px] bg:text-[17px] font-sansec  ">
              Millions of people moving to new countries use Vesti for accessing
              financial services like Financial Accounts, debit and credit
              cards.
            </p>
            <Link
              // to="https://app.wevesti.com/auth"
              onClick={() =>
                window.open("https://app.wevesti.com/auth", "_blank")
              }
            >
              <Button className="font-fontLight text-base capitalize p-5 mb-16 w-[100%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]">
                Get started in minutes
              </Button>
            </Link>
            {/* <img src={FeaturedLogos} alt="featured" /> */}
            <div className=" flex flex-wrap mt-8  lg:mt-0 lg:mb-10  items-center opacity-80 gap-12 xl:gap-10">
              <img
                className="cursor-pointer"
                src={Techstars}
                alt="techstars"
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                  )
                }
              />
              <img
                className="cursor-pointer"
                src={Techcity}
                alt="techcity"
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                  )
                }
              />
              <img
                className="xxl:flex justify-center"
                src={Bloomberg}
                alt="bloomberg"
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                  )
                }
              />
            </div>
          </div>
          <div className="col-span-1 bg:relative ">
            <img
              className="w-full bg:absolute object-center  xlg:h-[100%] xxl:h-auto  xlg:w-[100%] bottom-0 right-0"
              src={heroGif}
              alt="globalpage-phone"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
