import React from "react";
import checkIcon from "../../assets/visaForm/circle-check.png";
import "./section.scss";

export const H1bNote = (props) => {
  return (
    <div className="noteSection" style={{ backgroundColor: "#F7FFF3" }}>
      <div>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#14290A",
          }}
        >
          Before you proceed you need to take notice of the following things:
        </p>
      </div>

      <ul
        style={{
          color: "#2B5219",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          fontSize: "14px",
          marginTop: "1rem",
        }}
      >
        <li
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />A Bachelor
          Degree/HND is mandatory to complete registration and enter the
          lottery.{" "}
        </li>

        <li
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          Real time updates on the progress of your documents review.
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          This is the first stage of your H1-B Lottery registration. This is not
          an application for the H1-B Visa.
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          Please enter your details as written on your International passport.
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          If you do not complete payment, you will not be entered into the
          lottery.
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          None of the listed employers are liable for any errors in your
          registration.
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          <p>
            Communication for this process is only through Vesti's official
            email only which is <span>talentvisa@wevesti.com.</span>
          </p>
        </li>

        <li
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
          You will be able watch a clarity session once your payment is
          completed.
        </li>
      </ul>

      <div
        style={{
          color: "#518C36",
          fontWeight: "600",
          fontSize: "14px",
          marginTop: "1rem",
        }}
      >
        For support:
        <ul
          style={{
            color: "#2B5219",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            fontSize: "14px",
            marginTop: "1rem",
          }}
        >
          <li
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "flex-start",
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
            Email talentvisa@wevesti.com.
          </li>

          <li
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "flex-start",
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: "6px" }} />
            <p>
              Chat with H1B registration support on WhatsApp.{" "}
              <a href="https://Wa.me/+19453450043" target="_blanck">
                Wa.me/+19453450043
              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
