import React from 'react'
import EdoLog from '../../assets/Partnership-Img/EdoInnovate.png'
import CChubLog from '../../assets/Partnership-Img/CCHub logo.png'
import premiaLog from '../../assets/Partnership-Img/PremiaBn.png'
import techLog from '../../assets/Partnership-Img/Techstars logo.png'

export const ProsperPart = () => {
  return (
    <div className='md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center py-2 md:py-4 xlg:py-12 px-4 md:px-[4%] xl:px-[6%] xlg:px-[6%]'>
        <h1 className='text-[26px] sm:text-[30px] font-bold text-center'>Our Partners</h1>
        <div className='flex items-center justify-between gap-4 w-[100%] py-6'>
            <img src={premiaLog} alt="" className='w-[19%] sm:w-[16%] md:w-[16%] xl:w-[12%] xlg:w-[12%]'/>
            <img src={CChubLog} alt="" className='w-[24%] sm:w-[21%] md:w-[21%] xl:w-[17%] xlg:w-[17%]'/>
            <img src={EdoLog} alt="" className='w-[19%] sm:w-[16%] md:w-[16%] xl:w-[12%] xlg:w-[12%]'/>
            <img src={techLog} alt="" className='w-[19%] sm:w-[16%] md:w-[16%] xl:w-[12%] xlg:w-[12%]'/>
        </div>
    </div>
  )
}
