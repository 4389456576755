import CreditCard from "../assets/logo-icons/credit-card-1.png";
import Wallet from "../assets/logo-icons/wallet-1.svg";
import Travel from "../assets/logo-icons/travel.png";
import Transaction from "../assets/logo-icons/transaction-2.png";
import Mortgage from "../assets/logo-icons/mortgage.png";
import Speedometer from "../assets/logo-icons/speedometer.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SERVICE = {
  AboutUs: "AboutUs",
  Careers: "Careers",
  NewsRoom: "NewsRoom",
  Team: "Team",
};

const CompanysDropdown = () => {
  const [service, setService] = useState(SERVICE.FINANCIAL);
  return (
    <div className="relative -z-10 p-12">
      <div className="absolute left-[48%] top-4 bg-[#F6F8FF] w-4 h-4 rotate-45 rounded-sm"></div>
      <div className="bg-[#F6F8FF] lg:w-[150px]  absolute top-[20px] left-0 rounded-lg p-[10px] flex flex-col lg:flex-row gap-2">
        <div className="rounded-lg w-[100%] flex flex-col gap-1">
          <div
            className={`${
              service === SERVICE.AboutUs ? "bg-white" : ""
            } p-2 relative rounded-md  text-center w-full`}
          >
            <Link
              to="/about-us"
              className="hover:underline font-fontReg hover:font-fontSemi text-base text-[#060E42] "
              onMouseEnter={() => setService(SERVICE.AboutUs)}
            >
              About Us
            </Link>
          </div>
          <div
            className={`${
              service === SERVICE.Careers ? "bg-white" : ""
            } p-2 relative text-center w-full`}
            // onClick={() => setService(SERVICE.MIGRATION)}
          >
            <h1 className="font-fontReg hover:font-fontSemi text-base text-[#060E42]">
              <Link
                className="hover:underline"
                to="/careers"
                onMouseEnter={() => setService(SERVICE.Careers)}
              >
                Careers{" "}
              </Link>
            </h1>
          </div>

          <div
            className={`${
              service === SERVICE.NewsRoom ? "bg-white" : ""
            } p-2 relative rounded-md text-center w-full`}
          >
            <h1 className="font-fontReg hover:font-fontSemi text-base text-[#060E42] ">
              <Link
                className="hover:underline"
                to="/news-room"
                onMouseEnter={() => setService(SERVICE.NewsRoom)}
              >
                News Room
              </Link>
            </h1>
          </div>

          <div
            className={`${
              service === SERVICE.Team ? "bg-white" : ""
            } p-2 relative rounded-md text-center w-full`}
          >
            <h1 className="font-fontReg hover:font-fontSemi text-base text-[#060E42]">
              <Link
                className="hover:underline"
                to="/team"
                onMouseEnter={() => setService(SERVICE.Team)}
              >
                Team
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanysDropdown;
