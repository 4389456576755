import React from "react";
import "./button.scss"


export const Platformbutton = (props)=> {

   
    var _renderbtn = () =>{

        switch (props.type){


            case 'seeall':
                return <button className={`platform seeall ${props.small && ' --small'}`} type={'button'} onClick={() => props.click()} disabled={props.disabled}>{props.name}</button>;
        

                case 'overview':
                    return <button className={`platform overview ${props.small && ' --small'}`} type={'button'} onClick={() => props.click()} disabled={props.disabled}>{props.name}</button>;
                
        

             case 'claim30days':
                return <button className={`platform claim30days ${props.small && ' --small'}`} type={'button'} onClick={() => props.click()} disabled={props.disabled}>{props.name}</button>;
                


            case 'daysbutton':
                return <button className={`platform daysbutton ${props.small && ' --small'}`} type={'button'} onClick={() => props.click()} disabled={props.disabled}>{props.name}</button>;
        
            case 'normal':
                return <button className={`platform platform_mainbtn ${props.small && ' --small'}`} type={'button'} onClick={() => props.click()} disabled={props.disabled}>{props.name}</button>;
            case 'submit':
                return <button className="platform platform_mainbtn" type={props.type ? props.type : ''} disabled={props.disabled}>{props.name}</button>
            case 'withicon':
                return <button className={`platform platform_withicon ${props.addOns}`} type={props.type ? props.type : ''} onClick={() => props.click()} disabled={props.disabled}>
                    {props.name} <i className={props.classname} />
                </button>
            case 'withiconnobg':
                return <button className="platform platform_withiconnobg" type={props.type ? props.type : ''} onClick={() => props.click()} disabled={props.disabled}>
                    {props.name} <i className={props.classname} />
                </button>
            case 'secondary':
                return  <button className={`platform platform_secbtn ${props.color && ' --'+props.color }`} disabled={props.disabled} onClick={()=> props.click()}>{props.name}</button>
            case 'slant':
                return <p className={`platform platform_slant`} disabled={props.disabled} onClick={()=> props.click()}>{props.name}</p>
            case 'link':
                return  <p className={`platform platform_link`} onClick={()=> props.click()}>{props.text} <strong>{props.title}</strong> </p>
            default:
                return <>Error No Button Type</>
        }
    }

    return (
        _renderbtn()
    )
    
}