import React, { useState } from 'react'
import axios from 'axios'
import referImg from '../../assets/Partnership-Img/referImg.png'
// import { use } from 'i18next'
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import { Success } from "../helpers/success/success";
import errorsvg from "../../assets/logo-icons/oops.svg";




export const Refer = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: ''
  })

  const [formMessage, setFormMessage] = useState('')
  const [formModal, setFormModal] = useState(false)
  const [errorMessage, setErrormessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e, formType) => {
    e.preventDefault()
    try {
      let url = ''

      if ( formType === 'partner' ) {
        url = 'https://syca-staging.wevesti.com/api/v1/general-services/partner-landing?typeAction=partnerwithus'
      }
       else if (formType === 'refer') {
        url = 'https://syca-staging.wevesti.com/api/v1/general-services/partner-landing?typeAction=referapartner'
      }

      const response = await axios .post(url, formData)
      setFormMessage(response.data.message)
      setFormModal(true)
      console.log(response.data)
      setIsSuccess(true)
    } catch (error) {
      // setErrormessage('An Error occurred')
      setFormModal(true)
      // console.error('Error', error)
      setErrormessage(error.response.data.message);
      setIsSuccess(false)

      console.log('Error messg',error.response.data.message);
    }
    
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      companyName: ''
    })
  }



  return (
    <div id='referAndPartnerSection' className='block md:flex md:items-center md:justify-around xl:flex xl:items-center xl:justify-around xlg:flex xlg:items-center px-4 md:px-8 xl:px-10 xlg:px-6 py-4 md:py-10 xlg:py-10 gap-6 xlg:pb-10 '>
        <img src={referImg} alt="" className='w-[100%] md:w-[58%] xl:w-[45%] xlg:w-[44%]'/>
        <div className='mt-4'>
            <h1 className='text-[30px] md:text-[23px] xl:text-[30px] xlg:text-[30px] font-bold'>Partner with Us or Refer a Startup</h1>
                <form onSubmit={handleSubmit} className='my-4'>
                    <div className='flex items-center gap-4 my-4'>
                        <input type="text" name='firstName' value={formData.firstName} onChange={handleChange} placeholder='First name *' className='border-2 border-[#AEB4BE] rounded-md bg-[#ebedf0] p-2 w-[100%] outline-none' />
                        <input type="text" name='lastName' value={formData.lastName} onChange={handleChange} placeholder='Last name *' className='border-2 border-[#AEB4BE] rounded-md bg-[#ebedf0] p-2 w-[100%] outline-none'  />
                    </div>
                    <div className='flex items-center gap-4 my-4'>
                        <input type="email" name='email' value={formData.email} onChange={handleChange} placeholder='Email *'  className='border-2 border-[#AEB4BE] rounded-md bg-[#ebedf0] p-2 w-[100%] outline-none' />
                        <input type="text" name='companyName' value={formData.companyName} onChange={handleChange} placeholder='Company name *' className='border-2 border-[#AEB4BE] rounded-md bg-[#ebedf0] p-2 w-[100%] outline-none' />
                    </div>
                    <div className='flex flex-col '>
                        <button onClick={(e) => handleSubmit(e, 'partner')} className='border border-vestigreen rounded-md w-[100%] bg-vestigreen text-[#FFFFFF] py-2 my-2'>Partner with Us</button >
                        <button onClick={(e) => handleSubmit(e, 'refer')} className='border-2 border-vestigreen rounded-md w-[100%]  text-vestigreen py-2 my-2'>Refer a Startup</button>
                    </div>

                    <Simplemodal
                      open={formModal}
                      onClick={() => {
                      setFormModal(false);
                      }}
                      >
  
                      {!isSuccess ? (
                        <>
                          <Success
                            image={errorsvg}
                            type="error"
                            title={errorMessage? errorMessage : 'An Error Occured'}
                            subtitle=""
                            button="Close"
                            onClick={() => {
                            setFormModal(false);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Success
                            title="form submitted successfully"
                            subtitle=" "
                            button="Close"
                            onClick={() => {
                            setFormModal(false);
                            }}
                          />
                        </>
                      )}
                      </Simplemodal>
                </form>
            
        </div>
    </div>
  )
}
