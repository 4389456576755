import React from 'react'
import { BenefitCard } from './PartCards'

export const Benefits = () => {
  return (
    <div className='md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center px-4 py-2 sm:py-2 md:py-4 xl:py-6 xlg:py-6'>
        <div className=' border-2 border-[#D9D9D9] rounded-[1.5rem] sm:rounded-[2.5rem] md:rounded-[3rem] xl:rounded-[3rem] xlg:rounded-[3rem] w-[100%] md:w-[96.5%] xl:w-[92%] xlg:w-[90.5%] py-2 sm-py-3 md:py-3 xl:py-3 xlg:py-3'>
            <div className='flex items-center justify-center py-2 sm:py-3 md:py-3 xl:py-3 xlg:py-3 text-[18px] sm:text-[25px] md:text-[27px] xl:text-[30px] xlg:text-[30px] font-bold'>
            <h2 className='border-r-2 border-[#D9D9D9] px-2 sm:px-[8%] md:px-[10%] xl:px-[12%] xlg:px-[14%]'>Benefits for You</h2>
            <h2 className='px-2 sm:px-[8%] md:px-[10%] xl:px-[12%] xlg:px-[14%]'>For Your Startups</h2>
            </div>
        </div>
        <div className='grid gap-4 leading-8 sm:grid-cols-2 sm:gap-6 md:grid-cols-2 md:gap-6 xl:grid-cols-2 xl:gap-6 xlg:grid-cols-2 xlg:gap-6 w-[100%] md:w-[96%] xl:w-[80%] xlg:w-[80%] sm:pl-8 md:pl-16 xl:pl-16 xlg:pl-16 py-6 sm:py-8 md:py-10 xl:py-10 xlg:py-10'>
            {BenefitCard.map(({image, title, details}, index) => {
                return <div key={index} className=''>
                    <div className='flex items-center gap-4'>
                        <img src={image} alt="" className='border-2 border-vestigreen rounded-full p-2 w-[13%] h-[13%] sm:w-[13%] sm:h-[13%] md:w-[11%] md:h-[11%] xl:w-[8%] xl:h-[8%] xlg:w-[8%] xlg:h-[8%]'/>
                        <div className='block'>
                            <h6 className='text-[17px] sm:text-[20px] md:text-[20px] xl:text-[20px] xlg:text-[20px] font-semibold'>{title}</h6>
                            <p className='sm:w-[85%] md:w-[80%] xl:w-[75%] xlg:w-[75%] text-[#7D7D7D]'>{details}</p>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
  )
}
