import img1 from "../../assets/element/1.svg";
import img2 from "../../assets/element/2.svg";
import img3 from "../../assets/element/3.svg";

export const data = [
  {
    id: 1,
    img: img1,
    title: "Globally Accepted",
    text: "A physical card you can use in over 90 countries.",
  },
  {
    id: 2,
    img: img2,
    title: "Multiple Currencies",
    text: "Make trasanctions in both Naira and USD",
  },
  {
    id: 3,
    img: img3,
    title: "Fair Rates",
    text: "Our rates are really affordable to cater to everyone’s needs",
  },
];
