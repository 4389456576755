import tunji from "../../assets/H1bPetitionImg/tunji.png";
import blessing from "../../assets/H1bPetitionImg/blessing.png";
import erucharia from "../../assets/H1bPetitionImg/eucharia.png";
import marcel from "../../assets/H1bPetitionImg/marcel.png";
import mosesImg from "../../assets/H1bPetitionImg/moses.png";

export const H1bUsersData = [
  {
    avatarImg: tunji,
    name: "Tunji T.",
    review:
      "I am a process manager at a Nigerian bank, I never considered lotteries until Adeola's ad. Im really impressed by how Vesti made the process, I decided to take a chance and won! Now, I hope Vesti helps me secure a visa and build a new life with my family.",
    background: "#F7FFF3",
    border: "#67A948",
  },
  {
    avatarImg: blessing,
    name: "Blessing A.",
    review:
      "l am a copy writer, a social media strategist & an African fashion stylist. Vesti, I must say is too good to be true. At first I doubted, but through the whole process, I am super excited that I am among the 200 persons chosen. I highly recommend Vesti. I can't wait to finally be in the USA. All thanks to Vesti.",
    background: "#F6F7FF",
    border: "#16216B",
  },
  {
    avatarImg: marcel,
    name: "Marcel S.",
    review:
      "My name is Marcel Suhbang, a Cameroonian living and residing in the UAE. Currently I am an Occasional Health and Safety Professional. At first thought it was joke until I received the congratulatory email of my selection for the H1B Lottery.",
    background: "#FBF3FF",
    border: "#3F1951",
  },
  {
    avatarImg: erucharia,
    name: "Eucharia C.",
    review:
      "Am Eucharia, from Nigeria. I am currently working as a Marketer with a communication Company. Vesti is indeed wonderful with their service, I really appreciate their quick response to email.. My heart is filled with joy and I give all thanks to GOD Almighty for making it possible. It's GOD although...",
    background: "#F7FFF3",
    border: "#67A948",
  },
  {
    avatarImg: mosesImg,
    name: "Moses N.",
    review:
      "My name is Moses Ngwu from Nigeria and I work as CyberSecurity Engineer. Vesti made the process of application very seamless for me. H1B Visa will give me the opportunity to be able to take advantage of the numerous technology job available in America.",
    background: "#F6F7FF",
    border: "#16216B",
  },
];

// faqs
export const H1bQuest = [
  {
    title:
      "What are the different types of specialty occupations that qualities for an H1B Visa ",
    ans: (
      <ul className="flex flex-col gap-4">
        <li>
          The specialty occupations that qualify for an H-1B visa are those that
          require the theoretical and practical application of a body of highly
          specialized knowledge. These occupations typically require at least a
          bachelor's degree or equivalent. Some examples of specialty
          occupations that qualifies for an H-1B visa include:
        </li>
        <li>
          1. Engineering: Positions such as software engineer, mechanical
          engineer, electrical engineer, and civil engineer may qualify as
          specialty occupations.
        </li>
        <li>
          2. Information Technology: Jobs in the IT field, such as computer
          systems analyst, database administrator, network administrator, and
          software developer.
        </li>
        <li>
          3. Science: Scientific roles like research scientist, biotechnologist,
          chemist, and physicist.
        </li>
        <li>
          4. Medicine and Healthcare: Certain medical professions, including
          doctors, dentists, pharmacists, and physical therapists, may qualify
          for an H-1B visa.
        </li>
        <li>
          5. Business and Finance: Some positions in business and finance, such
          as financial analyst, market research analyst, management consultant,
          and accountant, may also be considered as specialty occupations.
        </li>
      </ul>
    ),
  },
  {
    title: "What is the H1B Visa Cap?",
    ans: (
      <ul className="flex flex-col gap-4">
        <li>
          The H-1B visa cap refers to the annual numerical limit on the number
          of H-1B visas that are issued by the United States. Here is the
          information about the H-1B visa cap:
        </li>
        <li>
          - The regular cap for H-1B visas is set at 65,000 visas per fiscal
          year.
        </li>
        <li>
          - In addition to the regular cap, there are an additional 20,000 slots
          available for individuals with advanced degrees from U.S.
          institutions. This is known as the advanced degree exemption.
        </li>
        <li>
          - Once the H-1B cap is reached, which often happens quickly due to
          high demand, a random lottery system is used to select the petitions
          that will be processed for approval.
        </li>
        <li>
          - The lottery system is conducted to ensure fairness and equal
          opportunity for all applicants.
        </li>
      </ul>
    ),
  },
  {
    title: "Can I apply for a green card while on a H1B Visa?",
    ans: (
      <ul className="flex flex-col gap-4">
        <li>
          Yes, as an H-1B visa holder, you can apply for a green card (permanent
          residency) in the United States. The process for H-1B visa holders to
          apply for a green card typically involves the following steps:
        </li>
        <li>
          1. Employer Sponsorship: Your employer will need to sponsor you for
          permanent residency by filing a labor certification and an immigrant
          petition on your behalf.
        </li>
        <li>
          2. Labor Certification: The employer will need to obtain a labor
          certification from the U.S. Department of Labor. This certification
          verifies that there are no qualified U.S. workers available for the
          position being offered to you.
        </li>
        <li>
          3. Immigrant Petition: Once the labor certification is approved, your
          employer will file an immigrant petition, such as Form I-140, with
          U.S. Citizenship and Immigration Services (USCIS). This petition
          establishes your eligibility for permanent residency based on your
          qualifications and the job offer.
        </li>
        <li>
          4. Adjustment of Status or Consular Processing: After the immigrant
          petition is approved, you can choose to apply for adjustment of status
          if you are already in the United States, or go through consular
          processing if you are outside the country. Adjustment of status allows
          you to apply for a green card while remaining in the U.S., while
          consular processing requires you to attend an interview at a U.S.
          embassy or consulate in your home country.
        </li>
      </ul>
    ),
  },
  // {
  //   title: "Can I apply for a green card while on an H-1B visa?",
  //   ans: "An H-1B visa is initially valid for three years and can be extended for up to six years.",
  // },
];
