import img1 from "../../assets/flight/London.jpg";
import img2 from "../../assets/flight/Gatwick.jpg";
import img3 from "../../assets/flight/toronto.jpg";
import img4 from "../../assets/flight/Capetown.jpg";
import img5 from "../../assets/flight/chicago.jpg";

import img6 from "../../assets/flight/Johannesburg.jpg";

export const MyflightOffer = [
  {
    id: 4,
    img: img6,

    title: "Lagos to Johannesburg",
    text: (
      <>
        Economy, One way Commissionable tickets are available through Royal Air
        Maroc for this upcoming flight.
      </>
    ),
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "4 - 20 September 2024",
    price: "₦1,207,421.00",
  },

  {
    id: 2,
    img: img1,
    title: "Lagos to London",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "23 September - 8 October 2024",
    price: "₦1,179,984.00",
  },
  {
    id: 1,
    img: img2,
    title: "Abuja to London",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "12 - 30 September 2024",
    price: "₦1,535,379.00",
  },
  {
    id: 3,
    img: img5,
    title: "Lagos to London (Gatwick)",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "18 - 30 September 2024	",
    price: "₦1,380,289.00",
  },

  {
    id: 8,
    img: img3,

    title: "Lagos to Toronto",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "9 - 30 September 2024",
    price: "₦2,728,819.00",
  },

  {
    id: 7,
    img: img5,

    title: "Lagos to Chicago",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "16 - 28 September 2024",
    price: "₦2,896,232.00",
  },
];
