import React from "react";
import sendmoneyPhone from "../../assets/element/sendmoneyE.jpg";
import virtualcardPhone from "../../assets/element/elementvirtualcardphone.svg";

export const ElementFeature = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
        <div className="flex flex-col gap-8 xxl:gap-16 sm:flex-row vlg:container vlg:mx-auto justify-between items-center">
          <div className="bg-[#F9F1FF] pt-8 w-full sm:w-[50%] px-6 bg:px-12 rounded-[16px]">
            <h2 className="text-[#20082B] text-[22px]  leading-[32px]  md:leading-[42px] font-[600] md:text-[24px]  ">
              Seamlessly Add and Send Money
            </h2>
            <p className="text-[#3F1951] my-2 ">
              "Element by Vesti" grants you the ability to add and send money
              with unparalleled ease. Experience the freedom of managing your
              funds with a few clicks, no matter where you are.
            </p>

            <img src={sendmoneyPhone} alt="" className="mx-auto mt-8" />
          </div>

          <div className="bg-[#FFF7E7] pt-8 px-6 bg:px-12 w-full mt-[2rem] sm:mt-0 sm:w-[50%] rounded-[16px]">
            <h2 className="text-[#060E42] text-[22px] leading-[32px]  md:leading-[42px] font-[600] md:text-[24px]  ">
              Access Physical and Virtual Cards
            </h2>
            <p className="text-[#16216B] my-2">
              Empower your transactions with flexibility. Gain access to both
              physical and virtual cards, tailored to match your dynamic
              business needs. Enjoy the convenience of swift payments, both
              online and offline.
            </p>

            <img src={virtualcardPhone} alt="" className="mx-auto mt-8" />
          </div>
        </div>
      </section>
    </>
  );
};
