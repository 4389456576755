import React, { useState, useEffect } from "react";
import { SmallSingleselect } from "../inputs/singleselect";
import axios from "../api/axios";
import avatar from "../../assets/Event-Img/avatar.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import catIcon from "../../assets/Event-Img/catIcon.png";
import dot from "../../assets/Event-Img/emptyDot.png";
import dateIcon from "../../assets/Event-Img/dateIcon.png";
import locationIcon from "../../assets/Event-Img/LocIcon.png";
import { IoMdClock } from "react-icons/io";



export const UpcomingEvent = () => {
  const [eventOption, setEventOption] = useState("");
  const [eventData, setEventData] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [arrowUpColor, setArrowUpColor] = useState(false);
  const [arrowDownColor, setArrowDownColor] = useState(false);

  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const [currentPastPage, setCurrentPastPage] = useState(1);

  const [filteredUpcomingEvents, setFilteredUpcomingEvents] = useState([]);
  const [filteredPastEvents, setFilteredPastEvents] = useState([]);

  const [limit, setLimit] = useState(6);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNoOfPage, setTotalNoOfPage] = useState("");

  useEffect(() => {
    setFilteredUpcomingEvents(
      eventData.filter((event) => event.status === "Upcoming")
    );
    setFilteredPastEvents(eventData.filter((event) => event.status === "Past"));
  }, [eventData, currentUpcomingPage, currentPastPage]);

  const handleEventClick = () => {
    window.open(
      "https://us06web.zoom.us/meeting/register/tZ0tcOCtpz8iG9b3MG4GRW-OToFZbSzfDp9w#/registration"
    );
  };

  const handleDownArrow = () => {
    setLimit(limit + 3);
    setArrowUpColor(false);
    setArrowDownColor(true);
    setPageNumber(
      pageNumber === totalNoOfPage ? totalNoOfPage : pageNumber + 1
    );
  };
  const handleUpArrow = () => {
    setLimit(limit > 6 ? limit - 3 : 6);
    setArrowUpColor(true);
    setArrowDownColor(false);
    setPageNumber(pageNumber === 1 ? 1 : pageNumber - 1);
  };

  useEffect(() => {
    const url = `/v1/vesti-events/all-events?page=1&limit=${limit}&${eventOption.toLocaleLowerCase()}=${eventOption}`;

    const fetchEvents = async () => {
      try {
        const res = await axios.get(url);
        const total = res.data.events.pagination.total;
        setTotalNoOfPage(Math.ceil((total - 6) / 3 + 1));
        setEventData(res.data.events.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEvents();
  }, [eventOption, limit]);

  const [myIndex, setmyIndex] = useState(0);

  const toggleDescription = (index) => {
    setmyIndex(index);

    if (myIndex === index) {
      setShowFullDescription(!showFullDescription);
    }
  };

  return (
    <div className="mt-10 px-4 md:px-8 lg:px-20 xxl:px-32  vlg:px-64">
      <div className="flex items-center justify-between">
        <h1 className="font-extrabold text-2xl lg:text-3xl">Explore Events</h1>
        <div className="w-[100px]">
          <SmallSingleselect
            onChange={(e) => {
              const selectedOption = e.value;
              setEventOption(selectedOption);
              console.log(e);
              if (selectedOption === "Upcoming") {
                setCurrentUpcomingPage(1);
              } else if (selectedOption === "Past") {
                setCurrentPastPage(1);
              }
            }}
            options={[
              { label: "Past", value: "Past" },
              { label: "Upcoming", value: "Upcoming" },
            ]}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-2 xlg:grid-cols-3 h-full gap-8 mt-10">

        
        {eventData.length > 0 &&
          filteredUpcomingEvents.map(
            (
              {
                bannerImg,
                logoImg,
                category,
                imgIcon,
                date,
                locIcon,
                location,
                title,
                description,
                status,
                time,
              },
              index
            ) => {
              let Filtereddate = new Date(date);
              let formattedDate = Filtereddate.toISOString().split("T")[0];
              return (
                <>
                  <div
                    key={index}
                    className={`grid border  border-[#DDDEDD] rounded-md p-6 ${
                      showFullDescription && myIndex === index
                        ? "h-[100%]"
                        : "h-[100%]"
                    }  md:${
                      showFullDescription && myIndex === index
                        ? "h-[100%]"
                        : "h-[30rem]"
                    }`}
                  >
                    <div className="relative w-full h-[200px]">
                      <img
                        src={bannerImg}
                        alt=""
                        className="w-full h-full rounded-md object-cover"
                      />
                      <img
                        src={logoImg}
                        alt=""
                        className="absolute  z-[20] right-2 bottom-[-24px]"
                      />
                      <div className="absolute flex items-center gap-2 left-2 top-2 z-[20] bg-white px-2 py-[4px] rounded-md text-[#888988] text-[12px]">
                        <img src={catIcon} className="w-[8%]" alt="" />
                        <p>{category}</p>
                        <img src={dot} alt="" />
                        <img src={locationIcon} className="w-[8%]" alt="" />
                        <p>{location}</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between">
                      <div>
                        <div className="flex items-center justify-between text-[#666766] mt-8 text-[14px]">
                          <div className="flex items-center gap-2">
                            <img src={dateIcon} alt="" />
                            <p>{formattedDate}</p>
                          </div>

                          <div className="flex items-center gap-2">
                            {/* <img src={locIcon} alt="" /> */}
                            <IoMdClock
                              style={{
                                color: "white",
                                background: "#666766",
                                borderRadius: "20px",
                                fontSize: "16px",
                              }}
                            />
                            <span className="text-[#888988] text-[12px] font-[500]">
                              {time}
                            </span>
                          </div>
                        </div>
                        <div className="mt-2">
                          <h2 className="font-bold text-[20px]">{title}</h2>
                          {showFullDescription && myIndex === index ? (
                            <p className="text-[#666766] text-[14px]">
                              {description}
                              <button
                                className="text-vestigreen"
                                onClick={() => toggleDescription(index)}
                              >
                                {"show Less"}
                              </button>
                            </p>
                          ) : (
                            <p className="text-[#666766] text-[14px]">
                              {`${description.slice(0, 80)}...`}
                              <button
                                className="text-vestigreen"
                                onClick={() => toggleDescription(index)}
                              >
                                {"Learn More"}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center mt-3 text-[14px] justify-between">
                          <div className="flex items-center gap-2">
                            <img src={avatar} alt="" className="w-[30%]" />
                            <p className="text-[#666766]">100+ attending</p>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="flex items-center gap-6 mt-4 text-[14px]">
                          <button
                            className="bg-vestigreen text-white p-1.5 px-[5%] w-[100%] py-2 rounded-md cursor-pointer"
                            onClick={handleEventClick}
                          >
                            Register Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          )}

        {eventData.length > 0 &&
          filteredPastEvents.map(
            (
              {
                bannerImg,
                logoImg,
                category,
                imgIcon,
                date,
                locIcon,
                location,
                title,
                description,
                status,
              },
              index
            ) => {
              let Filtereddate = new Date(date);
              let formattedDate = Filtereddate.toISOString().split("T")[0];
              return (
                <div
                  key={index}
                  className={`grid border  border-[#DDDEDD] rounded-md p-6 ${
                    showFullDescription && myIndex === index
                      ? "h-[100%]"
                      : " h-[100%]"
                  }  md:${
                    showFullDescription && myIndex === index
                      ? "h-[100%]"
                      : "h-[30rem]"
                  }`}
                >
                  <div className="relative w-full h-[200px]">
                    <img
                      src={bannerImg}
                      alt=""
                      className="w-full h-full rounded-md object-cover"
                    />
                    <img
                      src={logoImg}
                      alt=""
                      className="absolute  z-[20] right-2 bottom-[-24px]"
                    />
                    <div className="absolute flex items-center gap-2 left-2 top-2 z-[20] bg-white px-2 py-[4px] rounded-md text-[#888988] text-[12px]">
                      <img src={catIcon} className="w-[8%]" alt="" />
                      <p>{category}</p>
                      <img src={dot} alt="" />
                      <img src={locationIcon} className="w-[8%]" alt="" />
                      <p>{location}</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div>
                      <div className="flex items-center gap-8 text-[#666766] mt-8 text-[14px]">
                        <div className="flex items-center gap-2">
                          <img src={dateIcon} alt="" />
                          <p>{formattedDate}</p>
                        </div>
                        <div className="flex items-center gap-2">
                          <img src={locIcon} alt="" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h2 className="font-bold text-[20px]">{title}</h2>
                        {showFullDescription && myIndex === index ? (
                          <p className="text-[#666766] text-[14px]">
                            {description}
                            <button
                              className="text-vestigreen"
                              onClick={() => toggleDescription(index)}
                            >
                              {"show Less"}
                            </button>
                          </p>
                        ) : (
                          <p className="text-[#666766] text-[14px]">
                            {`${description.slice(0, 80)}...`}
                            <button
                              className="text-vestigreen"
                              onClick={() => toggleDescription(index)}
                            >
                              {"Learn More"}
                            </button>
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center mt-3 text-[14px] justify-between">
                        <div className="flex items-center gap-2">
                          <img src={avatar} alt="" className="w-[30%]" />
                          <p className="text-[#666766]">100+ attending</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>

      <div className="text-center">
        {Object.keys(filteredUpcomingEvents).length < 1 &&
        eventOption === "Upcoming" ? (
          <div>No upcoming event</div>
        ) : (
          <div className="flex flex-col justify-center items-center w-[100%] py-10">
            <div className="flex items-center border-2 border-solid border-vestigreen rounded-lg w-[100%] sm:w-[60%] lg:w-[50%] xlg:w-[30%] p-2 text-[14px]">
              <p className="flex items-center gap-3 px-[32%] sm:px-[34%] lg:px-[36%] xlg:px-[34%] text-[14px]">
                Page {pageNumber < 1 ? 1 : pageNumber}
                <div className="block">
                  <IoIosArrowUp
                    className="cursor-pointer text-[16px]"
                    onClick={() => {
                      handleUpArrow();
                    }}
                    style={{ color: arrowUpColor ? "green" : "black" }}
                  />
                  <IoIosArrowDown
                    className="cursor-pointer text-[16px]"
                    onClick={() => {
                      handleDownArrow();
                    }}
                    style={{ color: arrowDownColor ? "green" : "black" }}
                  />
                </div>{" "}
                of {totalNoOfPage < 1 ? 1 : totalNoOfPage}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
