import React from "react";

const IframeComponent = ({ onClick }) => {
  return (
    <div className="iframe-Style">
      <iframe
        title="miai"
        style={{ height: "70vh", width: "100%" }}
        src="https://widget.writesonic.com/CDN/index.html?service-base-url=https://api.botsonic.ai&token=0d3f5bc3-2613-406e-bc3d-969c871d83d0&base-origin=https://bot.writesonic.com&instance-name=Botsonic&standalone=true&page-url=https://bot.writesonic.com/c968907a-c7ee-4099-af23-0b415f0a8099?t=share&workspace_id=1ab463ab-d5c8-4f0f-83d2-e4e9c770f271"
      ></iframe>
      <div className="button-container">
        <button className="popup-button" onClick={onClick}>
          Close
        </button>
      </div>
    </div>
  );
};

export default IframeComponent;
