import React from 'react';
import PhoneInput from 'react-phone-number-input';
import flags from "react-phone-number-input/flags";
import 'react-phone-number-input/style.css';
import './phoneinput.scss';
// import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js/core';
// import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
// export const Phonenumber = props => {
//   return (
//     <PhoneInput
//       className="my-input-class"
//       inputClass="my-input-class"
//       placeholder="Phone Number"
//       international
//       countryCallingCodeEditable={false}
//       // defaultCountry="NG"
//       defaultCountry={props.country ? props.country : 'NG'}
//       value={props.value}
//       // countries={['US', 'NG']}
//       flags={flags}
//       onChange={phone => props.setValue(phone)}
//     />
//   );
// };

export const Phonenumber = ({ country, value, setValue, inputClass, containerClass }) => {
  // console.log('tghdbgd',country);
  return (
    <div className={`${containerClass}`}>
      <PhoneInput
        className={`my-input-class ${inputClass}`}
        placeholder="Phone Number"
        international
        countryCallingCodeEditable={false}
        // defaultCountry="NG"
        defaultCountry={country ? country : 'NG'}
        value={value}
         // countries={['US', 'NG']}
        flags={flags}
        onChange={setValue}
      />
    </div>
  );
};

export const Phonenumberng = props => {
  return (
    <PhoneInput
      className="my-input-class"
      inputClass="my-input-class"
      placeholder="Phone Number"
      international
      countryCallingCodeEditable={false}
      defaultCountry="NG"
      value={props.value}
      countries={['NG']}
      flags={flags}
      onChange={phone => props.setValue(phone)}
    />
  );
};

export const Phonenumberdef = props => {
  return (
    <PhoneInput
      className="my-input-class"
      inputClass="my-input-class"
      placeholder="Phone Number"
      international
      countryCallingCodeEditable={false}
      defaultCountry={props.default}
      //   international={false}
      value={props.value}
      countries={props.countries}
      flags={flags}
      onChange={phone => props.setValue(phone)}
    />
  );
};
