import React, { useState, useEffect, useRef } from "react";
import { ImCheckboxChecked } from "react-icons/im";

export const SuccessRight = (props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const handleLoadedMetadata = () => {
      if (video) {
        video.currentTime = 28;
      }
    };
    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  return (
    <div>
      {" "}
      <div>
        <p
          style={{
            color: "#62943B",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Clarity Session Instructional Video
        </p>

        <div
          className="aboutpathwayimgbox"
          style={{ margin: "10px 0px", borderRadius: "10px" }}
        >
          <video
            ref={videoRef}
            className="video-wrapper"
            controls
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          >
            <source
              src={`https://storagefileforpathway.s3.amazonaws.com/videos/web/H1B+video.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <div style={{}}>
            <ImCheckboxChecked
              style={{
                background: "white",
                color: props.isClarityVideoChecked ? "#518C36" : "white",
                cursor: "pointer",
                border: "1px solid #518C36",
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                marginTop: "6px",
              }}
              onClick={() => props.handleVideoCheckboxChange()}
            />
          </div>
          <p
            style={{
              color: "#67A948",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            I have watched the video and I agree to Vesti’s{" "}
            <a
              href="https://wevesti.com/tos"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              Terms & Conditions{" "}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
