import React from 'react'
import FocusImg from '../../assets/Partnership-Img/Focus.png'
import { Link as AnchorLink } from "react-scroll";

export const PartFocus = () => {
  return (
    <div className='block md:flex md:items-center md:justify-around xl:flex xl:items-center xl:justify-around xlg:flex xlg:items-center p-4 py-12 sm:py-[8%] md:py-[8%] xl:py-[8%] xlg:py-[8%] gap-6'>
        <img src={FocusImg} alt="" className='rounded-md w-[100%] md:w-[47%] xl:w-[38%] xlg:w-[35%]' />
        <div className='block w-[100%] md:w-[43%] xl:w-[43%] xlg:w-[43%]'>
            <h1 className='text-[30px] md:text-[23px] xl:text-[30px] xlg:text-[30px] font-bold pt-2'>Focus on Growth, Not Visa Delays</h1>
            <p className='md:leading-7 leading-10 py-2 mb-1 xl:mb-6 xlg:mb-6'>Global founders often encounter legal complexities and delays with US immigration. 
               Vesti addresses this challenge by offering specialized immigration services tailored 
               to their needs, ensuring speed and efficiency. Through a strategic partnership with 
               Vesti, you can smooth the overall immigration journey experience for your startups, 
               from visa selection to obtaining a green card, enabling them to focus on building their 
               businesses without worrying about immigration hurdles.
            </p>
            <AnchorLink
              spy={true}
              smooth={true}
              to="referAndPartnerSection"
              className='border bg-vestigreen py-3 px-14 rounded-md text-[#FFFFFF] cursor-pointer'>
              Partner with Us</AnchorLink>
        </div>
    </div>
  )
}
