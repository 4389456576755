import React, { useState } from "react";
import { FormData } from "./TempForm.js";
import { SuccessRight } from "./successRight";
import newsuccess from "../../../assets/logo-icons/newsuccess.svg";

export const PetitionForm = () => {
  const [isClarityVideoChecked, setIsClarityVideoChecked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([{}]);
  const handleVideoCheckboxChange = () => {
    setIsClarityVideoChecked(!isClarityVideoChecked);
  };

  const [step, setStep] = useState(1);

  const [state, setState] = useState({
    fullName: "",
    email: "",
    dob: "",
    degree: "",
    recruitingCompany: "",
    companyName: "",
    Ein: "",
    EmployerAddress: "",
    employerEmail: "",
    employerContact: "",
  });

  switch (step) {
    case 1:
      return (
        <div
          className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  "
          id="h1bFormSection"
        >
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#14290A] font-bold text-[22px]">
              U.S. H1B Petition Stage 1 (Lottery Registration)
            </p>
            <p className="text-[#2B5219] font-bold text-[14px] mt-1">
              Fill out all fields in this form to be entered into the H1B
              Lottery
            </p>
          </div>

          <div className="flex flex-col bg:flex-row gap-10 w-full mt-12">
            <div className="bg:w-[35%]  bg:hidden w-full">
              <SuccessRight
                handleVideoCheckboxChange={handleVideoCheckboxChange}
                isClarityVideoChecked={isClarityVideoChecked}
              />
            </div>
            <div className="bg:w-[65%] w-full">
              <FormData
                isClarityVideoChecked={isClarityVideoChecked}
                setStep={setStep}
                state={state}
                setState={setState}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
            <div className="bg:w-[35%] hidden bg:block w-full">
              <SuccessRight
                handleVideoCheckboxChange={handleVideoCheckboxChange}
                isClarityVideoChecked={isClarityVideoChecked}
              />
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div
          className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  "
          id="h1bFormSection"
        >
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#14290A] font-bold text-[18px] bg:text-[22px]">
              U.S. H1B Petition Stage 1 (Lottery Registration)
            </p>
          </div>
          <div
            className=" mx-auto w-full  bg:w-[500px]"
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",

              gap: "20px",
            }}
          >
            <img src={newsuccess} alt="" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  color: "#67A948",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                Your H1B Early Lottery application documents have been submitted
                successfully
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#2B5219",
                }}
              >
                Your documents for the H1B petition have been successfully
                submitted. Complete your Vesti sign up to be able to track
                progress and watch video.
              </p>
            </div>
          </div>

          <div
            onClick={() =>
              window.open("https://app.wevesti.com/register", "_blank")
            }
            className="bg-[#66AA48] cursor-pointer text-white px-12 py-2 w-fit mx-auto rounded-md font-semibold"
          >
            Sign Up
          </div>
        </div>
      );
    default:
      break;
  }
};
