import { Button } from "@material-tailwind/react";
import lady from "../../assets/images/ladylaptop.png";

const EBpostbanner = (props) => {
  return (
    <div
      className="w-full px-4 md:px-8   lg:px-20 xxl:px-32  vlg:px-64 
"
    >
      <div className="vlg:container vlg:mx-auto flex flex-col lg:flex-row justify-between items-center pt-10 pb-8">
        <div className="lg:w-[45%]">
          <img className="" src={lady} alt="immigrants" />
        </div>
        <div className="lg:w-[45%] lg:pt-0 pt-10">
          <h1 className="text-[#14290A] text-3xl pb-12 font-fontReg lg:w-[90%]">
            Why use Vesti for your
            <span className="text-[#67A948]"> EB-1 Visa</span> ?
          </h1>
          <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
            We have built an Intuitive platform with real-time progress
            tracking. Easily track your EB-1 visa application progress and stay
            up-to-date in real-time. No more wondering about the status of your
            visa. Get personalized support from our team of experienced EB-1
            visa specialists. We will be there to help you every step of the
            way.
          </p>
          <div className="flex items-center text-[#67A948] text-xl font-fontReg hover:text-headcol">
            <Button
              onClick={() => props.setOpen(true)}
              className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
            >
              See if you qualify
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EBpostbanner;
