import { useEffect, useRef, useState } from 'react';

export const useStep = number => {
  const [step, setStep] = useState(number);
  const previousStep = () => setStep(step - 1);
  const nextStep = () => setStep(step + 1);
  return { step, previousStep, nextStep, setStep };
};



export const parseDate = (input, format) => {
  
  var addT = input.replace(" ", 'T')
  var replaceZone = addT.replace("+00:00", '');
  var replaceDec = replaceZone.slice(0, -5)
  var replaceEmp = replaceDec.concat(" ")
  var final = replaceEmp.replace(" ", 'Z');
  
  // console.log(finall)
  
  return final
}


export const openMail = (value) => {
  window.location.href = `mailto:${value}`;
}



function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export default usePrevious;

