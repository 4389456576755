import React, { useState } from 'react'
import { speakerData } from './prosperData'
import upImg from '../../assets/ProsConfImg/imgDown.png'
import downImg from '../../assets/ProsConfImg/imgUp.png'
import {  Notificationmodal } from '../helpers/modal/simplifiedmodal'
// import { Simplemodal } from "../helpers/modal/simplifiedmodal";



export const Speakers = () => {

  const [limit, setLimit] = useState(3)
  const [showMore, setShowMore] = useState(true);

  const handleSpeakerClick = () => {
    if (showMore) {
      setLimit(speakerData.length);
    } else {
      setLimit(3);
    }
    setShowMore(!showMore);
  }

  const [profileModal, setProfileModal] = useState(false)
  const [seeBio, setSeeBio] = useState(null)

  const handleProfileClick = (profile) => {
    setProfileModal(true)
    setSeeBio(profile)
  }


  return (
    <>
  
 <Notificationmodal onClick={() => setProfileModal(false)}   open={profileModal} >
    <div className='text-[#AEB4BE] flex flex-col items-center w-full lg:w-[70svw]'>
      <p>{seeBio?.heading}</p>
      <div className='flex items-center gap-2 my-2'>
      <h4 className='text-[18px] md:text-[24px] text-[#000000] font-bold'>{seeBio?.name}</h4>
      <p className='text-[16px] xlg:text-[16px] sm:mt-1'>{seeBio?.title}</p>
      </div>
      <p>{seeBio?.bio}</p>
    </div>
  </Notificationmodal>


    <div className='relative bg-[#F7FFF3] my-[4%] md:my-[6%]'>
        <div className='flex flex-col items-center justify-center py-[6%] px-4 md:px-[4%] xl:px-[6%] xlg:px-[6%]'>
            <h1 className='text-[26px] sm:text-[30px] md:text-[30px] xl:text-[30px] xlg:text-[30px] font-bold pb-6 md:pb-10 xl:pb-10 xlg:pb-10'>Meet the Speakers</h1>
            <div className='grid grid-cols-2 md:grid md:grid-cols-3 xl:grid xl:grid-cols-3 xlg:grid xlg:grid-cols-3 gap-2 sm:gap-4 md:gap-6 xl:gap-12 xlg:gap-12'>
                {speakerData.slice(0, limit).map(({img, name, title, details, profile}, index) => {
                return <div key={index} className='relative '>
                    <img src={img} alt="" className='rounded-md' />
                    <div className='absolute block bottom-[6%] left-6'>
                    <p className='text-[#FFFFFF] text-[14px]'>{name}</p>
                    <p className='text-[#FFFFFF] text-[14px]'><small>{title}</small></p>
                    <button className='border rounded-3xl border-vestigreen text-vestigreen px-2' onClick={() => handleProfileClick(profile, name)}><small>{details}</small></button>

                    </div>
                </div>
                })} 
            </div>
            <button className='bg-vestigreen rounded-md text-[#FFFFFF] py-4 px-12 my-6 md:my-10 xl:my-10 xlg:my-10 cursor-pointer' onClick={handleSpeakerClick}>{showMore ? 'See More Speakers' :  'See Less Speakers' }</button>
        </div>
        <img src={upImg} alt="" className='absolute top-0 w-[10%]' />
        <img src={downImg} alt="" className='absolute right-0 bottom-0 w-[10%]' />
    </div>
    </>
  )
}
