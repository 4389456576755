import React, { useState } from "react";
import sendPlane from "../../assets/flight/sendplane.png";

export const Subscribe = () => {
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSubmit = () => {
    // console.log(email);
    setEmail("");
  };

  return (
    <div className="w-full  px-4 md:px-8   relative overflow-hidden lg:pb-28 lg:px-20 xxl:px-32  vlg:px-64 pb-28  ">
      <div className="vlg:container  vlg:mx-auto ">
        {/* relative h-[12rem] lg:h-[17rem] */}
        <div className="bg-[#57266E] px-12 rounded-[28px] py-24 overflow-hidden text-white flex flex-col lg:flex-row items-center justify-between gap-12">
          <article>
            <h1 className="text-white text-4xl w-full lg:w-[90%]  font-fontSemi">
              Enjoy Exclusive Offers
            </h1>
            <p className="text-base font-extralight text-white pt-6 font-fontReg w-full lg:w-[80%]">
              Subscribe to our newsletter and be the first to know about our
              deals and enticing offers.
            </p>
          </article>

          <div className="bg-white w-full  lg:w-[40%] p-6 text-[#6C6C72] flex justify-between items-center rounded-lg">
            {/* <input
              type="text"
              placeholder="Search by fee title..."
              value={searchValue}
              onChange={handleSearchChange}
              className="p-2 border-[0.5px] rounded-md mx-2 w-full outline-none focus:bg-[#FCFFF9] focus:border-vestigreen"
            />
            <button
              onClick={applyFilter}
              className="bg-[#66A848] w-[150px] h-[56px] text-white rounded-[5px] mr-2"
            >
              search
            </button> */}

            <input
              type="text"
              placeholder="Your email address"
              value={email}
              onChange={handleChange}
              className="p-2 w-[80%] border-[0.5px] rounded-md mx-2  outline-none focus:bg-[#FCFFF9] focus:border-[#57276d33]"
            />
            <div
              onClick={handleSubmit}
              className="bg-[#57266E] p-2 rounded-md cursor-pointer"
            >
              <img src={sendPlane} alt="" className="h-8 w-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
