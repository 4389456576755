import axios from "axios";
const BASE_URL = "https://api.wevesti.com/api/";
const STAGING_URL = "https://syca-staging.wevesti.com/api/v1";

export default axios.create({
  baseURL: BASE_URL,
  // baseURL: STAGING_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  // //   withCredentials: true,
});
