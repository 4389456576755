import React, { useEffect, useState } from "react";
import { WebBanner2 } from "../components/WebBanner";
import graduates from "../assets/images/graduates.png";
import LifeAtVesti from "../views/LifeAtVesti";
import Footer from "../containers/Footer";
import NavBar from "../containers/NavBar";
import FaqSection from "../views/FaqSection";
import DownloadApp from "../views/DownloadApp";
import WhatWeOffer from "../views/Whatweoffer";
import { VestisValues } from "../views/VestiValues";
// import GTApplication from "../views/GTApllication";
import { useLocation } from "react-router-dom";
import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
import { Success } from "../components/helpers/success/success";
import errorsvg from "../assets/logo-icons/oops.svg";

const VGE = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.hash === "#applicationform") {
      const applicationsection = document.getElementById("applicationform");
      if (applicationsection) {
        applicationsection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div>
      <NavBar />
      <div className="bg-secondary w-full pt-40 pb-20">
        <WebBanner2
          title={
            <p className="text-4xl lg:text-4xl xl:text-5xl font-fontSemi pb-5 text-[#ffffff] !leading-[60px]">
              Graduate Trainee Programme
            </p>
          }
          details="Unleash your potential and elevate your career journey by joining our challenging and rewarding graduate trainee program."
          buttonText="Apply now"
          image={graduates}
          onClick={() => setOpen(true)}
          // link="/graduate-trainee#applicationform"
          imgWidth="40%"
        />

        <Simplemodal
          open={open}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Success
            title="Notice"
            image={errorsvg}
            type="error"
            subtitle="The application period has closed. We'll notify you when we open applications again."
            button="Okay, Thank you"
            onClick={() => {
              setOpen(false);
            }}
          />
        </Simplemodal>
      </div>
      <LifeAtVesti />
      <div className="pt-20">
        <VestisValues />
      </div>
      <WhatWeOffer />
      {/* <div id="applicationform">
        <GTApplication />
      </div> */}
      <FaqSection />
      <DownloadApp />
      <Footer />
    </div>
  );
};

export default VGE;
