import React from 'react'
import Marquee from "react-fast-marquee";
import pastImg1 from '../../assets/ProsConfImg/pastImg5.png'
import pastImg from '../../assets/ProsConfImg/pastImg.png'
import pastImg3 from '../../assets/ProsConfImg/pastImg3.png'
import pastImg4 from '../../assets/ProsConfImg/pastImg4.png'
import './styles.css'

export const PastEventPic = () => {
  return (
    <div className='flex flex-col items-center justify-center xl:py-[2%] xlg:py-[4%] px-4 md:px-[4%] xl:px-[6%] xlg:px-[6.5%] w-full'> 
        <h1 className='text-[26px] sm:text-[30px] font-bold '>Highlights from Our Past Events</h1>
        <div className='slider-container overflow-hidden my-[4%] w-full'>
        <Marquee
        pauseOnClick={true}>
                <div className='flex space-x-5 cursor-pointer rounded-t-xl overflow-hidden w-full '>
                    <img src={pastImg1} alt="" className='curved-image h-[27rem] w-[100%] px-2 ' />
                </div>
                <div className='flex space-x-5 cursor-pointer overflow-hidden w-full '>
                    <img src={pastImg} alt="" className='curved-image h-[27rem] w-[100%] px-2' />
                </div>
                <div className='flex space-x-5 cursor-pointer overflow-hidden  w-full'>
                    <img src={pastImg3} alt="" className='curved-image h-[27rem] w-[100%] px-2' />
                </div>
                <div className='flex space-x-5 cursor-pointer rounded-b-xl overflow-hidden  mr-[-2rem] w-full'>
                    <img src={pastImg4} alt="" className='curved-image h-[27rem] w-[100%] ' />
                </div>
        </Marquee>
        </div>
    </div>
  )
}








        