import React, { useEffect, useState } from "react";
import VisaTestimony from "../components/visaComponents/visaTestimony";
import VisaBenefits from "../components/visaComponents/benefitsOfVisa";
import star from "../assets/logo-icons/blackstar.svg";
import target from "../assets/logo-icons/blacktarget.svg";
import check from "../assets/logo-icons/blackcheck.svg";
import engineer from "../assets/images/ladyengineer.png";
import colleagues from "../assets/images/colleagues.png";
import thumbsup from "../assets/images/thumbsup.png";
import teammates from "../assets/images/teammates.png";
import history from "../assets/images/history.svg";
import History from "../components/visaComponents/history";
import Footer from "../containers/Footer";
import Features from "../components/Features";
import VisaFaqSection from "../components/visaComponents/visaFaq";
import NavBar from "../containers/NavBar";
import CuttingEdge from "../components/visaComponents/cuttingEdge";
import axios from "../components/api/axios";
import H1BBanner, {
  H1Beligibility,
} from "../components/visaComponents/visaBanner";
import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
import VisaTabComponent, {
  TabContent,
} from "../components/visaComponents/tabComponent";

const benefitData = [
  {
    icon: star,
    alt: "image",
    cardTitle: "Expertise",
    cardBody:
      "We have a team of experienced and knowledgeable immigration lawyers. We know the H1B visa application process inside and out, and we can help you avoid common mistakes.",
  },
  {
    icon: target,
    alt: "image",
    cardTitle: "Commitment",
    cardBody:
      "We are committed to helping you succeed. We want to help you achieve your dream of living and working in the United States, and we will do everything we can to help you obtain your H1B visa.",
  },
  {
    icon: check,
    alt: "image",
    cardTitle: "Success",
    cardBody:
      "We have a proven track record of success in helping foreign nationals obtain visas. We have helped hundreds of immigrants achieve their aspiration of building a better future in the US.",
  },
];

const tabs = [
  {
    id: 1,
    label: "About",
    content: (
      <TabContent
        title="Your Path to a Rewarding Career in the Land of Opportunity"
        description="The H1B visa is a non-immigrant visa that allows U.S. companies to employ foreign workers in specialty occupations that require theoretical or technical expertise in specialized fields. With an H1B visa, you can live and work in the U.S. for up to six years, with the option to extend your visa for an additional three years."
        image={engineer}
      />
    ),
  },
  {
    id: 2,
    label: "Eligibility",
    content: (
      <TabContent
        title="Your Path to a Rewarding Career in the Land of Opportunity"
        description="Have a bachelor's degree or higher from a U.S. accredited university or its equivalent, be offered a job in a specialty occupation that requires theoretical and practical application of a body of specialized knowledge, and have the job paid at least the prevailing wage for the occupation and area of employment."
        image={colleagues}
      />
    ),
  },
  {
    id: 3,
    label: "Benefits",
    content: (
      <TabContent
        title="Your Path to a Rewarding Career in the Land of Opportunity"
        description="You have the opportunity to live and work in the U.S., earn competitive wages and benefits, and advance your career. You can also bring your family to the U.S., and may eventually be eligible for permanent residency and citizenship. It also allows U.S. employers to hire highly skilled foreign workers and compete globally."
        image={thumbsup}
      />
    ),
  },
  {
    id: 4,
    label: "Application",
    content: (
      <TabContent
        title="Your Path to a Rewarding Career in the Land of Opportunity"
        description="To apply for an H1B visa, you must first have your employer submit a petition to the U.S. Citizenship and Immigration Services (USCIS). Once the petition has been approved, you must pay the visa application fee and schedule an interview at a U.S. embassy or consulate in your home country."
        image={teammates}
      />
    ),
  },
];

const H1BVisa = (props) => {
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(
    () => fetchAllCountries(),
    // eslint-disable-next-line
    []
  );

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <NavBar />
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <H1Beligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>
      <H1BBanner open={open} setOpen={setOpen} />

      <VisaTabComponent tabs={tabs} />

      <VisaBenefits
        title="Why Use Vesti for Your "
        visaName="H1B "
        subtitle="We streamline the complex and stressful application process to make it easy for you."
        data={benefitData}
      />

      <CuttingEdge
        title="Cutting-edge approach to the "
        visaName="H1B"
        firstDescription=" Experience the difference with our innovative platform and expert support. Say goodbye to the hassle of traditional H1B visa services."
        secondDescriptionTitle="Automated technology workflows"
        secondDescription="Our advanced algorithms and team of experts make the H1B visa process easier and more efficient."
        thirdDescriptionTitle=""
        thirdDescription=""
      />
      <VisaTestimony
        title={
          <>
            <h1 className="text-3xl text-black font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-black text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <VisaFaqSection />
      <History
        icon={history}
        alt="Img"
        title="We have successfully filled over 240 H1B visa to date and the number continues to grow."
        buttonTitle="See if you Qualify"
        open={open}
        setOpen={setOpen}
        // onClick={}
      />
      <Features />
      <Footer />
    </div>
  );
};

export default H1BVisa;
