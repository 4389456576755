import React, { useState, useEffect } from "react";
import { H1bPetitionHero } from "./H1bPetHero";
import NavBar from "../../containers/NavBar";
import { H1bUser } from "./H1bUser";
import { PetitionForm } from "./petitionForm";
import Footer from "../../containers/Footer";
import { Faqs } from "./H1bFaqs";
import Features from "../Features";
import history from "../../assets/images/history.svg";
import History from "../visaComponents/history";
import { H1Beligibility } from "../visaComponents/visaBanner";
import {
  Simplemodal,
  Notificationmodal,
} from "../helpers/modal/simplifiedmodal";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

export const H1bPaymentTest = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [countries, setCountries] = useState(false);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  useEffect(
    () => fetchAllCountries(),
    // eslint-disable-next-line
    []
  );

  return (
    <div>
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <H1Beligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>

      <NavBar />
      <H1bPetitionHero />

      <div>
        <PetitionForm />
        <H1bUser />
        <Faqs />

        <History
          icon={history}
          alt="Img"
          title="We have successfully filled over 240 H1B visa to date and the number continues to grow."
          buttonTitle="See if you Qualify"
          open={open}
          setOpen={setOpen}
          // onClick={}
        />
        <div className="my-12">
          <Features />
        </div>
      </div>

      <Footer />
    </div>
  );
};
