import React from 'react'
import NavBar from '../../containers/NavBar'
import Footer from '../../containers/Footer'
import { PartHero } from './PartHero'
import { PartFocus } from './PartFocus'
import { PartNum } from './PartNum'
import { Refer } from './Refer'
import { Partners } from './Partners'
import { Reviews } from './Reviews'
import { Benefits } from './Benefits'
import { VestiQues } from './VestiQues'



export const Partnership = () => {

  return (
    <>
    <NavBar />
    <PartHero />
    <PartFocus />
    <PartNum />
    <Refer />
    <Partners />
    <Reviews />
    <Benefits />
    <VestiQues />
    <Footer />
    </>
  )
}
