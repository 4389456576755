import React, { useState, useRef, useEffect } from "react";
import Miai from "../../assets/miai/miaiBg.png";
import Typed from "react-typed";
import Loader from "../Loader/Loader";
import FormattedTextComponent from "./FormattedText";

const Titles = [
  { title: "Understand migration" },
  { title: "Choose destination and pathway" },
  { title: "Gather documents" },
  { title: "Submit application" },
];

const ChatHistory = (props) => {
  const chatContainerRef = useRef(null);
  const [formattedAns, settFormattedAns] = useState(
    "helo. 1. Emi: tell me about it. 2. Miimi: how are you"
  );

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const handleTitleClick = (title) => {
    props.setInputText(title);
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  function getFormattedTime() {
    const date = new Date();
    return date.toLocaleTimeString("en-US", { timeZone: userTimeZone });
  }

  return (
    <div
      ref={chatContainerRef}
      className={`px-[15px]  lg:w-full h-[35vh] ${
        props.height === "100vh"
          ? "lg:h-[70vh] py-[20px]"
          : "lg:h-[10vh] py-[5px]"
      } overflow-y-auto `}
    >
      {/* <p className="text-center  text-[#fff] text-[11px] mb-6">Today</p> */}
      <div className="flex my-4 w-full">
        <div className="w-[100%] flex  gap-2">
          <img className="w-[30px] h-[30px] " src={Miai} alt="Miai" />

          <div className="w-full">
            <p className="leading-[20px] w-full bg-[#fff] p-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]">
              {props.messages.length === 0 ? (
                <Typed
                  strings={[
                    "Hi there, what questions do you have about moving abroad?",
                  ]}
                  typeSpeed={100}
                  backSpeed={100}
                  loop
                />
              ) : (
                "Hi there, what questions do you have about moving abroad?"
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[25px] h-[25px] "></div>
        <ul className="text-[#fff] text-[13px] flex flex-col gap-4">
          {Titles.map(({ title }) => {
            return (
              <li
                onClick={() => handleTitleClick(title)}
                className="underline cursor-pointer"
              >
                {title}
              </li>
            );
          })}
        </ul>
      </div>
      {props.messages.map((message, index) => {
        return (
          <div>
            {message.isUser === true ? (
              <div key={index} className="flex justify-end mb-6 mt-6">
                <div>
                  <div className="w-[100%] flex items-center gap-2">
                    <div className="w-[25px] h-[25px] "></div>
                    <p className="leading-[20px] bg-[#97E373]  p-2 text-[14px] rounded-b-[8px] rounded-tl-[8px]">
                      {message.text}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index}>
                {props.typewritermsg === "Typing" &&
                index === props.messages.length - 1 ? (
                  <div className="w-[100%] flex  gap-2" key={index}>
                    <img className="w-[30px] h-[30px] " src={Miai} alt="Miai" />
                    <p className="leading-[20px] bg-[#fff] p-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]">
                      {/* Typing indicator for last message */}
                      {props.generateChatbotResponse(message.text)}
                    </p>
                  </div>
                ) : (
                  <div className="w-[100%] flex  gap-2" key={index}>
                    <img className="w-[30px] h-[30px] " src={Miai} alt="Miai" />
                    <p className="leading-[20px] bg-[#fff] px-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]">
                      {/* Miai's formatted response for all messages */}
                      <FormattedTextComponent text={message.text} />
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}

      {props.loading && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ChatHistory;
