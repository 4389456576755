import React, { useRef, useState } from "react";
import videoImg from "../../assets/Event-Img/videoImg.png";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Success } from "../helpers/success/success";
import YouTube from "react-youtube";

import h1bHeroImg from "../../assets/H1bPetitionImg/h1bHeroPic.png";
import h1bFlag from "../../assets/H1bPetitionImg/h1bHeroFlag.png";
import h1bUsa from "../../assets/H1bPetitionImg/heroUsa.png";
import { Link as AnchorLink } from "react-scroll";

export const H1bPetitionHero = () => {
  const videoId = "VNhLG8VjQqE"; // Extract the video ID from the YouTube link
  const playerRef = useRef(null); // Create a ref to store the player instance
  const [isPlaying, setIsPlaying] = useState(false); // State to manage play button visibility

  const handleVideoClick = () => {
    if (playerRef.current) {
      const player = playerRef.current;
      const playerState = player.getPlayerState();
      if (playerState === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  };

  const nullFunction = () => {};

  const onReady = (event) => {
    playerRef.current = event.target; // Store the player instance in the ref
  };

  const onStateChange = (event) => {
    const playerState = event.data;
    if (playerState === window.YT.PlayerState.PLAYING) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  const opts = {
    height: "600",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div
      className={`bg-[#060E42] relative py-8 mt-[6%] w-full h-[600px] ${
        isPlaying ? "cursor-pointer" : "cursor-default"
      }`}
      onClick={() => {
        isPlaying ? handleVideoClick() : nullFunction();
      }}
    >
      <div className="absolute inset-0  w-full top-0 left-0 right-0 h-full opacity-95">
        <div className="flex justify-center items-center w-full ">
          <YouTube
            videoId={videoId}
            opts={opts}
            className="object-cover w-full  h-[200px]  md:h-full flex justify-center"
            onReady={onReady}
            onStateChange={onStateChange}
          />
        </div>

        {/* <div className="absolute inset-0 bg-[#060E42A3] "></div> */}

        <div className="absolute inset-0 bg-[#060E42A3] "></div>
      </div>

      <div className="block relative z-100 mt-[2rem] sm:mt-0 h-full w-full sm:flex sm:items-center sm:justify-center md:flex md:items-center md:justify-center xl:flex xl:items-center xl:justify-center xlg:flex xlg:items-center xlg:justify-center p-8 px-4 sm:px-[6%] md:px-[6%] xl:px-[6%] xlg:px-[6%] gap-4">
        <div className="block z-10  ">
          <h1 className="text-[24px] sm:text-[30px] md:text-[30px] xlg:text-[32px] font-bold text-[#FFFFFF] w-[100%] md:w-[90%] xl:w-[87%] xlg:w-[85%]">
            Get entered into the <br />
            <span className="text-vestigreen">H1B Work Visa Lottery</span>
          </h1>
          <p className="text-[#FFFFFF] text-[16px] xlg:text-[18px]  w-[100%] md:w-[90%] xl:w-[87%] xlg:w-[85%] my-6">
            If you are a skilled worker who wants to work in the US, the H-1B
            visa is the pathway to your goal.
          </p>
          {/* <button ></button> */}
          <AnchorLink
            spy={true}
            smooth={true}
            to="h1bFormSection"
            className="normal-case text-white bg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px] rounded-lg cursor-pointer"
          >
            Enter the lottery
          </AnchorLink>
          ;
        </div>

        <div className="relative w-full h-full">
          {!isPlaying && (
            <img
              src={videoImg}
              alt="Play video"
              className="absolute left-[40%] mt-10 sm:mt-0 sm:left-[10%] sm:top-[40%] cursor-pointer z-100 w-[5rem] h-[5rem]"
              onClick={handleVideoClick}
            />
          )}

          {/* {isPlaying && (
            <img
              src={videoImg}
              alt="Play video"
              className="absolute left-[10%] top-[40%] cursor-pointer z-100 w-[5rem] h-[5rem]"
              onClick={handleVideoClick}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};
