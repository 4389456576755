import React from "react";
import Avatar from "../../assets/element/savesti.jpeg";
import FairAvatar from "../../assets/element/hillary.jpeg";
import "./animationbanner.scss";

const Review = () => {
  return (
    <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
      <div className=" vlg:container vlg:mx-auto ">
        <h1 className="text-[#000] text-center text-[22px] my-4 leading-[32px]  md:leading-[36px] font-[550] md:text-[24px] ">
          What our users are <span className="text-[#D090FF]">saying</span>
        </h1>
        <div className="w-full elementGrid">
          <div className="w-full max-w-[400px] mx-auto sm:mx-0  bg-[#F7FFF3] border border-[#67A948] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img
                className="w-[50px] rounded-full h-[50px] object-center"
                src={FairAvatar}
                alt=""
              />

              <div className="flex flex-col ">
                <h1 className="font-[600]">Hilz 👍</h1>
                <p className="font-fontLight text-[12px]">30 Oct, 2023</p>
              </div>
            </div>
            <p className="text-[14px]">
              I must greet ElementByVesti team! ElementByVesti is really solving
              a problem. Awesome work! Aside the fact that all of my company's
              account is set and great. Element has made things easy. Invoicing,
              links, and other things makes it seamless. Awesome work guys!
            </p>
          </div>

          <div className="w-full max-w-[400px] mx-auto sm:mx-0 bg-[#F6F7FF] border border-[#16216B] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img
                className="w-[50px] rounded-full  h-[50px] object-center"
                src={Avatar}
                alt=""
              />

              <div className="flex flex-col ">
                <h1 className="font-[600]">O.A</h1>
                <p className="font-fontLight text-[12px]">06 Oct, 2023</p>
              </div>
            </div>
            <p className="text-[14px]">
              I wanted a US Business Account for my business and i found the app
              useful, also it's Eligible for FDIC insurance up to $250K.
            </p>
          </div>

          {/* <div className="w-full bg-[#FBF3FF] border border-[#3F1951] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img className="w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col ">
                <h1 className="font-[600]">Joshua Shittabey</h1>
                <p className="font-fontLight text-[12px]">11 July, 2022</p>
              </div>
            </div>
            <p className="text-[14px]">
              Lorem ipsum dolor sit amet, consectetur adipiscin spendisse
              consequat hendrerit posuere. In pellent ulputate mauris. Proin nec
              diam sed ligula pulvinar, Lorem ipsum dolor sit amet, consectetur
              adipiscin spendisse consequat hendrerit posuere.
            </p>
          </div>

          <div className="w-full bg-[#F6F7FF] border border-[#16216B] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img className="w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col ">
                <h1 className="font-[600]">Joshua Shittabey</h1>
                <p className="font-fontLight text-[12px]">11 July, 2022</p>
              </div>
            </div>
            <p className="text-[14px]">
              Lorem ipsum dolor sit amet, consectetur adipiscin spendisse
              consequat hendrerit posuere. In pellent ulputate mauris. Proin nec
              diam sed ligula pulvinar, Lorem ipsum dolor sit amet, consectetur
              adipiscin spendisse consequat hendrerit posuere.
            </p>
          </div>

          <div className="w-full bg-[#FBF3FF] border border-[#3F1951] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img className="w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col ">
                <h1 className="font-[600]">Joshua Shittabey</h1>
                <p className="font-fontLight text-[12px]">11 July, 2022</p>
              </div>
            </div>
            <p className="text-[14px] text-[#14290A]">
              Lorem ipsum dolor sit amet, consectetur adipiscin spendisse
              consequat hendrerit posuere. In pellent ulputate mauris. Proin nec
              diam sed ligula pulvinar, Lorem ipsum dolor sit amet, consectetur
              adipiscin spendisse consequat hendrerit posuere.
            </p>
          </div>

          <div className="w-full bg-[#F7FFF3] border border-[#67A948] p-4 rounded-[15px]">
            <div className="flex items-center gap-3 mb-4 mt-8">
              <img className="w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col ">
                <h1 className="font-[600]">Joshua Shittabey</h1>
                <p className="font-fontLight text-[12px]">11 July, 2022</p>
              </div>
            </div>
            <p className="text-[14px] text-[#14290A]">
              Lorem ipsum dolor sit amet, consectetur adipiscin spendisse
              consequat hendrerit posuere. In pellent ulputate mauris. Proin nec
              diam sed ligula pulvinar, Lorem ipsum dolor sit amet, consectetur
              adipiscin spendisse consequat hendrerit posuere.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Review;
