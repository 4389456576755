import React, { useState } from "react";
import ElementBook from "../assets/element/ELementBook_.png";
// import EIcon from "../assets/element/ebookIcon.svg";
import { ElementSimplemodal } from "../components/helpers/modal/simplifiedmodal";
import { ElementInputfloat } from "../components/inputs/inputfloat";
import { Phonenumber } from "../components/inputs/phoneinput";
import { getPhoneCode, getCurrencyCode } from "../helpers/utils";
import { allCountries } from "../components/helpers/countryregion";
import { Singleselect } from "../components/helpers/singleselect";
import axios from "../components/api/axios";
import successImg from "../assets/element/successellipses.png";

const EbookM = (props) => {
  const [formModal, setFormModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleContinue = async () => {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      country: selectedCountry.label,
      currencyFlag: getCurrencyCode(selectedCountry.label),
      service: "element-ebook",
      phoneNumber: {
        code: getPhoneCode(selectedCountry.label),
        number: phoneNumber.slice(getPhoneCode(selectedCountry.label).length),
      },
      currency: getCurrencyCode(selectedCountry.label),
    };

    axios
      .post(
        "https://syca-staging.wevesti.com/api/v1/general-services/actionable-signup",
        payload
      )
      .then((response) => {
        setFirstName("");
        setLastName("");

        setPhoneNumber("");
        setSelectedCountry("");
        setFormModal(false);
        setSuccessModal(true);
      })
      .catch((error) => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setSelectedCountry("");
        setFailedModal(true);
      });
  };

  return (
    <div>
      <ElementSimplemodal onClick={() => setFormModal(false)} open={formModal}>
        <div className=" text-[16px] font-[600]">
          <h2>Enter your details</h2>

          <div className="mt-12 flex flex-col gap-10">
            <div className="flex gap-4">
              <ElementInputfloat
                label="firstname"
                type="text"
                name="firstname"
                value={firstName}
                disabled={false}
                placeholder="Austin"
                onChange={(e) => setFirstName(e.target.value)}
              />

              <ElementInputfloat
                label="lastname"
                type="text"
                name="lastname"
                value={lastName}
                disabled={false}
                placeholder="Duke"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <ElementInputfloat
                label="email"
                type="text"
                name="email"
                value={email}
                disabled={false}
                placeholder="austin@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <Singleselect
              label="Country"
              value={selectedCountry}
              options={allCountries.map((country) => ({
                value: country.countryShortCode,
                label: country.countryName,
              }))}
              onChange={handleCountryChange}
              placeholder="Select Country"
            />

            <Phonenumber
              value={phoneNumber}
              country=""
              setValue={(value) => setPhoneNumber(value)}
            />

            <div
              className="bg-[#330059] cursor-pointer rounded-[5px] py-[20px] text-center w-full text-[#fff] font-[500]"
              onClick={handleContinue}
            >
              Continue
            </div>
          </div>
        </div>
      </ElementSimplemodal>

      <ElementSimplemodal
        onClick={() => setSuccessModal(false)}
        open={successModal}
      >
        <div className="w-full flex flex-col items-center justify-between">
          <img src={successImg} className="w-[7rem] h-[7rem]" alt="success" />

          <p className="mt-8 text-[#4B0082] text-[22px]"> Success</p>
          <p className="mt-1 text-[#414244] text-[14px]">
            {" "}
            A link has been sent to{" "}
            <span className="text-[#4B0082]">{email}</span>
          </p>

          <div
            onClick={() => {
              props.setOpenModal(false);

              setSuccessModal(false);
            }}
            className=" mt-24 border w-full cursor-pointer text-[14px] xlg:text-[16px] font-[600] bg-[#4B0082] text-[#fff] border-[#000] p-4 rounded-[10px]"
          >
            <p className="text-center">Okay</p>
          </div>
        </div>
      </ElementSimplemodal>

      <section className="w-full bg:h-[500px] bg:w-[800px] px-4  pt-[1rem] pb-[1rem] md:px-8  lg:px-[1rem]">
        <div className="flex  p-[10px] flex-col gap-8 bg:flex-row  justify-between items-center">
          <div className="w-full bg:w-[60%] h-[500px] hidden bg:block">
            <img
              className="image-container w-[80%]  h-[90%]"
              src={ElementBook}
              alt=""
            />
          </div>

          <div className="w-full bg:w-[40%]">
            <h1 className="text-[#D090FF] text-[16px] md:text-[18px] leading-[24px]  md:leading-[28px] font-[550]  ">
              Free Webinar + Ebook:{" "}
              <span className="text-[#4B0082]">
                Learn How to Get Funding to Scale Your Startup
              </span>
            </h1>

            <div className="flex flex-col  gap-8 my-6 ">
              <p>
                Did you know that 70% of startups fail due to a lack of funding,
                and 90% fail to secure venture capital?
              </p>

              <p>Don’t let your startup become another statistic.</p>

              <p>
                Join our FREE webinar and receive a complimentary ebook to
                uncover proven strategies to overcome funding hurdles and propel
                your startup to new heights.
              </p>
            </div>

            <div className="w-full  bg:hidden mt-20">
              <img className="image-container" src={ElementBook} alt="" />
            </div>

            <div
              onClick={() => {
                setFormModal(true);
              }}
              className=" mt-12 border w-full cursor-pointer text-[14px] xlg:text-[16px] font-[600] bg-[#330059] text-[#fff] border-[#000] px-4 py-2 rounded-[10px]"
            >
              <p className="text-center">Download now</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EbookM;
