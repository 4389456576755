import React, { useState, useEffect, useRef } from "react";
import { PaymentSection } from "./paymentSection";
import { ImCheckboxChecked } from "react-icons/im";
import { H1bNote } from "./h1bnote";
import { FillingCompanies } from "./fillingCompany";
import axios from "../api/axios";

export const Rightside = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const [isPaid, setIsPaid] = useState(false);

  const [h1bFee, setH1bfee] = useState(null);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsPaid(isPaid);
  }, [isPaid]);

  useEffect(() => {
    const fetchLotteryPrices = async () => {
      // setIsSuccess(false);
      try {
        const response = await axios.get(
          `https://pathwayapi.wevesti.com/api/v1/pathway/fetch-visa-prices?visaType=H1B
           `
        );
        setH1bfee(response.data?.priceLists[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchLotteryPrices();
  }, []);

  return (
    <div style={{}}>
      {props.paymentPercent == 100 ? (
        <div>
          <p
            style={{
              color: "#62943B",
              fontSize: "14px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Clarity Session Instructional Video
          </p>

          <div
            className="aboutpathwayimgbox"
            style={{ margin: "10px 0px", borderRadius: "10px" }}
          >
            <video
              // ref={videoRef}
              className="video-wrapper"
              controls
              width="100%"
              height="100%"
              style={{ borderRadius: "10px" }}
            >
              <source
                src={`https://storagefileforpathway.s3.amazonaws.com/videos/web/H1B+video.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <div style={{}}>
              <ImCheckboxChecked
                style={{
                  background: "white",
                  color: props.isClarityVideoChecked ? "#518C36" : "white",
                  cursor: "pointer",
                  border: "1px solid #518C36",
                  width: "15px",
                  height: "15px",
                  borderRadius: "4px",
                  marginTop: "6px",
                }}
                onClick={() => props.handleVideoCheckboxChange()}
              />
            </div>
            <p
              style={{
                color: "#67A948",
                fontSize: "14px",
                fontWeight: "500",
                // textAlign: 'center',
              }}
            >
              I have watched the video and I agree to Vesti’s{" "}
              <a
                href="https://wevesti.com/tos"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                {" "}
                Terms & Conditions{" "}
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <ImCheckboxChecked
                style={{
                  background: "white",
                  color: isChecked ? "#518C36" : "white",
                  cursor: "pointer",
                  border: "1px solid #518C36",
                  width: "15px",
                  height: "15px",
                  borderRadius: "4px",
                }}
                onClick={() => handleCheckboxChange()}
              />
            </div>
            <p
              style={{ color: "#67A948", fontSize: "14px", fontWeight: "500" }}
            >
              I have my own filing company{" "}
            </p>
          </div>
          <PaymentSection
            isChecked={isChecked}
            setIsPaid={setIsPaid}
            visaValue={props.visaValue}
            formId={props.formId}
            firstName={props.firstName}
            h1bFee={h1bFee}
            state={props.state}
            isEmptyFieldErrCheck={props.isEmptyFieldErrCheck}
            setIsEmptyFieldErrCheck={props.setIsEmptyFieldErrCheck}
            selectedFiles={props.selectedFiles}
            setSelectedFiles={props.setSelectedFiles}
          />
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        <H1bNote />
      </div>

      <div className=" bg:hidden" style={{ marginTop: "20px" }}>
        <FillingCompanies />
      </div>
    </div>
  );
};
