import CareersBanner from "../views/CareersBanner";
import ActiveCard from "../components/ActiveCard";
import VestiValues from "../views/VestiValues";
import Vector from "../assets/logo-icons/Vector.png";
import Vector1 from "../assets/logo-icons/Vector1.png";
// import VestiLife from "../assets/images/vesti-life.png";
import VestiLife from "../assets/images/vestiLife.jpg";
import VestiOuting from "../assets/career/Vesti 2024s_140 1.jpg";
import VestiOffer from "../components/VestiOffer";
import FaqSection from "../views/FaqSection";
import DownloadApp from "../views/DownloadApp";
import Button from "../components/Button";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import { openLink } from "../helpers/helpers";

const CareersPage = () => {
  return (
    <div>
      <NavBar />
      <CareersBanner />
      <div className="w-full py-20">
        <div className=" container relative mx-auto">
          <div className="grid lg:grid-cols-2 gap-10 lg:gap-52">
            <div className="col-span-1">
              <h1 className="text-[#14290A] font-semibold text-4xl pb-10 font-fontSemi">
                Life at Vesti
              </h1>
              <ActiveCard
                title="Wallets"
                desc="Vesti gives humans the power to move overseas to 110 Countries."
                click="hover:border-l-4 hover:border-l-lime-700"
              />

              <ActiveCard
                title="Cards"
                desc="Vesti gives humans the power to move overseas to 110 Countries."
                click="hover:border-l-4 hover:border-l-lime-700"
                addStyles="py-16"
              />

              <ActiveCard
                title="Pathways to other countries"
                desc="Vesti gives humans the power to move overseas to 110 Countries."
                click="hover:border-l-4 hover:border-l-lime-700"
              />
            </div>
            <div className="col-span-1 rounded-[20px] flex justify-center items-end">
              <img
                className="w-[50rem] h-[27rem] object-cover rounded-[10px]"
                src={VestiOuting}
                alt="vesti-life"
              />
            </div>
          </div>
        </div>
      </div>
      <VestiValues />
      <div className="w-full bg-[#F7FFF3] relative mb-24">
        <img src={Vector} alt="shape" className="absolute top-0 left-0" />
        <div className="container mx-auto py-24">
          <VestiOffer
            classname="mx-auto w-[50%] lg:w-[40%] flex flex-col"
            title="What We Offer"
            head1="Competitive Compensation"
            desc1="Be rewarded with competitive salary and benefits."
            head2="Meaningful Work"
            desc2="Make a difference with work that matters."
            head3="Career Growth"
            desc3="Chart your course with clear career growth paths."
            head4="Great Work Culture"
            desc4="Thrive in a culture of collaboration, support, and innovation."
            head5="Distributed Team"
            desc5="Join a globally connected workforce, embracing diversity and remote collaboration."
            head6="Health Insurance"
            desc6="Prioritize your well-being with comprehensive health coverage and wellness initiatives."
            head7="Learning & Development"
            desc7="Upskill with in-house training, mentorship, and conferences."
            head8="Paid Time Off"
            desc8="Recharge and reconnect with generous paid time off."
            head9="Work Tools"
            desc9="Work smarter with cutting-edge tools and technology."
            buttonText="See Open Roles"
            link="#"
          />
        </div>
        <img src={Vector1} alt="shape" className="absolute bottom-0 right-0" />
      </div>
      <div className="w-full bg-secondary mb-24">
        <div className="container mx-auto text-center py-14 lg:w-[60%]">
          <h1 className="text-[#7F8CE5] text-lg font-fontSemi">
            COME WORK WITH US
          </h1>
          <p className="text-[#ffffff] pt-2 pb-10 text-3xl leading-[40px] font-fontReg">
            Didn’t find a role that fits? We still want to hear from you. Send
            us an email at{" "}
            <span className="text-[#67A948]">join@wevesti.com</span>
          </p>
          {/* <Button
            link="#"
            CTA="View open roles"
            styles="p-4 mx-auto"
            width="40%"
          /> */}

          <div
            onClick={() => openLink("https://blog1.wevesti.com/opportunities/")}
            className={` p-4 lg:w-[40%] sm:w-[40%] mx-auto font-fontReg text-[#fff] text-center bg-[#67A948] cursor-pointer transition duration-700 ease-in-out hover:bg-[#3E6F26]  rounded-xl`}
          >
            See Open Roles
          </div>
        </div>
      </div>
      <FaqSection />
      <DownloadApp />
      <Footer />
    </div>
  );
};

export default CareersPage;
