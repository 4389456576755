import React from "react";
import flightHero from "../../assets/flight/flightbanner.svg";
import Users from "../../assets/flight/userflight.png";
import { GiAirplaneDeparture } from "react-icons/gi";

import "./animate.scss";

export const Flightbanner = (props) => {
  return (
    <div className="w-full  px-4 md:px-8 py-7 relative overflow-hidden lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-28 lg:pt-28 bg-[#060E42] ">
      <div className="vlg:container  vlg:mx-auto flex flex-col gap-8  lg:flex-row  lg:items-center justify-between ">
        {/* <img
          src={animatedbg}
          alt=""
          className=" absolute z-[2] top-0 left-0 opacity-[0.25] right-0 w-full h-full bottom-0"
        /> */}
        <ul className="background z-[6]">
          <li></li>
          <li></li>
          <li></li>
          {/* <li></li> */}
          <li></li>
          <li></li>
          {/* <li></li> */}
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="w-full lg:w-[50%] z-10">
          <h2 className="text-[42px] md:text-[48px] lg:max-w-[600px] text-white leading-[130%] font-[550]">
            Discover and book the most affordable flights effortlessly
          </h2>
          <p className="w-fulll xl:w-[520px] text-white text-[18px] leading-[150%] mt-6">
            We offer the best deals you can find on study, work, and adventure
            flights.
          </p>
          <p
            onClick={() => props.setShowCurrencyModal(true)}
            className="normal-case text-white rounded-lg lg:w-64 cursor-pointer bg-vestigreen transition w-64 flex mt-8 mb-2 duration-700 ease-in-out hover:bg-[#3E6F26] p-4 text-base"
          >
            Book a flight now{" "}
            <GiAirplaneDeparture className="text-[25px] flight-icon" />
          </p>

          <div className="flex w-64 items-center gap-2 mt-2">
            <img src={Users} alt="" className="object-center w-[55%]" />
            <p className="text-[13px] text-white">Happy Customers</p>
          </div>
        </div>
        <div className="z-10">
          <img src={flightHero} alt="" className="object-cover" />
        </div>
      </div>
    </div>
  );
};
