import React from 'react'
import techstars from '../../assets/Partnership-Img/techsters.png'
import techcity from '../../assets/Partnership-Img/TechCity Logo.png'
import bloomberg from '../../assets/Partnership-Img/bloomberg.png'
import heroImg from '../../assets/Partnership-Img/HeroImg .png'
import UsLogo from '../../assets/Partnership-Img/UsLogo.png'
import { Link as AnchorLink } from "react-scroll";


export const PartHero = () => {
  return (
    <div className='bg-[#060E42] pt-28'>
        <div className='grid md:flex md:items-center md:justify-around xl:flex xl::items-center xl:justify-around xl:gap-6 xlg:flex xlg:items-center xlg:justify-around xlg:gap-8 px-4 md:px-6 xlg:px-10 py-8'>
            <div className='text-[#FFFFFF] w-[100%] md:w-[40%] xl:w-[40%] xlg:w-[46%]'>
                <h1 className='font-bold text-[40px] md:text-[36px] xlg:text-[45px]'><span className='text-vestigreen text-bold'>Empower Your Startups:</span> Facilitate US Immigration with Vesti</h1>
                    <p className='text-[18px] w-[90%] mt-2 xlg:mt-4 md:leading-8 leading-10'>Partner with us to streamline the US immigration journey for your startups through
                    our advanced migration fintech platform and expert guidance, helping them focus on growth.
                    </p>
                <div className='flex items-center gap-2 xlg:gap-4 my-6 xlg:my-10'>
                <AnchorLink
                spy={true}
                smooth={true}
                to="referAndPartnerSection"
                className='border rounded-md px-2 sm:px-14 md:px-2 xl:px-8 xlg:px-14 py-2 xlg:py-3 cursor-pointer'            >
                Refer a Startup            
                </AnchorLink>

                <AnchorLink
                spy={true}
                smooth={true}
                to="referAndPartnerSection"
                className='rounded-md px-2 sm:px-14 md:px-2 xl:px-8 xlg:px-14 py-2 xlg:py-3 bg-vestigreen cursor-pointer'>
                Partner with Us
                </AnchorLink>

                </div>
                <div className='flex items-center gap-2 xl:gap-4 xlg:gap-4] sm:py-4 py-2'>
                    <p className='text-[#7D7D7D] text-[14px] xlg:text-[16px]'>As featured in:</p>
                    <div className='flex items-center w-[18%] md:w-[28%] xl:w-[20%] xlg:w-[70%] gap-2 xlg:gap-4'>
                        <img src={techstars} alt="" />
                        <img src={techcity} alt="" />
                        <img src={bloomberg} alt="" />
                    </div>
                </div>
            </div>

            <div className='relative mt-8 flex flex-col items-center justify-center'>
                <img src={heroImg} alt="" />
                <img src={UsLogo} alt="" className='absolute top-0 left-4 sm:left-12 xlg:left-6 w-[25%]' />
            </div>
        </div>
    </div>
  )
}
