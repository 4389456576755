import React, { useState } from "react";
import debitcard from "../../assets/icons/debcrecard.svg";
import ngnwallet from "../../assets/icons/ngnwallet.svg";
import axios from "../api/axios";
import { Platformbutton } from "../helpers/button/button";
import { RadioOption } from "../inputs/radiobutton";

export const Paymentoption = (props) => {
  const [option, setOption] = useState("Wallet");
  const [step, setStep] = useState(1);

  // eslint-disable-next-line
  const [payData, setPay] = useState({});
  const [nmcEmail, setNmcEmail] = useState("");
  const [nmcPin, setNmcPin] = useState();
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Initiate Payment");

  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(value);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };

  const handleNmcEmailChange = (e) => {
    const inputValue = e.target.value;
    setNmcEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };

  const handleNmcPinChange = (e) => {
    const inputValue = e.target.value;
    setNmcPin(inputValue);
    // setIsValidEmail(validateEmail(inputValue));
  };

  const setPaymentOption = (value) => {
    setOption(value);
  };

  const paystackPay = () => {
    setButtonText("Redirecting ...");
    var amt = props.amount;
    // console.log(props.selectedCountry);
    const payload = {
      currency: props.selectedCountry ? props.selectedCountry : "NGN",
      amount: Math.ceil(amt),
      // amount: Math.ceil(amt),
      method: "card",
      service: props.paymentService,
      email: email,
      currencyFlag: "NG",
    };

    const nmcpayload = {
      currency: props.selectedCountry,
      amount: Math.ceil(amt),
      method: "card",
      service: props.paymentService,
      nmcEmail: nmcEmail,
      nmcPin: nmcPin,
      email: email,
      currencyFlag: "NG",
    };

    axios
      .post(
        "v1/merchant-service/payment-2",
        props.paymentTitle === "NMC UK" ? nmcpayload : payload
      )
      .then((response) => {
        console.log(response);
        setTimeout(() => {
          window.open(response.data.data.url.link);
        }, 100);
        // props.closeModal();
        setButtonText("Initiate Payment");
        props.closeModal();
      })

      .catch((error) => {
        // console.log(error);
        setButtonText("Initiate Payment");
        // openNotificationWithIconErr(error.message, "Merchant Payment");
      });
  };

  switch (step) {
    // case 0:
    //     return <Wespay
    //         continue ={()=> setStep(1)}
    //         amount={amount}
    //         handleAmount={handleAmount}
    //         setCurrency={setCurrency}
    //         currency={currency}
    //         rate={props.rate}
    //     />
    case 1:
      return (
        <Mode
          option={option}
          setPaymentOption={setPaymentOption}
          payData={payData}
          setStep={setStep}
          paymentTitle={props.paymentTitle}
          // formData={props.formData}
          paystackPay={paystackPay}
          // current={props.current}
          // setCurrent={props.setCurrent}
          toWholeCurrency={props.toWholeCurrency}
          step={step}
          rate={props.rate}
        />
      );
    case 2:
      return (
        <VerifyEmail
          option={option}
          setPaymentOption={setPaymentOption}
          payData={payData}
          setStep={setStep}
          paymentTitle={props.paymentTitle}
          email={email}
          setEmail={setEmail}
          handleEmailChange={handleEmailChange}
          isValidEmail={isValidEmail}
          buttonText={buttonText}
          // formData={props.formData}
          paystackPay={paystackPay}
          // current={props.current}
          // setCurrent={props.setCurrent}
          toWholeCurrency={props.toWholeCurrency}
          step={step}
          rate={props.rate}
        />
      );
    case 3:
      return (
        <NMC
          option={option}
          setPaymentOption={setPaymentOption}
          payData={payData}
          setStep={setStep}
          paymentTitle={props.paymentTitle}
          email={email}
          setEmail={setEmail}
          handleEmailChange={handleEmailChange}
          handleNmcEmailChange={handleNmcEmailChange}
          handleNmcPinChange={handleNmcPinChange}
          nmcEmail={nmcEmail}
          nmcPin={nmcPin}
          isValidEmail={isValidEmail}
          buttonText={buttonText}
          // formData={props.formData}
          paystackPay={paystackPay}
          toWholeCurrency={props.toWholeCurrency}
          step={step}
          rate={props.rate}
        />
      );
    // case 3:
    //   return (
    //     <>
    //       <Titlesubtitle
    //         title={`Enter Your Vesti Transaction PIN • 2 of  ${
    //           props.formData.type === 'applicationsFees' ||
    //           props.formData.type === 'TuitionAcceptanceFees'
    //             ? 3
    //             : 2
    //         }`}
    //         subtitle="Enter your Vesti transaction PIN to finalize this transaction"
    //       />
    //       <Smallbox>
    //         <p>
    //           You are about to pay for a{' '}
    //           <strong> {props.formData.type} </strong> with a cost of{' '}
    //           <strong>
    //             {' '}
    //             ₦
    //             {props.amount.ngn
    //               ? props.amount.ngn
    //                   .toString()
    //                   .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //               : props.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //           </strong>{' '}
    //           , enter your Vesti transaction PIN to finalize.
    //         </p>
    //       </Smallbox>

    //       <Comptransaction
    //         setPin={props.setTransactionPin}
    //         loading={props.loader}
    //         goBack={() => goBack()}
    //         btn="Initiate Payment"
    //         onFinish={finalizeTransaction}
    //         len={4}
    //       />
    //     </>
    //   );
    // case 4:
    //   return (
    //     <Success
    //       title={`Merchant Payment`}
    //       subtitle={props.message}
    //       button="Okay, Thank You!"
    //       onClick={() => setStep(6)}
    //     />
    //   );
    // case 5:
    //   return (
    //     <Success
    //       image={errorsvg}
    //       title={props.formData.type}
    //       subtitle={props.message}
    //       onClick={() => setStep(1)}
    //       button="Try Again"
    //       type="error"
    //     />
    //   );
    // case 6:
    //   return (
    //     <Vestireward amount="5,000">
    //       <p>
    //         <strong>
    //           {' '}
    //           Claim ₦{props.formData.type === 'SEVIS' ? '5,000' : '2,500'}{' '}
    //         </strong>{' '}
    //         when you tell someone who needs to Pay For{' '}
    //         <strong>{props.formData.type} fee about Vesti.</strong>
    //       </p>
    //     </Vestireward>
    //   );

    default:
      return <>Not Found</>;
  }
};

export const Mode = (props) => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="text-vestigreen">
            {` Step 1 of `} {props.paymentTitle === "NMC UK" ? 3 : 2}
          </h2>
          <h2 className="font-[600] text-[18px] mb-3 ">{`${props.paymentTitle} Payment`}</h2>
          <p className="font-[300]">Select Payment Mode</p>
        </div>

        <div className="paymentoption-inner mt-8 center mb-8">
          <RadioOption
            image={ngnwallet}
            changed={props.setPaymentOption}
            id="1"
            isSelected={props.option === "Wallet"}
            label="Pay With Vesti Wallet"
            sublabel="You can pay via your Vesti wallet"
            value="Wallet"
          />
          <div className="mb-4"></div>
          <RadioOption
            image={debitcard}
            changed={props.setPaymentOption}
            id="2"
            isSelected={props.option === "card"}
            label="Pay with card"
            value="card"
            sublabel="You can pay using active debit card."
          />

          <div className="mb-4"></div>
        </div>

        {/* <div className="">
          <Link
           
            onClick={() =>
              window.open("https://app.wevesti.com/merchants", "_blank")
            }
            className="bg-vestigreen px-6 py-4 rounded-md text-white "
          >
            Initiate Payment
          </Link>
        </div> */}
        {/* 
        {props.option === "Wallet" && (
          <Link
           
            onClick={() =>
              window.open("https://app.wevesti.com/merchants", "_blank")
            }
            className="bg-vestigreen px-6 py-4 rounded-md text-white "
          >
            Continue
          </Link>
        )} */}

        {props.option === "Wallet" && (
          <Platformbutton
            type="normal"
            name="Continue"
            click={() =>
              window.open("https://app.wevesti.com/merchants", "_blank")
            }
            classname="fas fa-arrow-right"
          />
        )}

        {props.option === "card" && (
          <Platformbutton
            type="normal"
            name="Continue Payment"
            // click={(e) => props.paystackPay(e)}
            click={() => props.setStep(2)}
            classname="fas fa-arrow-right"
          />
        )}

        {/* <button className="bg-vestigreen px-6 py-4 rounded-md text-white">
          Initiate Payment
        </button> */}

        {/* {props.payData.paymentURL && (
          <div className="link-box">
            You are paying for <strong> {props.payData.type} </strong> at{" "}
            <strong> NGN {props.toWholeCurrency(props.payData.amount)}</strong>{" "}
            with an exchange rate of <strong>{props.rate} Naira to USD</strong>
          </div>
        )}
        {props.option === "card" && props.formData.custom && (
          <Form style={{ width: "70%" }}>
            <div
              className="w-100 flex-fill pt-4 mb-2"
              style={{ width: "100%" }}
            >
              <div className="enter-amount">
                <div className="custom-field">
                  <p className="mt-2">Enter an amount</p>
                  <input
                    type="number"
                    placeholder="Enter custom fee amount"
                    value={props.current}
                    onChange={(e) => props.setCurrent(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}

        {props.option === "card" && (
          <Platformbutton
            type="normal"
            name="Initiate Payment"
            click={(e) => props.paystackPay(e)}
            classname="fas fa-arrow-right"
          />
        )}

        {props.option === "Wallet" && (
          <Platformbutton
            type="normal"
            name={
              props.formData.type === "applicationsFees" ||
              props.formData.type === "TuitionAcceptanceFees"
                ? "Proceed to input details "
                : "Proceed to input PIN"
            }
            click={() =>
              props.formData.type === "applicationsFees" ||
              props.formData.type === "TuitionAcceptanceFees"
                ? props.setStep(2)
                : props.setStep(3)
            }
          />
        )} */}
      </div>
    </div>
  );
};

export const VerifyEmail = (props) => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="text-vestigreen">
            {` Step 2 of`} {props.paymentTitle === "NMC UK" ? 3 : 2}
          </h2>
          <h2 className="font-[600] text-[18px] mb-3 ">{`${props.paymentTitle} Payment`}</h2>
          <p className="font-[300]">Enter Your Email to Proceed</p>

          <div className="mb-8">
            <input
              type="text"
              className="w-full bg-merchantcardcolor  border rounded-[5px] py-4 px-1 mt-2 mb-2 outline-none focus:border-vestigreen border-lightvestigreen"
              placeholder="enter your email"
              value={props.email}
              onChange={props.handleEmailChange}
            />
            {!props.isValidEmail && (
              <p className="text-red-500 text-sm ">*Invalid email address</p>
            )}
          </div>

          {props.paymentTitle !== "NMC UK" ? (
            !props.isValidEmail || props.email === "" ? (
              <Platformbutton
                type="normal"
                name="Initiate Payment"
                click={(e) => props.paystackPay(e)}
                disabled
                // click={() => props.setStep(2)}
                classname="fas fa-arrow-right"
              />
            ) : (
              <Platformbutton
                type="normal"
                name={props.buttonText}
                click={(e) => props.paystackPay(e)}
                // click={() => props.setStep(2)}
                classname="fas fa-arrow-right"
              />
            )
          ) : !props.isValidEmail || props.email === "" ? (
            props.option === "card" && (
              <Platformbutton
                type="normal"
                name="Continue Payment"
                disabled
                // click={(e) => props.paystackPay(e)}
                click={() => props.setStep(3)}
                classname="fas fa-arrow-right"
              />
            )
          ) : (
            props.option === "card" && (
              <Platformbutton
                type="normal"
                name="Continue Payment"
                // click={(e) => props.paystackPay(e)}
                click={() => props.setStep(3)}
                classname="fas fa-arrow-right"
              />
            )
          )}

          {/* {!props.isValidEmail || props.email === "" ? (
            <Platformbutton
              type="normal"
              name="Initiate Payment"
              click={(e) => props.paystackPay(e)}
              disabled
             
              classname="fas fa-arrow-right"
            />
          ) : (
            <Platformbutton
              type="normal"
              name={props.buttonText}
              click={(e) => props.paystackPay(e)}
             
              classname="fas fa-arrow-right"
            />
          )} */}
          {/* {props.option === "card" && (
            <Platformbutton
              type="normal"
              name="Initiate Payment"
              click={(e) => props.paystackPay(e)}
              // click={() => props.setStep(2)}
              classname="fas fa-arrow-right"
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export const NMC = (props) => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="text-vestigreen">
            {` Step 3 of `}
            {props.paymentTitle === "NMC UK" ? 3 : 2}
          </h2>
          <h2 className="font-[600] text-[18px] mb-3 ">{`${props.paymentTitle} Payment`}</h2>
          <p className="font-[300]">Enter Your Nmc Email to Proceed</p>

          <div className="mb-2">
            <input
              type="text"
              className="w-full bg-merchantcardcolor  border rounded-[5px] py-4 px-1 mt-2 mb-2 outline-none focus:border-vestigreen border-lightvestigreen"
              placeholder="enter your Nmc email"
              value={props.nmcEmail}
              onChange={props.handleNmcEmailChange}
            />
            {!props.isValidEmail && (
              <p className="text-red-500 text-sm ">*Invalid email address</p>
            )}
          </div>

          <p className="font-[300]">Enter Your Nmc Pin to Proceed</p>

          <div className="mb-8">
            <input
              type="text"
              className="w-full bg-merchantcardcolor  border rounded-[5px] py-4 px-1 mt-2 mb-2 outline-none focus:border-vestigreen border-lightvestigreen"
              placeholder="enter your Nmc pin"
              value={props.nmcPin}
              onChange={props.handleNmcPinChange}
            />
            {/* {!props.isValidEmail && (
              <p className="text-red-500 text-sm ">*Invalid email address</p>
            )} */}
          </div>

          {!props.isValidEmail || props.nmcEmail === "" || !props.nmcPin ? (
            <Platformbutton
              type="normal"
              name="Initiate Payment"
              click={(e) => props.paystackPay(e)}
              disabled
              classname="fas fa-arrow-right"
            />
          ) : (
            <Platformbutton
              type="normal"
              name={props.buttonText}
              click={(e) => props.paystackPay(e)}
              classname="fas fa-arrow-right"
            />
          )}

          {/* {props.paymentTitle === "NMC UK" ? <p>input nmc pin</p> : <></>} */}
        </div>
      </div>
    </div>
  );
};
