import React, { useState } from "react";
import { H1bQuest } from "./H1bCards";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

export const Faqs = () => {
  const [hideAnswer, setHideAnswer] = useState(null);

  const toggleAnswer = (index) => {
    if (hideAnswer === index) {
      setHideAnswer(null);
    } else {
      setHideAnswer(index);
    }
  };

  return (
    <div className="md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center px-4 md:px-[3%] xl:px-[5%] xlg:px-20  pt-20">
      <div className="px-0 sm:px-4 xlg:px-8">
        <div className="grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 xlg:grid-cols-2 sm:p-2 md:p-4 xl:p-8 xlg:p-4 h-full">
          <div className="flex h-full">
            <div className="flex-grow pb-6">
              <h1 className="text-[22px] mb-1  font-bold w-[100%] sm:w-[85%] md:w-[85%] xl:w-[85%] xlg:w-[85%]">
                Some of the things you may want to know about the H-1B visa{" "}
              </h1>
              <p className="w-[100%] sm:w-[85%] md:w-[85%] xl:w-[85%] xlg:w-[85%]">
                We answered questions so you don’t have to ask them
              </p>
            </div>
          </div>
          <div className="grid h-full">
            <div className="flex h-full">
              <div className="flex-grow">
                {H1bQuest.map(({ title, ans, border }, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        index + 1 === H1bQuest?.length ? "" : "border-b-2"
                      }   pb-4 mb-4`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="block w-[84%]">
                          <h6 className="text-[18px] xlg:text-[20px] xl:text-[20px] md:text-[20px] sm:text-[20px] font-bold">
                            {title}
                          </h6>
                          {hideAnswer === index && (
                            <p className="py-3">{ans}</p>
                          )}
                        </div>
                        <div className="">
                          {hideAnswer === index ? (
                            <FiMinus
                              className="cursor-pointer sm:text-[30px] md:text-[35px] xl:text-[40px] xlg:text-[40px]"
                              onClick={() => toggleAnswer(index)}
                            />
                          ) : (
                            <FiPlus
                              className="cursor-pointer text-[30px] sm:text-[30px] md:text-[35px] xl:text-[40px] xlg:text-[40px]"
                              onClick={() => toggleAnswer(index)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
