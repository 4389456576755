import React from "react";
import purplebg from "../../assets/element/purpleElementanimatebg.svg";
import elementAvatarUsers from "../../assets/element/Eusers.png";
import phones from "../../assets/element/Ephones.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const OpenaccountOnline = () => {
  return (
    <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
      <div className=" vlg:container vlg:mx-auto ">
        <div className="bg-[#8D2DD4] px-6 relative h-[22rem] sm:h-[20rem] md:h-[27rem] rounded-[16px] overflow-hidden flex justify-between gap-12">
          <img
            src={purplebg}
            alt=""
            className=" absolute z-[5] top-0 left-0 bottom-0 opacity-[0.2]"
          />
          <article className="z-10 flex flex-col justify-center">
            <h2 className="text-[#DDAFFF] text-[22px] my-4 leading-[32px]  md:leading-[42px] font-[600] md:text-[38px] xxl:text-[42px]">
              Open a US Business <br />
              <span className="text-white">Account</span>{" "}
            </h2>
            <p className="leading-12 my-4 text-white text-[14px] bg:text-[16px] w-full md:max-w-[550px] ">
              Break down geographical barriers and open doors to global
              opportunities. Element allows you to effortlessly open a free US
              Business Account online, ensuring that your financial operations
              are as borderless as your ambitions.
            </p>

            <div className="flex text-[white] items-center ">
              <img src={elementAvatarUsers} alt="" />
              <p className="ml-2 font-[300]">& More</p>
            </div>
          </article>

          <div className="pt-32 w-[40%] z-10 hidden lg:flex flex-col justify-end">
            <img src={phones} alt="" className="w-full" />
          </div>
        </div>
      </div>
      {/* other card */}
      <div className=" vlg:container vlg:mx-auto mt-[4rem]  lg:mt-[6rem]">
        <div className="bg-[#AE8C26] px-6 relative h-[22rem] sm:h-[20rem] md:h-[27rem] rounded-[16px] overflow-hidden flex justify-between gap-12">
          {/* <div className="bg-[#AE8C26] px-6 relative h-[22rem] sm:h-[20rem] pb-4 sm:pb-0 md:h-[27rem] rounded-[16px] overflow-hidden flex justify-between gap-12"> */}
          <img
            src={purplebg}
            alt=""
            className=" absolute z-[5] top-0 bottom-0 left-0 opacity-[0.05]"
          />
          <div className="pt-32 w-[40%] z-10 hidden lg:flex flex-col justify-end">
            <img src={phones} alt="" className="w-full" />
          </div>

          <article className="z-10 flex flex-col justify-center">
            <h2 className="text-[#FCDA97] text-[22px] my-4 leading-[32px]  md:leading-[42px] font-[600] md:text-[38px] xxl:text-[42px]">
              Effortlessly Generate Payment <br />{" "}
              <span className="text-white">Links and Invoice</span>{" "}
            </h2>
            <p className="leading-12 my-4 text-white text-[16px] xxl:text-[16px] max-w-[550px] ">
              Transform your billing experience. Instantly generate secure
              payment links for swift transactions. Simplify payments for your
              clients, partners, and customers with a click.
            </p>

            <div className="flex text-black items-center ">
              <img src={elementAvatarUsers} alt="" />
              <p className="ml-2 font-[300]">& More</p>
            </div>
          </article>
        </div>
      </div>

      {/* Other card */}
      <div className=" vlg:container vlg:mx-auto mt-[4rem] lg:mt-[6rem]">
        <div className="bg-[#2F2EFF] px-6 relative h-[22rem] sm:h-[20rem] md:h-[27rem] rounded-[16px] overflow-hidden flex justify-between gap-12">
          <img
            src={purplebg}
            alt=""
            className=" absolute z-[5] top-0 left-0 bottom-0 opacity-[0.2]"
          />
          <article className="z-10 flex flex-col justify-center">
            <h2 className="text-[#E5E5FF] text-[22px] my-4 leading-[32px]  md:leading-[42px] font-[600] md:text-[38px] xxl:text-[42px]">
              Register your business <br />
              <span className="text-white">in the United States</span>{" "}
            </h2>
            <p className="leading-12 my-4 text-white text-[14px] bg:text-[16px] w-full md:max-w-[550px] ">
              Element app makes it easy to register your African business in the
              US. We guide you through the entire process, from choosing the
              right business structure to filing the necessary paperwork.
            </p>

            <div className="flex text-[white] items-center ">
              <img src={elementAvatarUsers} alt="" />
              <p className="ml-2 font-[300]">& More</p>
            </div>
          </article>

          <div className="pt-32 w-[40%] z-10 hidden lg:flex flex-col justify-end">
            <img src={phones} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};
