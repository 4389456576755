import flash from "../assets/images/flash.svg";
import snap from "../assets/images/snap.svg";
import Rates from "../assets/logo-icons/rates-icon.png";

const OvBenefits = () => {
  return (
    <div className="w-full pb-24 xs:pb-10">
      <div className="container mx-auto lg:w-[80%] flex-wrap">
        <div className="text-center pb-10">
          <h1 className="text-[#14290A] xs:text-l text-4xl font-fontReg pb-4">
            Benefits of <span className="text-[#67A948]">O-1 Visa</span>
          </h1>
          <p className="text-[#2B5219] text-2xl font-fontLight">
            Unlock new possibilities with an O-1 visa for extraordinary
            individuals.
          </p>
        </div>

        {/* Offerings Listed in Cards */}
        <div className="grid gap-y-10 gap-x-10 xl:grid-cols-3 lg:grid-cols-2 lg:gap-y-10 lg:gap-x-10 md:grid-cols-2 md:gap-5 sm:grid-cols-2 sm:gap-5">
          <div className="flex flex-col bg-[#F7FFF3] rounded-xl p-10 font-fontReg">
            <div>
              <img src={snap} alt="convenient" />
            </div>
            <div className="pt-5">
              <h3 className="text-[#14290A] font-fontBold text-lg pb-1">
                Recognition
              </h3>
              <p className="text-[#2B5219] font-fontLight text-base pb-4">
                Gain recognition for your extraordinary abilities and stand out
                in the US workforce with an O-1 visa.
              </p>
            </div>
          </div>

          <div className="flex flex-col bg-[#F6F7FF] rounded-xl p-10">
            <div>
              <img src={flash} alt="speed" />
            </div>
            <div className="pt-5">
              <h3 className="text-[#14290A] font-fontReg text-lg pb-1">
                Flexibility
              </h3>
              <p className="text-[#2B5219] font-fontLight text-base pb-4">
                Enjoy the flexibility to work and live in the US for an extended
                period of time with an O-1 visa.
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-[#FBF3FF] rounded-xl p-10">
            <div>
              <img src={Rates} alt="rates" />
            </div>
            <div className="pt-5">
              <h3 className="text-[#14290A] font-fontReg text-lg pb-1">
                Opportunities
              </h3>
              <p className="text-[#2B5219] font-fontLight text-base pb-4">
                Expand your career and personal opportunities with the ability
                to live and work in the US on an O-1 visa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OvBenefits;
