import { Link, useLocation } from "react-router-dom";

const MiaiDoc = ({
  title,
  date,
  link1,
  link2,
  link3,
  mainBody,
  path1,
  path2,
  path3,
  subTitle,
  img,
}) => {
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
      <div className="w-full   py-7 relative overflow-hidden px-4 md:px-8 lg:py-6 lg:px-28 xxl:px-32  vlg:px-64 pt-28 lg:pt-28 bg-[#060E42] ">
        <div className="vlg:container  vlg:mx-auto ">
          <div className="flex flex-col gap-8  lg:flex-row  lg:items-center justify-between">
            <div className="w-full lg:w-[50%] z-10  ">
              <p className="text-white font-[300] text-[16px] mb-6">{title}</p>
              <h2 className="text-[32px] md:text-[32px] lg:max-w-[400px] text-white leading-[145%] font-[550]">
                {subTitle}
              </h2>
              <p className="w-fulll xl:w-[520px] text-white text-[16px] leading-[150%] mt-6 flex items-center gap-2">
                <div className="bg-[#67A948] w-3 h-3 rounded-full"></div>
                {date}
              </p>
            </div>

            <div className="z-10">
              <img src={img} alt="" className="object-cover" />
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <ul className="flex text-[#fff] text-lg">
              <Link
                to={path1}
                className={`pb-3 text-[12px] lg:text-[18px] ${
                  splitLocation[2] === "tos"
                    ? `border-b-8 border-[#67A948]`
                    : "text-white"
                }`}
              >
                {link1}
              </Link>
              <Link
                to={path2}
                className={`mx-5 pb-3 text-[12px] lg:text-[18px] ${
                  splitLocation[2] === "privacy-policy"
                    ? `border-b-8 border-[#67A948]`
                    : "text-white"
                }`}
              >
                {link2}
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:w-[100%] px-4 md:px-8 lg:py-6 lg:px-28 xxl:px-32">
        {mainBody}
      </div>
    </>
  );
};

export default MiaiDoc;
