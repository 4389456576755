import React, { useState } from "react";
import woman from "../../assets/images/bigsmile.png";
import { Button } from "@material-tailwind/react";
import { Success } from "../helpers/success/success";
import { Titlesubtitle } from "../helpers/titlesubtitle/titlesubtitle";
import Inputfloat from "../inputs/inputfloat";
import { Phonenumber } from "../inputs/phoneinput";
import {
  // IconOption,
  // IconSingleValue,
  Singleselect,
} from "../inputs/singleselect";
import { Platformbutton } from "../helpers/button/button";
import { RadioOption } from "../inputs/radiobutton";
import { Backcontinue } from "../helpers/backcontinue/backcontinue";
import errorsvg from "../../assets/logo-icons/oops.svg";
import "../../components/oeligibility/Oeligibility.scss";
import axios from "axios";
import { useStep } from "../../hooks/hooks";
import { openLink } from "../../helpers/helpers";
// import { getCurrencyCode } from "../../helpers/utils";
import {
  getPhoneCode,
  getCurrencyCode,
  getCurrencyImg,
} from "../../helpers/utils";
const config = {
  cors: "https://cors-anywhere.herokuapp.com/",
  formUrl:
    "https://docs.google.com/forms/d/1FfETc0yXUbynYjnNThsE3BDENU_ay8Vj19Gx97EkpOc/formResponse",
};

const EB2Banner = (props) => {
  return (
    <div className="bg-secondary w-full pt-24 lg:pt-28 text-white">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 gap-20">
          <div className="col-span-1 mt-10">
            <h1 className="text-2xl font-fontSemi mt-20 lg:text-3xl xl:text-4xl pb-5 lg:w-[100%] lg:font-fontBold !leading-[50px] lg:!leading-[60px]">
              <span className="text-[#f4f7f3]">EB2-NIW Visa: </span>
              <span className="text-[#67A948]"> Secure US Residency</span>{" "}
              <span className="text-[#f4f7f3]">
                without Employer Sponsorship or Labour Certification
              </span>{" "}
            </h1>
            <p className="text-lg pb-12 lg:w-[80%] font-fontLight">
              Have a masters degree? Work and live in the US with the National
              Interest Waiver.
            </p>
            <div className="flex flex-row justify-between gap-4">
              <Button
                onClick={() => props.setOpen(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                See if you qualify
              </Button>
              <Button
                onClick={() =>
                  openLink("https://calendly.com/vesti/founderpaid", "_blank")
                }
                // onClick={() => props.setAppointmentModal(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
              >
                Speak to an Attorney
              </Button>
            </div>
          </div>
          <div className="col-span-1">
            <img className="w-full" src={woman} alt="Big Smile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EB2Banner;

export const EB2eligibility = (props) => {
  console.log(props);
  const [state, setState] = useState({
    fullName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    country: "",
    expertise: "",
    prizes: "",
    books: "",
    judge: "",
    degrees: "",
    reference: "",
  });
  var ids = [
    "275674196",
    "500981336",
    "364319452",
    "850618021",
    "491281489",
    "1376928382",
    "543109535",
    "713045706",
    "540324483",
    "39273814",
    "586209094",
  ];

  const { step, setStep, nextStep, previousStep } = useStep(0);
  const [score, setScore] = useState(6);
  var data = [
    {
      name: "expertise",
      title:
        "Do you consider your area of Expertise of National Interest to the United States?",
      value: "yes",
    },
    {
      name: "prizes",
      title:
        "Have you received any major prizes, scholarships, funding, fellowships, grants or awards in your field?",
      value: "yes",
    },
    {
      name: "books",
      title:
        "Have you authored any books, articles, or other scholarly work about your field?",
      value: "yes",
    },
    {
      name: "judge",
      title:
        "Have you previously served as a judge in your field or related fields?",
      value: "yes",
    },
    {
      name: "degree",
      title:
        "Do you have an advanced degree e.g (Masters or PHD), Answer yes if you have a Bachelors degree with at least 5+ years of experience.?",
      value: "yes",
    },
    {
      name: "reference",
      title:
        "Are you able to obtain reference letters from critical people in your field of study. (This has to be someone in your field, a professor, colleague, or industry professional)?",
      value: "yes",
    },
  ];
  var setInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  var handleOption = (name, value) => {
    if (state[name] === "Yes" || !state[name]) {
      value === "No" && setScore(score - 1);
    } else if (state[name] === "No") {
      value === "Yes" && setScore(score + 1);
    }
    setState({ ...state, [name]: value });
  };
  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            click={nextStep}
            setState={setState}
            state={state}
          />
        );
      case 1:
        return (
          <Steptwo
            disabled={
              Object.entries(state)
                .slice(4, 9)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(0, 4)}
            name="Continue"
            click={nextStep}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 2:
        return (
          <Stepthree
            disabled={
              Object.entries(state)
                .slice(10)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(4)}
            name="Submit"
            click={() => submit()}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 3:
        return (
          <Success
            title="Congratulations, You are a Fit for the EB2-NIW"
            subtitle="Congratulations, you are fit for the EB2-NIW VIsa."
            button="Book a call now!"
            onClick={() => {
              window.open("https://calendly.com/vesti/founderpaid");
              props.close();
            }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Bummer, We can help you get ready for the EB2-NIW"
            subtitle="Unfortunately you are not a Fit for the EB2-NIW Visa at the moment. However, we believe we can help you to decide which other Visa is better, also we can help prepare you for the EB2-NIW visa in 10-20weeks. A member of our team will reach out in 24-48 hours with additional information."
            button="Close"
            onClick={() => {
              props.close();
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value.label
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value
          );
    }

    await axios({
      url: `${config.formUrl}`,
      method: "post",
      data: formData,
      responseType: "json",
    })
      .then((res) => {
        axios
          .post(
            "https://syca-staging.wevesti.com/api/v1/general-services/actionable-signup",
            {
              email: state.email,
              score: score,
              type: "eb-two",
              country: state.country.label,
              fullName: state.fullName,
              // phoneNumber: state.phoneNumber,
              currency: getCurrencyCode(state.country.value),
              currencyFlag: getCurrencyCode(state.country.value),
              service: "eb-two",
              phoneNumber: {
                code: getPhoneCode(state.country.label),
                number: state.phoneNumber.slice(
                  getPhoneCode(state.country.label).length
                ),
              },
            }
          )
          .then((res) => {})
          .catch((err) => (() => {})());
      })
      .catch((error) => {
        //console.log(score, 'score value')
        score >= 3 ? setStep(3) : setStep(4);
        axios
          .post(
            "https://syca-staging.wevesti.com/api/v1/general-services/actionable-signup",
            {
              email: state.email,
              score: score,
              type: "eb-two",
              country: state.country.label,
              fullName: state.fullName,
              // phoneNumber: state.phoneNumber,
              currency: getCurrencyCode(state.country.value),
              currencyFlag: getCurrencyCode(state.country.value),
              service: "eb-two",
              phoneNumber: {
                code: getPhoneCode(state.country.label),
                number: state.phoneNumber.slice(
                  getPhoneCode(state.country.label).length
                ),
              },
            }
          )
          .then((res) => {});
      });
  };

  return (
    <div>
      {step < 3 && (
        <Titlesubtitle
          steps={` ${step + 1} of 3 `}
          title="See if an EB2-NIW Visa is a fit for you"
          subtitle="Take this quiz to see if the EB2-NIW Visa is a fit for you to move to America."
        />
      )}
      <div className="mb-4"></div>
      {__renderStep()}
    </div>
  );
};

const Stepone = (props) => {
  return (
    <div className="ostepone">
      <form className="ostepone__col">
        <div className="ostepone__row">
          <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.data.fullname}
            disabled={false}
            onChange={props.setInput}
          />
          <Phonenumber
            value={props.data.phoneNumber}
            country={props.data.country?.vlaue}
            setValue={(value) =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
        </div>
        <Inputfloat
          type="email"
          label="Email Address"
          name="email"
          placeholder="Johndoe@gmail.com"
          value={props.data.email}
          disabled={false}
          onChange={props.setInput}
        />
        <Singleselect
          label="Gender"
          value={props.data.gender}
          options={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Prefer not to say", value: "Prefer not to say" },
          ]}
          onChange={(value) => {
            //console.log(value.value);
            props.handleSelect("gender", value);
          }}
          placeholder="Select your gender "
        />

        <Singleselect
          label="Country of birth"
          value={props.data.country}
          options={props.countries}
          onChange={(value) => {
            props.handleSelect("country", value);
          }}
          placeholder="Select your country"
          // components={{ SingleValue: IconSingleValue, Option: IconOption }}
        />

        <div className="ostepone__btns">
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              Object.entries(props.data)
                .slice(0, 5)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};

const Steptwo = (props) => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data.map((item, index) => (
            <div className="ostepone__col" key={index}>
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <div style={{ width: "95%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`0 + ${item.name}`}
                    isSelected={props.state[item.name] === "Yes"}
                    label="Yes"
                    value={"Yes"}
                    style={{ width: "95%" }}
                  />
                </div>
                <div style={{ width: "95%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`1 + ${item.name}`}
                    isSelected={props.state[item.name] === "No"}
                    label="No"
                    value={"No"}
                    style={{ width: "95%" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const Stepthree = (props) => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data?.map((item, index) => (
            <div className="ostepone__col" key={index}>
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <div style={{ width: "95%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`0 + ${item.name}`}
                    isSelected={props.state[item.name] === "Yes"}
                    label="Yes"
                    value={"Yes"}
                  />
                </div>
                <div style={{ width: "95%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`1 + ${item.name}`}
                    isSelected={props.state[item.name] === "No"}
                    label="No"
                    value={"No"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};
