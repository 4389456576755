import { useState } from "react";
import "../components/oeligibility/Oeligibility.scss";
import axios from "axios";
import { useStep } from "../hooks/hooks";
import { Platformbutton } from "../components/helpers/button/button";
import { Phonenumber } from "../components/inputs/phoneinput";
import Inputfloat from "../components/inputs/inputfloat";
import { Titlesubtitle } from "../components/helpers/titlesubtitle/titlesubtitle";
import { Singleselect } from "../components/inputs/singleselect";
import { Success } from "../components/helpers/success/success";
import { useNavigate } from "react-router-dom";
const config = {
  cors: "https://cors-anywhere.herokuapp.com/",
  formUrl:
    "https://docs.google.com/forms/d/1Ka0fcXE5_uRFUtPVmzHz0x_ZnjclPnHyoHTG13VkJe0/formResponse",
};

const NITDAForm = (props) => {
  const navigate = useNavigate();
  const { step, setStep, nextStep } = useStep(0);
  const [state, setState] = useState({
    fullName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    concern: "",
    interest: "",
    feedback: "",
  });


  var ids = [
    "275674196",
    "500981336",
    "364319452",
    "850618021",
    "491281489",
    "1139714467",
    "713045706",
  ];

  var setInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            state={state}
            setInput={setInput}
            handleSelect={handleSelect}
            click={nextStep}
            setState={setState}
            submit={submit}
            navigate={navigate}
            close={props.close}
          />
        );
      case 1:
        return (
          <Success
            title="Success"
            subtitle="Successful, We will get in touch."
            button="Okay, Thank you"
            onClick={() => {
              props.close();
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value.label
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value
          );
    }

    await axios({
      url: `${config.formUrl}`,
      method: "post",
      data: formData,
      responseType: "json",
    })
      .then((res) => {
        setStep(1);
        axios
          .post(
            "https://api.wevesti.com/api/v1/general-services/ats-and-nitda",
            {
              email: state.email,
              type: "nitda",
              fullName: state.fullName,
            }
          )
          .then((res) => {})
          .catch((err) => (() => {})());
      })
      .catch((err) => {
        setStep(1);
        axios
          .post(
            "https://api.wevesti.com/api/v1/general-services/ats-and-nitda",
            {
              email: state.email,
              type: "nitda",
              fullName: state.fullName,
            }
          )
          .then((res) => {});
      });
  };

  return (
    <div>
      <div className="mb-4"></div>
      {__renderStep()}
    </div>
  );
};

export default NITDAForm;

const Stepone = (props) => {
  return (
    <div className="astepone">
      <Titlesubtitle
        steps="Step 1 of 1"
        title="Start Your Migration Journey with Vesti!"
        subtitle="Answer 7 simple questions to unlock valuable resources tailored to your immigration journey"
      />
      <form className="ostepone__col">
        <div className="ostepone__row">
          <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.state.fullname}
            disabled={false}
            onChange={props.setInput}
          />
          <Inputfloat
            type="email"
            label="Email Address"
            name="email"
            placeholder="Johndoe@gmail.com"
            value={props.state.email}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="ostepone__row">
          <Phonenumber
            value={props.state.phoneNumber}
            country={props.state.country?.vlaue}
            setValue={(value) =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
          <Singleselect
            label="Gender"
            value={props.state.gender}
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Prefer not to say", value: "Prefer not to say" },
            ]}
            onChange={(value) => {
              //console.log(value.value);
              props.handleSelect("gender", value);
            }}
            placeholder="Select your gender "
          />
        </div>

        <Singleselect
          label="My biggest immigration concern is:"
          value={props.state.concern}
          options={[
            {
              label: "Special Talent Visa application process",
              value: "Special Talent Visa application process",
            },
            {
              label: "Virtual Dollar Card services",
              value: "Virtual Dollar Card services",
            },
            {
              label: "Study abroad opportunities",
              value: "Study abroad opportunities",
            },
            {
              label: "Multi currency wallets",
              value: "Multi currency wallets",
            },
            {
              label: "Migration savings account",
              value: "Migration savings account",
            },
            {
              label: "Speaking to an Immigration Attorney",
              value: "Speaking to an Immigration Attorney",
            },
            {
              label: "Migration AI",
              value: "Migration AI",
            },
            {
              label: "Flight bookings",
              value: "Flight bookings",
            },
            {
              label: "Business registration in the USA",
              value: "Business registration in the USA",
            },
            {
              label: "",
              value: "",
            },
          ]}
          onChange={(value) => {
            props.handleSelect("concern", value);
          }}
          placeholder="My biggest immigration concern is:"
        />

        <div className="ostepone__row">
          <Inputfloat
            type="text"
            label="I am interested in:"
            name="interest"
            placeholder="I am interested in"
            value={props.state.interest}
            disabled={false}
            onChange={props.setInput}
          />
          <Inputfloat
            type="text"
            label="Any other feedback?"
            name="feedback"
            placeholder="Any other feedback?"
            value={props.state.feedback}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="ostepone__btns">
          <Platformbutton
            name="Submit"
            type="normal"
            disabled={
              !props.state.feedback ||
              !props.state.interest ||
              !props.state.interest ||
              !props.state.fullName ||
              !props.state.email ||
              !props.state.gender ||
              !props.state.phoneNumber
                ? true
                : false
            }
            click={() => props.submit()}
          />
        </div>
      </form>
    </div>
  );
};
