import React from "react";
import { H1bUsersData } from "./H1bCards";

export const H1bUser = () => {
  const borderColors = ["#67A948", "#16216B", "#67A948", "#67A948", "#16216B"];
  return (
    <div className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32 vlg:px-64 pt-24 lg:pt-[5rem]">
      <div className="sm:flex mb-12 sm:flex-col sm:items-center sm:justify-center md:flex md:flex-col md:items-center md:justify-center xl:flex xl:flex-col xl:items-center xl:justify-center xlg:flex xlg:flex-col xlg:items-center xlg:justify-center">
        <h1 className="text-[22px] xlg:text-[26px]  font-bold">
          What Our Users Are <span className="text-vestigreen">Saying</span>
        </h1>
        <p className="text-[14px] font-bold">
          Real stories of success with our H1-B services.
        </p>
      </div>

      <div className="w-full grid md:grid-cols-2 xl:grid-cols-3 xlg:grid-cols-3 gap-4 ">
        {H1bUsersData.map(
          ({ avatarImg, name, review, background, border }, index) => {
            return (
              <>
                {index <= 2 ? (
                  <div
                    key={index}
                    className={`block bg-[${background}] border-[0.5px] border-[${border}] rounded-md w-full px-4 py-4 sm:py-4 sm:px-6 md:py-4 md:px-6 xl:py-4 xl:px-6 xlg:py-4 xlg:px-6 `}
                  >
                    <div className="flex items-center gap-2">
                      <img src={avatarImg} alt="" />
                      <p className="font-bold">{name}</p>
                    </div>
                    <p className="mt-2 text-[14px] text-[#14290A]">{review}</p>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            );
          }
        )}
      </div>

      <div className="w-full flex justify-center flex-col md:flex-row  gap-4">
        {H1bUsersData.map(
          ({ avatarImg, name, review, background, border }, index) => {
            return (
              <>
                {index > 2 ? (
                  <div
                    key={index}
                    className={`block bg-[${background}] border-[0.5px] border-[${border}] rounded-md gap-y-2 w-full md:w-[27rem]  px-4 py-4 sm:py-4 sm:px-6 md:py-4 md:px-6 xl:py-4 xl:px-6 xlg:py-4 xlg:px-6`}
                  >
                    <div className="flex items-center gap-2">
                      <img src={avatarImg} alt="" />
                      <p className="font-bold">{name}</p>
                    </div>
                    <p className="mt-2 text-[14px] text-[#14290A]">{review}</p>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            );
          }
        )}
      </div>
    </div>
  );
};
