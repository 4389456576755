import React from "react";
import { images } from "./dataCards";

export const Gallery = () => {
  const firstRow = images.slice(0, 4);
  const secondRow = images.slice(4, 6);
  const thirdRow = images.slice(6, 11);

  return (
    <div className="px-4 md:px-8 lg:px-20 xxl:px-32  vlg:px-64 my-20">
      <p className="font-bold text-center text-3xl mb-10">
        Past Events Gallery
      </p>
      <div className="grid md:grid-cols-3 gap-4">
        {/* first Row */}
        <div className="col-span-1">
          <div className="grid md:grid-rows-2 lg:grid-rows-3 h-full gap-2 lg:gap-4">
            {firstRow.map((image, index) => (
              <div key={index} className="">
                <img
                  src={image}
                  alt=""
                  className="w-full rounded-md h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
        {/* second Row */}
        <div className="col-span-1">
          <div className="grid grid-rows-2 h-full gap-4">
            {secondRow.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-full rounded-md h-full object-cover"
              />
            ))}
          </div>
        </div>
        {/* third Row */}
        <div className="col-span-1">
          <div className="grid grid-rows-4 h-full gap-4">
            {thirdRow.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-full h-full rounded-md object-cover"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
