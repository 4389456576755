import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import SchoolsTestimony from "../components/ebnw/SchoolsTestimony";
import FaqSection from "../views/FaqSection";
import SchoolsSearch from "../views/SchoolsSearch";
import SchoolsHero from "../views/SchoolsHero";

const Schools = () => {
  return (
    <>
      <NavBar />
      <SchoolsHero />
      <SchoolsSearch />
      <SchoolsTestimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <FaqSection />
      <Footer />
    </>
  );
};

export default Schools;
