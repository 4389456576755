import NavBar from "../../containers/NavBar";
import Footer from "../../containers/Footer";
import MiaiDoc from "./MiaiDocs";
import bannerTosImg from "../../assets/miai/tou.png";

const Tos = () => {
  const underlinedText = {
    textDecoration: "underline",
  };
  return (
    <div>
      <NavBar />
      <MiaiDoc
        title="Miai ™ TERMS OF USE"
        date="Last Updated: Jan 02, 2024"
        link1="TERMS OF USE"
        subTitle="Learn about the terms that guide your usage of Miai ™"
        link2="PRIVACY POLICY"
        path1="/miai/tos"
        path2="/miai/privacy-policy"
        img={bannerTosImg}
        mainBody={
          <div className="text-justify text-[#14290A]">
            <h1 className="text-2xl font-fontReg">Introduction</h1>
            <p className="c">
              These Terms of Use (Terms) govern your utilization of Vesti (Miai
              ™) services for individuals and corporate entities, encompassing
              associated software applications and websites (collectively
              referred to as &quot;Services&quot;). By using our Services, you
              are entering into an agreement with Miai ™, acknowledging and
              accepting these Terms, which include our Terms of Use and Policies
              for dispute resolution through arbitration. Regardless of your
              residence, your engagement with our Services signifies your
              consent to abide by these Terms.
            </p>

            <h1 className="text-2xl font-fontReg pt-10">About Us</h1>
            <p className="pt-10 ">
              Vesti is a Migration - FinTech, with a mission to be the preferred
              provider of tailored assistance and financial services for
              immigrants from the time they decide to migrate till they arrive
              in a new country and every minute after.
            </p>
            <p className="pt-4 ">
              Miai ™: is a Migration AI developed by Vesti Technologies to
              provide answers to migration-related queries.
            </p>
            <h1 className="text-2xl font-fontReg py-10 ">Terms of Use</h1>

            <h1 className="text-2xl font-fontReg">
              1. Registration and Access
            </h1>
            <p className="pt-10">
              <span className="font-[600]">Minimum Age:</span> You must be at
              least 18 years old or meet the minimum age required in your
              country to use the Services, with parental or legal guardian
              consent required for users under 18.
            </p>
            <p className="py-10">
              <span className="font-[600]">Registration:</span> Accurate and
              complete information is necessary for account registration, and
              you are not to share your account credentials with others. You are
              responsible for all activities under your account.
            </p>

            <h1 className="text-2xl font-fontReg">2. Prohibited Activities:</h1>
            <p className="pt-10 ">
              You may not engage in illegal, harmful, or abusive activities
              while using our Services, such as infringing on rights or
              extracting software data. For example, you may not:
            </p>

            <ul className="flex flex-col gap-4 mt-6 mb-10">
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> Use our
                Services in a way that infringes, misappropriates, or violates
                anyone’s rights.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> Modify, copy,
                lease, sell, or distribute any of our Services.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p>Attempt to or
                assist anyone to reverse engineer, decompile, or discover the
                source code or underlying components of our Services, including
                our models, algorithms, or systems (except to the extent this
                restriction is prohibited by applicable law).
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> Interfere
                with or disrupt our Services, including circumventing any rate
                limits or restrictions or bypassing any protective measures or
                safety mitigations we put on our Services.
              </li>
            </ul>
            <h1 className="text-2xl font-fontReg">3. Third-Party Services</h1>
            <p className="py-10 font-fontLight">
              Our services may include third-party software, products, or
              services, (“Third Party Services”), and some parts of our Services
              may include output from those services (“Third Party Output”).
              Third Party Services and Third-Party Output are subject to their
              own terms, and we are not responsible for them.
            </p>
            <h1 className="text-2xl font-fontReg">4. Content</h1>
            <p className="py-10">
              <span className="font-[600]">Your Content:</span> You may provide
              input to the Services (“Input”), and receive output from the
              Services based on the Input (“Output”). Input and Output are
              collectively “Content.” You are responsible for Content, including
              ensuring that it does not violate any applicable law or these
              Terms. You represent and warrant that you have all rights,
              licenses, and permissions needed to provide Input to our Services.
            </p>

            <p className="py-10">
              <span className="font-[600]">Ownership of Content: </span> As
              between you and Miai ™ to the extent permitted by applicable law,
              you (a) retain your ownership rights in Input and (b) own the
              Output. We hereby assign to you all our rights, title, and
              interest, if any, in and to Output.
            </p>

            <p className="py-10">
              <span className="font-[600]">Use of Content:</span> We may use
              Content to develop, maintain, train, and, or improve our Services.
              Also, to ensure compliance with the law, and Terms and maintain
              the safety of our Services.
            </p>

            <p className="py-10">
              <span className="font-[600]">Accuracy of Content:</span>Given that
              Artificial intelligence is a rapidly evolving field of study, we
              are constantly working to improve our Services to make them more
              accurate, reliable, safe, and beneficial. Given the probabilistic
              nature of Artificial Intelligence, the use of our Services may, in
              some situations, result in Output that does not accurately reflect
              real people, places, or facts.
            </p>

            <h1 className="text-2xl font-fontReg">
              5. Disclaimer of Warranties
            </h1>
            <p className="py-10 ">
              Our Services are Provided “As Is.” except to the extent prohibited
              by Law, we and our affiliates and licensors make no warranties
              (express, implied, statutory, or otherwise) with respect to the
              Services, and disclaim all warranties including, but not limited
              to, warranties of merchantability, fitness for a particular
              purpose, satisfactory quality, non-infringement, and quiet
              enjoyment, and any warranties arising out of any course of dealing
              or trade usage. We do not warrant that the Services will be
              uninterrupted, accurate, or error-free, or that any Content will
              be secure or not lost or altered. You accept and agree that any
              use of Outputs from our Service is at your sole risk and you will
              not rely on Output as a sole source of truth or factual
              information, or as a substitute for professional advice.
            </p>
            <h1 className="text-2xl font-fontReg">
              6. Limitation of Liability
            </h1>
            <p className="py-10 ">
              We and our affiliates will not be liable for indirect, incidental,
              special, consequential, or exemplary damages incurred by reliance
              solely on information provided by Miai ™.
            </p>
            <h1 className="text-2xl font-fontReg">7. Indemnity</h1>
            <p className="py-10 ">
              If you are a business or organization, to the extent permitted by
              law, you will indemnify and hold harmless us, our affiliates, and
              our personnel, from and against any costs, losses, liabilities,
              and expenses (including attorneys’ fees) from third party claims
              arising out of or relating to your use of the Services and Content
              or any violation of these Terms.
            </p>
            <h1 className="text-2xl font-fontReg">
              8. Our Intellectual Property (IP) Rights
            </h1>
            <p className="py-10 ">
              We and our affiliates own all rights, title, and interest in and
              to the Services. You may only use our name and logo upon obtaining
              our written consent and in accordance with our other relevant
              policies.
            </p>
            <h1 className="text-2xl font-fontReg">
              9. Termination and Suspension
            </h1>
            <p className="py-10 ">
              Termination. You are free to stop using our Services at any time.
              We reserve the right to suspend or terminate your access to our
              Services or delete your account if we determine:
            </p>

            <ul className="flex flex-col gap-4 mt-6 mb-10">
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> You breached
                these Terms or our Usage Policies.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> We must do so
                to comply with the law.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p>Your use of
                our Services could cause risk or harm to Miai ™, our users, or
                anyone else.
              </li>
            </ul>

            <h1 className="text-2xl font-fontReg">10. Assignment</h1>
            <p className="py-10">
              You may not assign or transfer any rights or obligations under
              these Terms and any attempt to do so will be void. We may assign
              our rights or obligations under these Terms to any affiliate,
              subsidiary, or successor in the interest of any business
              associated with our Services.
            </p>
            <h1 className="text-2xl font-fontReg">
              11. Changes to Terms or Services
            </h1>
            <p className="py-10 ">
              Changes to These Terms or Our Services. We are continuously
              working to develop and improve our Services. We may update these
              Terms or our Services accordingly from time to time. For example,
              we may make changes to these Terms or the Services due to:
            </p>

            <ul className="flex flex-col gap-4 mt-6 mb-10">
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> Changes to
                the law or regulatory requirements.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p> Security or
                safety reasons.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p>Circumstances
                beyond our reasonable control.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p>Changes we
                make in the usual course of developing our Services.
              </li>
              <li className="flex items-center gap-2">
                <p className="bg-black w-2 h-2  rounded-full"></p>To adapt to
                new technologies.
              </li>
            </ul>

            <h1 className="text-2xl font-fontReg">12. Dispute Resolution</h1>
            <p className="py-10 ">
              Mandatory Arbitration: You agree to resolve any claims arising out
              of or relating to these Terms or our Services, regardless of when
              the claim arose, even if it was before these Terms existed (a
              “Dispute”), through final and binding arbitration
            </p>

            <h1 className="text-2xl font-fontReg">13. Governing Law</h1>
            <p className="py-10 ">
              These Terms will be deemed to be made and entered into in The
              State of Delaware, and be governed by, and construed and enforced
              in accordance with, the laws of The State of Delaware. Except as
              provided in the dispute resolution section above, all claims
              arising out of or relating to these Terms, all claims arising out
              of or relating to these Terms will be brought exclusively in the
              federal or state courts of The State of Delaware.
            </p>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default Tos;
