import React from "react";
import covenLogo from "../../assets/visaForm/covenlabsFullLogo.svg";
import InfraNova from "../../assets/H1bPetitionImg/infracomLogo.png";
import { FaArrowRight } from "react-icons/fa";

const data = [
  {
    logo: covenLogo,
    companyName: "Coven Work",
    text: "Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.",
    link: "https://joincovenworks.com",
    id: "1",
  },
  {
    logo: InfraNova,
    companyName: "Infra Nova Inc",
    text: "InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.",
    link: "https://infranova.us/",
    id: "2",
  },
];

export const FillingCompanies = () => {
  return (
    <div style={{}}>
      <p style={{ color: "#62943B", fontWeight: "600", fontSize: "18px" }}>
        About our Filing Companies
      </p>

      <div className="flex flex-col bg:flex-row mt-[10px] gap-[20px]">
        {data.map(({ logo, id, text, companyName, link }) => {
          return (
            <div
              style={{
                background: `${
                  companyName === "Coven Work" ? "#ECF8FF" : "#FFFCF5"
                }`,
                color: `${
                  companyName === "Coven Work" ? " #1481BA" : "#B54708"
                }`,
                padding: "10px",
              }}
            >
              <img
                src={logo}
                alt="Coven Works"
                className={`${
                  companyName === "Coven Work" ? "w-[10rem]" : "w-[5rem]"
                }`}
              />
              <p
                style={{
                  color: `${
                    companyName === "Coven Work" ? " #1481BA" : "#B54708"
                  }`,
                  fontWeight: "300",
                  padding: "10px 0px",
                }}
              >
                {text}
              </p>

              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  color: `${
                    companyName === "Coven Work" ? " #1481BA" : "#B54708"
                  }`,
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                Learn more <FaArrowRight />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
