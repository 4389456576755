const VisaBenefits = (props) => {
  return (
    <div className="w-full lg:pb-24 mt-10">
      <div className="container mx-auto lg:w-[90%] flex-wrap">
        <div className="text-center pb-10">
          <h1 className="text-[#14290A] text-xl lg:text-4xl md:text-4xl font-fontReg pb-4">
            {props.title}{" "}
            <span className="text-[#67A948]">{props.visaName}</span> Visa?
          </h1>
          <p className="text-[#2B5219] text-lg lg:text-2xl md:text-2xl font-fontLight">
            {props.subtitle}
          </p>
        </div>

        {/* Offerings Listed in Cards */}
        <div className="grid gap-y-10 gap-x-10 xl:grid-cols-3 lg:grid-cols-2 lg:gap-y-10 lg:gap-x-10 md:grid-cols-2 md:gap-5 sm:grid-cols-2 sm:gap-5 bg-[#F7FFF3]">
          {props.data?.map((item, index) => (
            <div
              key={index}
              className="flex flex-row gap-4 justify-between items-start  rounded-xl p-5 lg:p-10 font-fontReg"
            >
              {/* <div> */}
              <img src={item.icon} alt={item.alt} />
              {/* </div> */}
              <div className="">
                <h3 className="text-[#14290A] font-fontBold text-lg pb-1">
                  {item.cardTitle}
                </h3>
                <p className="text-[#2B5219] font-fontLight text-base pb-4">
                  {item.cardBody}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VisaBenefits;
