import React, { useState } from "react";
import InstagramLogo from "../../assets/logo-icons/instagram-logo.svg";
import { Link } from "react-router-dom";
import { openMail, openLink } from "../../helpers/helpers";
import elementlogo from "../../assets/element/ElementbyVestiWhite.png";
// import { openLink, openMail } from "../helpers/helpers";

export const Footer = () => {
  const [show, setShow] = useState({ value: false, name: "" });
  const d = new Date();
  let year = d.getFullYear();
  // eslint-disable-next-line
  const toggleShow = (name) => {
    setShow({ value: !show.value, name: name === show.name ? "" : name });
  };
  return (
    <>
      <div className="bg-[#330059] w-full px-4 md:px-8 py-7 lg:py-6 lg:px-[8rem] xxl:px-32  vlg:px-64 mt-[4rem] lg:mt-[6rem]  text-white  ">
        <div className="vlg:container vlg:mx-auto  pt-14 font-fontLight">
          <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-8 pb-20">
            <div>
              <div className="pr-[40px] cursor-pointer">
                <Link to="#">
                  <img
                    src={elementlogo}
                    alt="Logo"
                    className="w-[6rem] md:w-[10rem]"
                  />
                  {/* <img className="w-[150px]" src={VestiLogo} alt="vesti-logo" /> */}
                </Link>
              </div>
            </div>

            <div>
              <ul className="flex flex-col">
                <li className="text-base font-fontSemi pb-3">Quick Links</li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.wevesti.vesti_founders_app"
                  target="_blanck"
                  className="text-sm font-fontLight pb-2 hover:text-[#D090FF] hover:font-fontSemi"
                >
                  Download on Google play store
                </a>
                <a
                  href="https://apps.apple.com/us/app/element-by-vesti/id6445962968"
                  target="_blanck"
                  className="text-sm pb-2 font-fontLight hover:text-[#D090FF] hover:font-fontSemi"
                >
                  Download on App store
                </a>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base font-medium pb-3 font-fontSemi">
                  LEGAL
                </li>

                <Link
                  to="#"
                  className="text-sm font-fontLight pb-2 hover:text-[#D090FF] hover:font-fontSemi"
                >
                  Privacy Policy
                </Link>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base font-medium pb-3 font-fontSemi">
                  CONTACT
                </li>
                <li
                  className="text-sm font-fontLight pb-2 hover:cursor-pointer hover:text-[#D090FF]"
                  onClick={() => openMail("help@wevesti.com")}
                >
                  help@wevesti.com
                </li>
                <li
                  className="text-sm font-fontLight pb-2 hover:cursor-pointer hover:text-[#D090FF]"
                  onClick={() => openLink("https://vesti.tawk.help")}
                >
                  Help Center
                </li>
              </ul>
            </div>

            <div className="mx-[2rem]"></div>

            <div>
              <div className="pb-4">
                <ul className="flex">
                  {/* <Link to="https://www.twitter.com/Vestiofficial" className="">
                    <img src={TwitterLogo} alt="twitter-icon" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/vestiofficial"
                    className="pl-4"
                  >
                    <img src={LinkedinLogo} alt="linkedin-icon" />
                  </Link> */}
                  {/* <Link
                    to="https://www.facebook.com/profile.php?id=100088055724008&mibextid=ZbWKwL"
                    className="pl-4"
                  >
                    Threads
                  </Link> */}
                  <a
                    href="https://instagram.com/elementforstartups?igshid=MzRlODBiNWFlZA=="
                    target="_blanck"
                    className=""
                  >
                    <img src={InstagramLogo} alt="instagram-icon" />
                  </a>
                </ul>
              </div>
              <ul className="flex flex-col">
                <li className="text-base pb-3 font-fontSemi">
                  LAGOS, (NIGERIA)
                </li>
                <li className="text-sm font-light pb-2">
                  30 Furo Ezimora street, Lekki Phase 1
                </li>
                <li className="text-base py-4 font-fontSemi">
                  DALLAS TEXAS (USA)
                </li>
                <li className="text-sm font-fontLight">
                  1301, S Bowen rd, Ste 450, Arlington, Tx 76013.
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center pb-7 text-sm font-fontLight">
            <p className="mb-6  leading-5 w-[90%]  mx-auto text-sm font-fontLight">
              Element by Vesti partners with Stripe Payments Company for money
              transmission services and account services with funds held at
              Evolve Bank & Trust, Member FDIC. Element by Vesti Visa® Prepaid
              Cards are issued by Celtic Bank®, Member FDIC, pursuant to a
              license from Visa USA Inc.
            </p>

            <p>Copyrights &copy; Element {year}</p>
          </div>
        </div>
      </div>
    </>
  );
};
