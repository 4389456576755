import React from 'react'
import { StartRev } from './PartCards'

export const Reviews = () => {
  return (
    <div className='md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center px-4 md:px-[3%] xl:px-[5%] xlg:px-20 py-4 sm:py-6 md:py-8 xl:py-10 xlg:py-10'>
    <div className='bg-vestigreen rounded-md my-6 text-[#FFFFFF] mt-2 xlg:mt-4 p-4 xlg:p-16'>
        <h1 className='text-[27px] sm:text-[28px] md:text-[27px] xl:text[30px] xlg:text-[30px] font-bold text-center my-4'>What Startups Are Saying</h1>
        <div className='grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 xlg:grid-cols-3 gap-6 p-1 sm:p-2 md:p-4 xl:p-6 xlg:p-6'>
            {StartRev.map(({img, company, name, location, details, background}, index) => {
                return <div key={index} className={`bg-[${background}] rounded-md`}>
                    <div className='p-4'>
                        <div className='flex items-center gap-2'>
                            <img src={img} alt="" />
                            <div className='grid'>
                            <h6 className='text-[#14290A] text-[20px] font-normal'>{company}</h6>
                                <div className='flex items-center text-[#4F4F4F] gap-2 text-[12px] sm:text-[12px] md:text-[12px] xl:text-[14px] xlg:text-[14px]'>
                                    <p className=''>{name}</p>
                                    <p className=''>{location}</p>
                                </div>
                            </div>
                        </div>
                        <p className='text-[#4F4F4F] pt-4'>{details}</p>
                    </div>
                </div>
            })}
        </div>
    </div>
    </div>
  )
}
