import React from "react";
// eslint-disable-next-line
import purplebg from "../../assets/element/purpleElementanimatebg.svg";
import animatedbg from "../../assets/element/animatedpurple.svg";

export const Downloadelement = (props) => {
  return (
    <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
      <div className=" vlg:container vlg:mx-auto">
        <div className="bg-[#6F368B] px-12 relative h-[20rem] lg:h-[27rem] rounded-[16px] overflow-hidden flex justify-between gap-12">
          <img
            src={animatedbg}
            alt=""
            className=" absolute z-[5] top-0 left-0  opacity-[0.5] right-0 w-full h-full bottom-0"
          />
          <article className="z-10 flex flex-col justify-center mx-auto">
            <h2 className="text-[#DDAFFF] text-[22px] my-4 leading-[42px] font-[600] md:text-[38px] xxl:text-[42px] xlg:max-w-[800px] text-center">
              Without further ado, proceed to your favourite app store to{" "}
              <span className="text-white">download now</span>{" "}
            </h2>

            <div className="flex gap-4 font-fontReg mx-auto mt-6">
              <a
                ref={props.board}
                onClick={() => {
                  props.copyCodeToClipboard();
                }}
                href="https://play.google.com/store/apps/details?id=com.wevesti.vesti_founders_app"
                target="_blanck"
                className="bg-white py-2 pl-2 w-32 rounded-[6px] text-[#330059] flex gap-2 items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 29 30"
                  fill="none"
                >
                  <path
                    d="M28.6033 2.15293C29.0385 1.7177 29.0385 1.00523 28.6033 0.569951C28.168 0.134721 27.4556 0.134721 27.0203 0.569951L23.8734 3.71685L23.8998 3.74684C21.4793 1.44202 18.0841 0 14.3359 0C10.6902 0 7.37569 1.36246 4.97002 3.55825L4.97292 3.55503L1.98753 0.569638C1.5523 0.134408 0.839827 0.134408 0.404548 0.569638C-0.0306825 1.00487 -0.0306825 1.71734 0.404548 2.15262L3.45662 5.2047L3.48403 5.17504C1.95881 7.15228 1.05797 9.55796 1.05797 12.1481V30H27.6146L27.6143 12.1481C27.6143 9.65246 26.78 7.32667 25.3553 5.39136L25.3598 5.39651L28.6033 2.15293ZM8.92077 10.9179C7.91651 10.9179 7.10215 10.1036 7.10215 9.09933C7.10215 8.09507 7.91652 7.28072 8.92077 7.28072C9.92503 7.28072 10.7394 8.09509 10.7394 9.09933C10.7394 10.1036 9.92534 10.9179 8.92077 10.9179ZM19.7516 10.9179C18.7473 10.9179 17.933 10.1036 17.933 9.09933C17.933 8.09507 18.7473 7.28072 19.7516 7.28072C20.7558 7.28072 21.5702 8.09509 21.5702 9.09933C21.5702 10.1036 20.7565 10.9179 19.7516 10.9179Z"
                    fill="#330059"
                  />
                </svg>
                Android
              </a>

              <a
                ref={props.board}
                onClick={() => {
                  props.copyCodeToClipboard();
                }}
                href="https://apps.apple.com/us/app/element-by-vesti/id6445962968"
                target="_blanck"
                className="bg-white py-2 pl-2 w-32 text-[#330059] rounded-[6px] flex gap-2 items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 31 30"
                  fill="none"
                >
                  <g clip-path="url(#clip0_24764_68725)">
                    <path
                      d="M20.7087 0C19.1026 0.110624 17.2254 1.13437 16.1315 2.46749C15.1336 3.67685 14.3127 5.47309 14.6328 7.21871C16.3876 7.27308 18.2007 6.22496 19.2513 4.86935C20.2342 3.60748 20.9779 1.82249 20.7087 0Z"
                      fill="#330059"
                    />
                    <path
                      d="M27.0556 10.0665C25.5135 8.14093 23.3464 7.02344 21.2997 7.02344C18.5979 7.02344 17.455 8.31156 15.5778 8.31156C13.6422 8.31156 12.1717 7.02719 9.83515 7.02719C7.53997 7.02719 5.09605 8.42405 3.54648 10.8128C1.36804 14.1765 1.74084 20.5009 5.27116 25.8877C6.53454 27.8152 8.22156 29.9827 10.4282 30.0014C12.392 30.0202 12.9456 28.7471 15.606 28.7339C18.2665 28.7189 18.7711 30.0183 20.7311 29.9977C22.9397 29.9808 24.719 27.5789 25.9823 25.6515C26.888 24.2696 27.225 23.574 27.9273 22.014C22.8192 20.0771 22.0001 12.8434 27.0556 10.0665Z"
                      fill="#330059"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_24764_68725">
                      <rect width="30.1255" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Apple
              </a>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};
