import { Link } from "react-router-dom";

export const Button = ({ link, CTA, styles, width }) => {
  return (
    <div
      className={`delay-150 ${styles} lg:w-[${width}] sm:w-[50%] font-fontReg text-[#fff] text-center bg-[#67A948] cursor-pointer transition ease-in-out hover:bg-headcol hover:transition-all duration-1000 rounded-xl`}
    >
      <Link to={link}>{CTA}</Link>
    </div>
  );
};

export const ButtonTab = ({ link, CTA, styles, width }) => {
  return (
    <div
      className={` ${styles} lg:w-[${width}] sm:w-[50%] font-fontReg text-[#fff] text-center bg-[#67A948] cursor-pointer transition duration-700 ease-in-out hover:bg-[#3E6F26]  rounded-xl`}
    >
      <Link onClick={() => window.open(link, "_blank")}>{CTA}</Link>
    </div>
  );
};

export default Button;

// hover:bg-headcol
