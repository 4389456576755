import React, {  useState } from 'react'
import NavBar from '../../containers/NavBar'
import Footer from '../../containers/Footer'
import { EventHero } from './EventHero'
import { UpcomingEvent } from './UpcomingEvent'
import { Impact } from './Impact'
import { Reasons } from './Reasons' 
import { Updates } from './Updates'
import { Gallery } from './Gallery'
import axios from '../api/axios'


export const Events = () => {
  const [email, setEmail] = useState('')

  const [message, setMessage] = useState('')
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [errorEmail, setErrorEmail] = useState(true)


  const handleSubmit = async () => {
    const payload = {
      email: email,
       };

    axios
      .post("/v1/vesti-events/email-subscribe", payload)
      .then((response) => {
      setMessage(response.data.message)
      setSubscriptionModal(true)
      })
      .catch((error) => {
        console.log(error)

        setMessage('An Error Occured')
        setSubscriptionModal(true)
      });
setEmail('')
  };
  return (
    <>
        <NavBar />
        <EventHero handleSubmit={handleSubmit} errorEmail={errorEmail} setErrorEmail={setErrorEmail} setMessage={setMessage} setSubscriptionModal = {setSubscriptionModal} message={message} subscriptionModal={subscriptionModal} email={email} setEmail={setEmail}/>
        <UpcomingEvent />
        <Impact />
        <Reasons />
        <Updates handleSubmit={handleSubmit} errorEmail={errorEmail} setErrorEmail={setErrorEmail} setMessage={setMessage} setSubscriptionModal = {setSubscriptionModal} message={message} subscriptionModal={subscriptionModal} email={email} setEmail={setEmail}/>
        <Gallery />
        <Footer/>
       
        
    </>
  )
}

