import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Navbar } from "./navbar";
import { Elementbanner } from "./elementbanner";
import { Elemententerprenuer } from "./elemententerprenuer";
import { OpenaccountOnline } from "./openaccountOnline";
import { ElementFeature } from "./elementFeature";
import { Downloadelement } from "./downloadelement";
import { Easygetstarted } from "./easygetstarted";
import Ebook from "./ebook";
import Review from "./review";
import { Footer } from "./footer";

const ElementLandingPage = () => {
  const { referralcode } = useParams();
  const [id, setId] = useState("");

  const board = useRef(null);

  useEffect(() => {
    if (referralcode) {
      setId(`<element${referralcode}>`);
    }
  }, []);

  const copyCodeToClipboard = () => {
    if (id) {
      navigator.clipboard.writeText(id);
    }
  };

  return (
    <div>
      <Navbar id={id} board={board} copyCodeToClipboard={copyCodeToClipboard} />
      <Elementbanner
        id={id}
        board={board}
        copyCodeToClipboard={copyCodeToClipboard}
      />
      <Elemententerprenuer />
      <OpenaccountOnline />
      <ElementFeature />
      <Downloadelement
        id={id}
        board={board}
        copyCodeToClipboard={copyCodeToClipboard}
      />
      <Easygetstarted />
      <Ebook />
      <Review />
      <Footer />
    </div>
  );
};

export default ElementLandingPage;
