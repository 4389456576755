import React from 'react'
import { reasons } from './prosperData' 


export const ProsperRea = () => {
    return (
        <div className=' px-4 md:px-[4%] xl:px-[6%] xlg:px-[6%] pt-4'>
            <h1 className='font-bold text-center text-[26px] sm:text-[30px]'>Reasons You Should Attend Our Events</h1>
            <div className='grid grid-cols-2 lg:grid-cols-3 py-10 gap-3 lg:gap-8'>
                {reasons.map(({img2, title, description, background, color}, index) => {
                    return <div key={index} className={`bg-[${background}] text-[${color}] rounded-md`}>
                        <div className='p-4 lg:p-8'>
                            <img src={img2} alt="" />
                            <h3 className='mt-4 font-bold text-black'>{title}</h3>
                            <p className='mt-4 text-[14px] w-[90%] md:w-[75%]'>{description}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
      )
}
