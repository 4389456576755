import React, { useState } from 'react'
import axios from '../api/axios'
import NavBar from '../../containers/NavBar'
import Footer from '../../containers/Footer'
import { ProsperRea } from './prosperRea'
import { Updates } from '../Events/Updates'
import { ProsperQues } from './prosperQues'
import { ProsperPart } from './ProsperPart'
import { ProsConfHero } from './ProsConfHero'
import { Gateway } from './Gateway'
import { Speakers } from './Speakers'
import { PastEventPic } from './pastEventPic'




export const ProsperConf = () => {

  const [email, setEmail] = useState('')

  const [message, setMessage] = useState('')
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [errorEmail, setErrorEmail] = useState(true)


  const handleSubmit = async () => {
    const payload = {
      email: email,
       };

    axios
      .post("/v1/vesti-events/email-subscribe", payload)
      .then((response) => {
      setMessage(response.data.message)
      setSubscriptionModal(true)
      })
      .catch((error) => {
        console.log(error)

        setMessage('An Error Occured')
        setSubscriptionModal(true)
      });
setEmail('')
  };
  return (
    <div>
        <NavBar />
        <ProsConfHero />
        <Gateway />
        <Speakers />
        <ProsperRea />
        <PastEventPic />
        <Updates  handleSubmit={handleSubmit} errorEmail={errorEmail} setErrorEmail={setErrorEmail} setMessage={setMessage} setSubscriptionModal = {setSubscriptionModal} message={message} subscriptionModal={subscriptionModal} email={email} setEmail={setEmail} />
        <ProsperQues />
        <ProsperPart />
        <Footer />
    </div>
  )
}
