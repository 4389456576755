import React from "react";
import flightHero from "../assets/flight/flightbanner.svg";

export const FlightAd = (props) => {
  return (
    <div className="w-full  px-4 md:px-8 py-7 lg:py-0 lg:px-20  xxl:px-32 vlg:px-64  pt-8 lg:pt-12">
      <div className="vlg:container  vlg:mx-auto">
        <div className={props.addStyle}>
          {props.leftSide}
          <div className="text-center px-6">
            <img
              className="bg:h-[20rem] lg:h-[25rem] h-[15rem] sm:h-[22rem] md:h-[27rem] w-full "
              src={flightHero}
              alt="vesti-mastercard"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
