// import FeaturedLogos from "../assets/logo-icons/feat-logos.png";
// import GlobalPageImage from "../assets/images/gpphone.png";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Techstars from "../assets/logo-icons/techstars1.svg";
import Techcity from "../assets/logo-icons/techcity1.svg";
import Bloomberg from "../assets/logo-icons/bloomberg1.svg";
import { openLink } from "../helpers/helpers";
import heroGif from "../assets/images/showcaseGif.gif";

const Miaishowcase = () => {
  return (
    <div className="bg-[#060E42] w-full px-4 md:px-8 pt-12 lg:pt-20 lg:px-20 xxl:px-32  vlg:px-64  text-white font-fontReg">
      <div className="vlg:container  vlg:mx-auto ">
        <div className="grid bg:grid-cols-2 gap-20  lg:gap-0 xlg:gap-8">
          <div className="col-span-1 pt-20">
            <h1 className="text-4xl font-fontSemi lg:text-5xl bg:text-[36px] xl:text-[34px] pb-5  xlg:w-[80%] lg:font-fontBold !leading-[50px] lg:!leading-[60px] xlg:!leading-[50px]">
              The
              <span className="text-[#67A948]"> AI Co-Pilot</span> for <br />
              <span className="text-[#67A948]"> 1 Billion </span> Immigrants
            </h1>
            <p className="text-lg pb-12 lg:w-[80%] lg:text-[22px] bg:text-[17px] font-sansec  ">
              Millions of people moving to new countries use Vesti for accessing
              financial services like Financial Accounts, debit and credit
              cards.
              {/* <br className="hidden xlg:block" /> accessing legal and financial
              services like bank accounts, debit and credit cards. */}
            </p>
            <Link
              // to="https://app.wevesti.com/auth"
              onClick={() =>
                window.open("https://app.wevesti.com/auth", "_blank")
              }
            >
              <Button className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]">
                Get started in minutes
              </Button>
            </Link>
            {/* <img src={FeaturedLogos} alt="featured" /> */}
            <div className=" flex flex-wrap mt-8  lg:mt-0 lg:mb-10  items-center opacity-80 gap-12 xl:gap-10">
              <img
                className="cursor-pointer"
                src={Techstars}
                alt="techstars"
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                  )
                }
              />
              <img
                className="cursor-pointer"
                src={Techcity}
                alt="techcity"
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                  )
                }
              />
              <img
                className="xxl:flex justify-center"
                src={Bloomberg}
                alt="bloomberg"
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                  )
                }
              />
            </div>
          </div>
          <div className="col-span-1 bg:relative ">
            <img
              className="w-full bg:absolute object-center  xlg:h-[100%] xxl:h-[100%]  xlg:w-[100%] bottom-0 right-0"
              src={heroGif}
              alt="globalpage-phone"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Miaishowcase;
