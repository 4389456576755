import { useState } from "react";
import staff from "../assets/images/staff.png";
import { ActiveCardd } from "../components/ActiveCard";

const data = [
  {
    id: 0,
    title: "Wallets",
    desc: "Vesti gives humans the power to move overseas to 110 Countries.",
    image: staff,
    learnMore: "/",
  },
  {
    id: 1,
    title: "Cards",
    desc: "Vesti gives humans the power to move overseas to 110 Countries. ",
    image: staff,
  },
  {
    id: 2,
    title: "Pathways to other countries",
    desc: "Vesti gives humans the power to move overseas to 110 Countries. ",
    image: staff,
  },
];

const LifeAtVesti = () => {
  const [activeTrx, setActiveTrx] = useState(0);

  return (
    <div className="w-full px-4 md:px-8 py-7 lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-12 lg:pt-28">
      <div className="vlg:container  vlg:mx-auto grid grid-rows-1 gap-10 lg:grid-cols-2 lg:grid-rows-1 lg:gap-44 ">
        <div className="col-span-1">
          <h3 className="text-headcol font-fontReg text-3xl pb-10">
            Life At Vesti
          </h3>
          {data.map((item, index) => (
            <ActiveCardd
              title={item.title}
              index={index}
              key={index}
              desc={item.desc}
              activeTrx={activeTrx}
              onClick={() => setActiveTrx(index)}
              learnMore={item.learnMore}
            />
          ))}
        </div>

        <div className="col-span-1 md:col-span-0">
          {/* <div className="bg-[#F7FFF3] relative mt-auto h-[70%] sm:h-[80%] lg:h-full rounded-2xl overflow-hidden"> */}
          <img
            src={data[activeTrx].image}
            // className="flex w-[70%] sm:w-[50%] sm:left-[25%] md:w-[40%] md:left-[30%] lg:left-[20%] lg:w-[60%]"
            alt="slider"
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default LifeAtVesti;
