import React, { useState } from "react";
import Select from "react-select";
import { components } from "react-select";
import "./singleselect.scss";
import { Profiletitle } from "./titlesubtitle/titlesubtitle";
import "../inputs/inputfloat.scss";

const { SingleValue, Option } = components;
// import 'react-select/dist/react-select.css';

export const Singleselect = (props) => {
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  const style = {
    control: (base, state, isFocused) => ({
      ...base,
      height: "60px",
      backgroundColor: isFocused ? "#67A948" : "#F9F8F9",
      border: isFocused ? ".5px solid #67A948" : ".5px solid #CCCCCC",
      boxShadow: "0 !important",
      "&:hover": {
        border: ".5px solid #CCCCCC",
      },
      tabIndex: 1,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#151617",
        backgroundColor: isFocused ? "#F9F8F9" : "",
        borderTop: "1px solid #F8F6F5",
        lineHeight: "3em",
        display: "flex",
        alignItems: "center",
        zIndex: 9999,
        isDisabled: isDisabled,
      };
    },
  };

  return (
    <div
      className="select-input"
      tabIndex="1"
      onFocus={() => setShow(true)}
      onBlur={() => setShow(false)}
    >
      {props.label && props.sublabel && (
        <Profiletitle
          label={props.label}
          sublabel={props.label && `Edit ${props.label}`}
        />
      )}
      <div
        className="select-box"
        style={{
          position: "relative",
          display: "inline-block",
          width: 100 + "%",
        }}
      >
        <Select
          id="color"
          className="select"
          placeholder={props.placeholder}
          options={props.options}
          // multi={false}
          value={props.value}
          onChange={props.onChange}
          styles={style}
          components={
            props.components
              ? props.components
              : {
                  IndicatorSeparator: () => null,
                }
          }
        />
        {props.children}
      </div>
    </div>
  );
};

export const IconSingleValue = (props) => (
  <SingleValue {...props} className="flex items-center justify-start">
    <img
      src={props.data.image}
      style={{ height: "15px", marginRight: "10px" }}
      alt="Flags"
    />
    {props.data.label}
  </SingleValue>
);

export const IconOption = (props) => (
  <Option {...props} className="flex items-center justify-start">
    <img
      src={props.data.image}
      style={{ height: "15px", marginRight: "10px" }}
      alt="Flags"
    />
    {props.data.label}
  </Option>
);

export const BigIconSingleValue = (props) => (
  <SingleValue {...props} className="flex items-center justify-start">
    {props.data.image ? (
      <img
        src={props.data.image}
        style={{ height: "20px", marginRight: "10px" }}
        alt="Flags"
      />
    ) : (
      <p style={{ color: "#67A948", fontSize: "14px" }}>{props.data.value}</p>
    )}
    {props.data.label}
  </SingleValue>
);

export const BigIconOption = (props) => (
  <Option {...props} className="flex items-center justify-start">
    {props.data.image ? (
      <img
        src={props.data.image}
        style={{ height: "20px", marginRight: "10px" }}
        alt="Flags"
      />
    ) : (
      <p style={{ color: "#67A948", fontSize: "14px" }}>{props.data.value}</p>
    )}
    {props.data.label}
  </Option>
);

export const CustomizedSingleselect = (props) => {
  const [show, setShow] = useState(false);

  const style = {
    control: (base, state, isFocused) => ({
      ...base,
      height: "60px",
      backgroundColor: isFocused ? "#67A948" : "#F9F8F9",
      border: isFocused ? ".5px solid #67A948" : ".5px solid #CCCCCC",
      boxShadow: "0 !important",
      "&:hover": {
        border: ".5px solid #CCCCCC",
      },
      tabIndex: 1,
    }),
    option: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      color: "#151617",
      backgroundColor: isFocused ? "#F9F8F9" : "",
      borderTop: "1px solid #F8F6F5",
      lineHeight: "3em",
      display: "flex",
      alignItems: "center",
      zIndex: 9999,
      isDisabled: isDisabled,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "13px", // Adjust the font size here
    }),
  };

  const handleSelectChange = (selectedOption) => {
    props.onChange(selectedOption.value);
  };

  return (
    <div
      className="select-input"
      tabIndex="1"
      onFocus={() => setShow(true)}
      onBlur={() => setShow(false)}
    >
      {props.label && props.sublabel && (
        <Profiletitle label={props.label} sublabel={`Edit ${props.label}`} />
      )}
      <div
        className="select-box"
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        {props.value ? (
          <div
            style={{
              backgroundColor: "#C7FFAD",
              position: "absolute",
              top: "-10px",
              zIndex: "10",
              color: "#2b5219",
              fontSize: "8px",
              padding: "5px 10px",
              fontWeight: "600",
            }}
          >
            {props.name}
          </div>
        ) : (
          <></>
        )}

        <Select
          id="color"
          className="select"
          placeholder={props.placeholder}
          options={props.options}
          value={props.options.find((option) => option.value === props.value)}
          onChange={handleSelectChange}
          styles={style}
          components={
            props.components
              ? props.components
              : {
                  IndicatorSeparator: () => null,
                }
          }
        />

        {props.children}
      </div>
    </div>
  );
};
