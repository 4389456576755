import speakerImg from "../../assets/ProsConfImg/speakerImg.png";
import greenImg from "../../assets/Event-Img/greenImg.png";
import blueImg from "../../assets/Event-Img/blueImg.png";
import purImg from "../../assets/Event-Img/purImg.png";
import serena from "../../assets/ProsConfImg/Serena Williams.png";
import KevinHart from "../../assets/ProsConfImg/Kevin Hart.png";
import sorinyo from "../../assets/ProsConfImg/Olakunle Sorinyo.png";
import serunjoji from "../../assets/ProsConfImg/Ham serunjoji.png";
import gregKid from "../../assets/ProsConfImg/Greg kidd.png";
import waleSal from "../../assets/ProsConfImg/Wale Salami.png";
import opeyemiA from "../../assets/ProsConfImg/Opeyemi Awoyemi.png";
import olusola from "../../assets/ProsConfImg/Olusola Amusan.png";
import bimbo from "../../assets/ProsConfImg/Abimbola Amusan.png";
import johnJide from "../../assets/ProsConfImg/johnJide.png";
// import overlayImg from '../../assets/ProsConfImg/overlayImg.png'

export const speakerData = [
  {
    img: serena,
    name: "Serena Williams",
    title: "Former Tennis Player/Entrepreneur",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Serena Williams |",
      title: "Former Tennis Player/Entrepreneur",
      bio: (
        <>
          Serena Williams is a retired professional tennis player and
          entrepreneur renowned for her prowess on the court and her influence
          off it. She has won 23 Grand Slam singles titles, the most in the Open
          Era, and has been a dominant force in women's tennis for over two
          decades. <br />
          <br />
          A multifaceted individual, Serena has ventured into various business
          endeavors, including her fashion line "S by Serena" and her venture
          capital firm, Serena Ventures, which focuses on funding early-stage
          startups and empowering underrepresented entrepreneurs.
          <br />
          <br />
          Before retiring, Serena completed a stellar career that included four
          Olympic gold medals, numerous endorsement deals, and a significant
          impact on the sport's popularity and visibility. Her advocacy for
          gender and racial equality, coupled with her philanthropic efforts,
          underscores her commitment to creating a better world both within and
          beyond the realm of sports.
          <br />
          <br />
          Trained originally in tennis from a young age, Serena turned
          professional in 1995. She has a strong educational background, having
          completed courses in business and management. Balancing her roles as
          an athlete, entrepreneur, and mother, she lives in Florida and Los
          Angeles.
        </>
      ),
    },
  },

  {
    img: KevinHart,
    name: "Kevin Hart",
    title: "Comedian and Actor",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Kevin Hart |",
      title: "Comedian and Actor",
      bio: (
        <>
          Kevin Hart, born on July 6, 1979, in Philadelphia, Pennsylvania, is an
          American comedian, actor, and producer known for his energetic and
          relatable comedy style. He began his career performing in clubs,
          eventually breaking through with his first big role on the TV series
          "Undeclared" in 2001. His stand-up career took off with the release of
          his album "I'm a Grown Little Man" in 2008, followed by successful
          specials like "Seriously Funny" (2010) and "Laugh at My Pain" (2011),
          which established him as a leading figure in comedy. <br />
          <br />
          Hart transitioned into acting with significant roles in popular films
          such as "Think Like a Man" (2012), "Ride Along" (2014), and the
          "Jumanji" series (2017, 2019). His versatility has allowed him to
          shine in both comedic and dramatic roles, making him a household name
          in Hollywood. In addition to acting, Hart is a successful producer
          with his company, HartBeat Productions, creating content across
          various platforms.
          <br />
          <br />
          Despite facing personal challenges, including a serious car accident
          in 2019, Hart has remained a dominant force in entertainment. He is
          also an author, with his memoir "I Can't Make This Up: Life Lessons"
          becoming a bestseller. Known for his resilience and work ethic, Hart
          continues to influence the entertainment industry and beyond,
          including his philanthropic efforts in education and disaster relief.
          <br />
          <br />
        </>
      ),
    },
  },

  {
    img: sorinyo,
    name: "Olakunle Soriyan",
    title: "Futurist and Author",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Olakunle Soriyan |",
      title: "Futurist and Author",
      bio: (
        <>
          Olakunle Soriyan is a prominent Nigerian speaker, author, and
          leadership consultant, known for his work in personal and
          organizational transformation. He is the founder of the Olakunle
          Soriyan Company, which focuses on developing visionary leaders and
          helping organizations achieve sustainable growth. His expertise in
          leadership and human capital development has made him a sought-after
          advisor across various industries.
          <br />
          <br />
          Driven by a passion for personal growth, Soriyan has delivered
          impactful talks and workshops globally, working with business leaders,
          government agencies, and educational institutions. His ability to
          inspire and equip individuals to reach their full potential has earned
          him a reputation as a trusted voice in leadership and strategy.
          <br />
          <br />
          In addition to his speaking and consulting work, Soriyan is an
          accomplished author, with several books on leadership and personal
          development. His writings offer practical wisdom and strategies for
          success, making him a significant influence on a generation of leaders
          striving for excellence and innovation.
          <br />
          <br />
        </>
      ),
    },
  },

  {
    img: serunjoji,
    name: "Ham Serunjogi",
    title: "Co-founder/CEO, Chipper Cash",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Ham Serunjogi |",
      title: "Co-founder/CEO, Chipper Cash",
      bio: (
        <>
          Ham Serunjogi is a Ugandan entrepreneur and co-founder of Chipper
          Cash, a fintech company offering mobile-based, cross-border payment
          services in Africa. After studying Environmental Studies at Grinnell
          College in the U.S., Serunjogi co-founded Chipper Cash in 2018 with
          Maijid Moujaled, aiming to address financial challenges in Africa
          through technology.
          <br />
          <br />
          Under Serunjogi's leadership, Chipper Cash grew rapidly, providing
          fee-free money transfers and expanding its services across Africa and
          into markets like the U.S. and U.K. The company attracted major
          investments, including a $100 million Series C round in 2021,
          cementing its place as a leading fintech player.
          <br />
          <br />
          Serunjogi is recognized for advancing financial inclusion in Africa,
          with a vision to connect the continent through accessible and
          affordable financial services.
          <br />
          <br />
        </>
      ),
    },
  },

  {
    img: gregKid,
    name: "Greg Kidd",
    title: "Co-founder/CEO, Hard Yaka Inc",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Greg Kidd |",
      title: "Co-founder/CEO, Hard Yaka Inc",
      bio: (
        <>
          Greg Kidd is an influential entrepreneur and investor, known for his
          significant contributions to the financial technology (fintech)
          sector. He co-founded Hard Yaka, an early-stage investment firm, and
          has been involved in backing several high-profile fintech startups,
          including Twitter, Ripple, and Coinbase. Kidd's expertise lies in risk
          management and digital payments, having served as a senior analyst at
          the Federal Reserve, where he focused on payment systems and
          electronic transfers.
          <br />
          <br />
          Kidd's most notable role was as Chief Risk Officer at Ripple, where he
          played a key part in shaping the company’s risk and compliance
          strategies during its early years. His experience at Ripple, combined
          with his work at the Federal Reserve, positioned him as a thought
          leader in the intersection of finance and technology. Greg's insights
          into regulatory challenges and market dynamics have made him a
          sought-after advisor and investor in the fintech space.
          <br />
          <br />
          Beyond his work in fintech, Greg Kidd has been a vocal advocate for
          decentralized finance and blockchain technology. He has consistently
          emphasized the importance of innovation in financial services to
          increase accessibility and security. Through his investments and
          strategic guidance, Kidd continues to shape the future of digital
          finance, influencing both startups and established financial
          institutions.
        </>
      ),
    },
  },

  {
    img: waleSal,
    name: "Wale Salami",
    title: "Angel Investor, Midlo Angels",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Wale Salami |",
      title: "Angel Investor, Midlo Angels",
      bio: (
        <>
          Wale Salami is a Nigerian entrepreneur and business leader known for
          his significant contributions to the technology and financial services
          sectors in Africa. He has built a reputation as a dynamic innovator
          with a focus on creating solutions that address critical needs within
          emerging markets. Wale is best known as the co-founder and CEO of
          Vesti, a fintech company that provides digital banking services
          tailored to the unique needs of immigrants and people looking to
          relocate. Under his leadership, Vesti has grown rapidly, gaining
          recognition for its user-friendly platform and commitment to financial
          inclusion.
          <br />
          <br />
          Before founding Vesti, Wale Salami held various leadership roles in
          the tech industry, where he developed a deep understanding of the
          intersection between technology and finance. His career began in
          software development, but his passion for entrepreneurship soon led
          him to focus on building businesses that leverage technology to solve
          real-world problems. Wale's ability to identify market gaps and create
          innovative products has made him a prominent figure in the African
          tech ecosystem.
          <br />
          <br />
          In addition to his work with Vesti, Wale is also an advocate for
          education and empowerment in Africa. He frequently speaks at
          conferences and events, sharing his insights on entrepreneurship,
          technology, and financial inclusion. His vision extends beyond
          business success, as he is deeply committed to using his platform to
          drive positive change and create opportunities for the next generation
          of African leaders.
          <br />
          <br />
        </>
      ),
    },
  },

  {
    img: opeyemiA,
    name: "Opeyemi Awoyemi",
    title: "Partner, Fast Forward Venture Studio",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Opeyemi Awoyemi |",
      title: "Partner, Fast Forward Venture Studio",
      bio: (
        <>
          Opeyemi Awoyemi is a prominent Nigerian entrepreneur and technology
          innovator, recognized for his influential role in the tech industry.
          Born in Nigeria, Awoyemi studied Computer Science at the University of
          Ibadan, where he began to develop his passion for technology and
          innovation. He gained significant recognition as a co-founder of
          Jobberman, Nigeria's leading job search portal, which he helped
          establish in 2009. The platform became a crucial resource for job
          seekers and employers in Nigeria, marking a significant milestone in
          the digital job market.
          <br />
          <br />
          In addition to his success with Jobberman, Awoyemi has been involved
          in several other ventures and initiatives aimed at promoting
          technological advancement and entrepreneurship in Africa. He is a
          founding partner at the African Technology Foundation (ATF), an
          organization dedicated to fostering technological innovation and
          supporting startups across the continent. His efforts in the tech
          space have earned him accolades and a reputation as a key player in
          driving Africa’s digital transformation.
          <br />
          <br />
          Awoyemi’s impact extends beyond entrepreneurship; he is also a vocal
          advocate for technology-driven solutions to address social and
          economic challenges. Through his various ventures and philanthropic
          efforts, he continues to champion the role of technology in shaping
          the future of Africa, contributing to the development of a more
          connected and innovative continent. <br />
          <br />
        </>
      ),
    },
  },

  {
    img: johnJide,
    name: "John Olajide",
    title: "Co-founder/CEO, Vesti",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "John Olajide |",
      title: "CEO, Axxess",
      bio: (
        <>
          John Olajide is a prominent entrepreneur and technology innovator,
          known for his significant contributions to the tech industry. He is
          the co-founder and CEO of Sudo Africa, a platform designed to simplify
          and enhance digital transactions for users across the continent. With
          a strong background in technology and a vision for leveraging digital
          solutions to solve real-world problems, Olajide has played a crucial
          role in advancing fintech in Africa.
          <br />
          <br />
          Before founding Sudo Africa, Olajide gained extensive experience in
          the technology sector through various roles and ventures. His career
          includes work with notable tech companies, where he developed a deep
          understanding of digital infrastructure and financial systems. His
          expertise and leadership have been instrumental in driving innovation
          and creating impactful solutions within the fintech space.
          <br />
          <br />
          Olajide's work is marked by a commitment to improving financial
          inclusion and accessibility for underserved communities. His
          leadership at Sudo Africa reflects his dedication to leveraging
          technology to address economic challenges and empower individuals.
          Through his entrepreneurial efforts, Olajide continues to shape the
          future of fintech in Africa and beyond.
          <br />
          <br />
        </>
      ),
    },
  },

  {
    img: olusola,
    name: "Olusola Amusan",
    title: "Co-founder/CEO, Vesti",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Olusola Amusan |",
      title: "Co-founder/CEO, Vesti",
      bio: (
        <>
          Olusola (Olu) Amusan, is Co-founder and CEO at Vesti where he is
          building a financial future using Artificial Intelligence Copilots for
          immigrants. Vesti software simplifies the journey and transition of
          immigrants from 131 countries to North America and Europe for
          education and work. It boasts of 150k users in less than 2 years.{" "}
          <br />
          <br />
          A two time founder, Olu moved to the US to build a software talent
          marketplace for AI talent, and saw first hand the hardship immigrants
          face migrating to a new country and the lack of legal and financial
          inclusion for immigrants.
          <br />
          <br />
          Before Vesti, Olu completed projects for Skills for jobs programs for
          Microsoft, Amazon Web Services, World Bank, Sterling Bank of Nigeria,
          Shell Petroleum Development company, First Bank of Nigeria, Youth
          bridge Foundation Ghana, Zambia & Johannesburg, Schwarzkopf Foundation
          Germany, GIZ, Ashoka as well as Entrepreneur Organization.
          <br />
          <br />
          Trained originally as a software engineer, Olu has a masters in CS
          with focus on Cybersecurity and AI. He completed the Non-profit
          strategy course at Philanthropy University, the Block Chain essentials
          specialization at IBM, and designed learning curriculum for top banks
          and Universities. As a builder/operator- he lives in Lagos, Dallas,
          and San Francisco.
        </>
      ),
    },
  },

  {
    img: bimbo,
    name: "Abimbola Amusan",
    title: "Co-founder/COO, Vesti",
    details: "Read Profile",
    profile: {
      heading: "SPEAKER PROFILE",
      name: "Abimbola Amusan |",
      title: "Co-founder/COO, Vesti",
      bio: (
        <>
          Abimbola Amusan is a distinguished Nigerian entrepreneur and the
          co-founder of Vesti, a groundbreaking platform that simplifies the
          process of obtaining work permits and visas for high-achieving
          professionals. With a strong background in computer science, Abimbola
          has leveraged his technical expertise to drive Vesti's innovative
          approach, making international mobility more accessible and efficient.
          His leadership in Vesti highlights his commitment to integrating
          technology with practical solutions for global mobility.
          <br />
          <br />
          Beyond his role at Vesti, Abimbola is recognized for his
          entrepreneurial ventures in the tech industry. His work often involves
          developing AI-driven solutions that address complex business
          challenges, streamline operations, and enhance decision-making. His
          contributions to various startups and technological innovations
          underscore his role as a key player in the intersection of technology
          and business.
          <br />
          <br />
          Abimbola's impact extends to social and economic development through
          his philanthropic efforts and mentorship programs. He actively
          supports educational and community development projects, aiming to
          empower the next generation of leaders and foster innovation. His
          dedication to both technological advancement and societal betterment
          reflects a comprehensive approach to making a significant difference
          in the world.
          <br />
          <br />
        </>
      ),
    },
  },
];

export const reasons = [
  {
    img2: greenImg,
    title: "Networking",
    description: "Connect with fellow movers and industry experts",
    background: "#F7FFF3",
    color: "#2B5219",
  },
  {
    img2: blueImg,
    title: "Opportunities",
    description: "Find valuable resources to simplify your move abroad",
    background: "#F6F7FF",
    color: "#16216B",
  },
  {
    img2: purImg,
    title: "Empower",
    description: "Take charge of your move abroad journey with confidence",
    background: "#FBF3FF",
    color: "#3F1951",
  },
  {
    img2: blueImg,
    title: "Community",
    description: "Make trasanctions in both Naira and USD",
    background: "#F6F7FF",
    color: "#16216B",
  },
  {
    img2: purImg,
    title: "Insights",
    description:
      "Gain insights on immigration options and navigate the process,",
    background: "#FBF3FF",
    color: "#3F1951",
  },
  {
    img2: greenImg,
    title: "Education",
    description: "Get expert advice and knowledge from industry experts",
    background: "#F7FFF3",
    color: "#2B5219",
  },
];

export const Question = [
  {
    title: "What is Vesti?",
    ans: "Vesti is a migration fintech company focused on global migration, aiming to simplify the movement of humans and capital across borders. Our goal is to become the leading provider of legal and financial services for the next one billion immigrants, from the initial decision to emigrate to their arrival in the destination country and beyond.",
  },
  {
    title: "What types of Visas does Vesti specialize in?",
    ans: "Vesti specializes in a range of visas crucial for startups, including H-1B visas, O-1 visas for extraordinary ability, E-2 investor visas, and more.",
  },
  {
    title: "How does Vesti tailor visa strategies for startups?",
    ans: "Vesti tailors visa strategies for startups by considering the startup's specific needs and the qualifications of its founders and employees. This may involve recommending visa types that are suited to startups, such as EB-5 visas for investment or O-1 visas for individuals with extraordinary ability. Vesti also assists with the complex paperwork and procedures involved in applying for visas.",
  },
  {
    title: "How can accelerators and VC firms partner with Vesti?",
    ans: "Accelerators and VC firms can partner with Vesti to streamline the visa process for their portfolio startups. Vesti's expertise can help startups secure the necessary visas for founders and employees, allowing them to focus on growing their businesses. This partnership can be mutually beneficial, as a smoother visa process can attract more talented individuals to startups.",
  },
];

export const options = [
  {
    label: "Online General Admission (Pre-order)",
    price: "$350",
    discount: "50% off",
    info: "Buy now to get 1 of the 500 available tickets",
    value: "OnlineGeneralAdmission",
  },
  {
    label: "Offline General Admission (Pre-order)",
    price: "$350",
    discount: "50% off",
    info: "Buy now to get 1 of the 500 available tickets",
    value: "OfflineGeneralAdmission",
  },
  {
    label: "VIP",
    price: "$950",
    value: "Vip",
  },
  {
    label: "Startup Exhibition",
    price: "$1,350",
    info: "Apply for startup discount to pay $660 (5 tickets available for selected startups only)",
    value: "StartupExhibition",
  },
];
