import React from "react";
import { data } from "./Data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Easygetstarted = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64 overflow-x-hidden ">
      <div className=" vlg:container vlg:mx-auto ">
        <p className=" text-[22px] mb-[2rem] leading-[42px] font-[600] md:text-[38px] xxl:text-[42px] max-w-[800px] mx-auto">
          Ready to revolutionise how you bank? Getting started is{" "}
          <span className="text-[#6A12AB]">as easy as 1-2-3</span>
        </p>

        <div className=" gap-4 hidden sm:flex justify-between ">
          {data.map((mydata) => {
            return (
              <div
                key={mydata?.id}
                className="bg-[#F8F6F5] p-6 w-[30%] rounded-[12px]"
              >
                <img src={mydata?.img} alt="" />
                <p className="my-2">{mydata?.title}</p>
                <p className="text-[#2B5219]">{mydata?.text}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="sm:hidden">
        <Slider {...settings}>
          {data.map((mydata) => {
            return (
              <div
                key={mydata?.id}
                className="bg-[#F8F6F5] p-6  rounded-[12px]"
              >
                <img src={mydata?.img} alt="" />
                <p className="my-2">{mydata?.title}</p>
                <p className="text-[#2B5219]">{mydata?.text}</p>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
