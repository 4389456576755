import greenImg from "../../assets/Event-Img/greenImg.png";
import blueImg from "../../assets/Event-Img/blueImg.png";
import purImg from "../../assets/Event-Img/purImg.png";
import vest1 from "../../assets/Event-Img/Vesti-1.png";
import vest2 from "../../assets/Event-Img/Vesti-2.png";
import vest3 from "../../assets/Event-Img/vesti-3.png";
import vest4 from "../../assets/Event-Img/Vesti-4.png";
import vest5 from "../../assets/Event-Img/vesti-5.png";
import vest6 from "../../assets/Event-Img/vesti-6.png";
import vest7 from "../../assets/Event-Img/Vesti-7.png";
import vest8 from "../../assets/Event-Img/vesti-8.png";
import vest9 from "../../assets/Event-Img/vesti-9.png";
import vest10 from "../../assets/Event-Img/01Event.jpeg";
import vest11 from "../../assets/Event-Img/02Event.jpeg";

// impact

export const impacts = [
  { num: "100+", details: "Number of events" },
  { num: "5000+", details: "Satisfied attendees" },
  { num: "15+", details: "Cities across the world" },
  { num: "230+", details: "Beneficiaries rewarded" },
];

// Reasons

export const reasons = [
  {
    img2: greenImg,
    title: "Networking",
    description: "Connect with fellow movers and industry experts",
    background: "#F7FFF3",
    color: "#2B5219",
  },
  {
    img2: blueImg,
    title: "Opportunities",
    description: "Find valuable resources to simplify your move abroad",
    background: "#F6F7FF",
    color: "#16216B",
  },
  {
    img2: purImg,
    title: "Empower",
    description: "Take charge of your move abroad journey with confidence",
    background: "#FBF3FF",
    color: "#3F1951",
  },
  {
    img2: blueImg,
    title: "Community",
    description: "Make trasanctions in both Naira and USD",
    background: "#F6F7FF",
    color: "#16216B",
  },
  {
    img2: purImg,
    title: "Insights",
    description:
      "Gain insights on immigration options and navigate the process,",
    background: "#FBF3FF",
    color: "#3F1951",
  },
  {
    img2: greenImg,
    title: "Education",
    description: "Get expert advice and knowledge from industry experts",
    background: "#F7FFF3",
    color: "#2B5219",
  },
];

// eventGallery

export const images = [
  vest10,
  vest11,
  vest1,
  vest4,
  vest7,
  vest2,
  vest5,
  vest3,
  vest6,
  vest8,
  vest9,
];
