import React, { useRef } from "react";
import videoImg from "../../assets/Event-Img/videoImg.png";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Success } from "../helpers/success/success";

export const EventHero = (props) => {
  const videoUrl =
    "https://storagefileforpathway.s3.amazonaws.com/videos/web/vesti_element+event+video.mp4";
  const videoRef = useRef();

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(e.target.value)) {
      props.setEmail(e.target.value);
      props.setErrorEmail(true);
    } else {
      props.setEmail(e.target.value);
      props.setErrorEmail(false);
    }
  };

  return (
    <div
      className={`relative justify-center px-4 md:px-8 lg:px-20 xxl:px-32  vlg:px-64 bg-no-repeat bg-cover bg-center rounded-lg min-h-screen flex items-center`}
    >
      <div className="absolute inset-0 flex justify-center items-center opacity-75">
        <video
          ref={videoRef}
          src={videoUrl}
          type="video/mp4"
          repeat={true}
          resizeMode={"cover"}
          onRateChange={1.0}
          ignoreSilentSwitch={"obey"}
          className="object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-[#060E42A3] bg-opacity-50"></div>
      </div>

      <div className="text-white md:pr-[15%] z-10 flex items-center w-full gap-6 leading-[6]">
        <div className="lg:w-[42%]">
          <h1 className="text-5xl lg:w-  leading-[1.2] mt-12">
            Explore events and discover how you can benefit
          </h1>
          <div>
            <div className="flex bg-white text-black py-3 px-4 justify-between gap-7 text-base rounded-md mt-2">
              <input
                type="email"
                placeholder="Enter your email"
                value={props.email}
                onChange={handleEmailChange}
                className="outline-none"
              />
              <button
                disabled={props.email && !props.errorEmail ? false : true}
                className={`bg-vestigreen text-white  py-4 px-4 md:py-2 lg:px-4 sm:px-2 rounded-md ${
                  props.email && !props.errorEmail
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                onClick={() => {
                  props.email && !props.errorEmail
                    ? props.handleSubmit()
                    : props.setSubscriptionModal(false);
                }}
              >
                Get updates
              </button>
            </div>
            {props.errorEmail && props.email ? (
              <p className="text-[12px] text-red-800 font-[600]">
                Invalid Email
              </p>
            ) : (
              <p></p>
            )}
          </div>
        </div>
        <img
          src={videoImg}
          alt="Play video"
          className="absolute left-[40%] lg:left-[46%] cursor-pointer z-100"
          onClick={handleVideoClick}
        />
      </div>
      <Simplemodal
        open={props.subscriptionModal}
        onClick={() => {
          props.setSubscriptionModal(false);
        }}
      >
        {props.message == "You have previously subscribed" ||
        props.message == "An Error Occured" ? (
          <>
            <Success
              image={errorsvg}
              type="error"
              title={props.message}
              subtitle=""
              button="Close"
              onClick={() => {
                props.setSubscriptionModal(false);
              }}
            />
          </>
        ) : (
          <>
            <Success
              title="Events subscribed successfully"
              subtitle=""
              button="Close"
              onClick={() => {
                props.setSubscriptionModal(false);
              }}
            />
          </>
        )}
      </Simplemodal>
    </div>
  );
};
