import React from 'react'
import gateImg from '../../assets/ProsConfImg/secImg.png'
import gateDot from '../../assets/ProsConfImg/gateDot.png'
import { Link as AnchorLink } from "react-scroll";


export const Gateway = () => {
  return (
    <div id="gatewaySection" className='flex flex-col items-center justify-center pt-[16%] sm:pt-[12%] md:pt-[12%] xl:pt-[12%] xlg:pt-[12%] px-4 md:px-[4%] xl:px-[6%] xlg:px-[6%] pb-6'>
        <div className='relative md:flex xl:flex xl:items-center xlg:flex xlg:items-center md:gap-[6%] xl:gap-[10%] xlg:gap-[10%] sm:pt-[4%] sm:pl-[4%] md:pt-[4%] md:pl-[4%] xl:pt-[4%] xl:pl-[4%] xlg:pt-[4%] xlg:pl-[4%]'>
                <img src={gateDot} alt="" className='absolute top-0 left-0 z-0 hidden sm:block'/>
                <img src={gateImg} alt="" className='rounded-md z-10 w-[100%] md:w-[50%] ' />
            <div className='grid'>
                <h1 className='pt-4 text-[26px] sm:text-[30px] md:text-[30px] xl:text-[30px] xlg:text-[30px] font-bold'>Your Gateway to Global Prosperity</h1>
                <p className='text-[#AEB4BE] leading-8 pt-2'>In today's globalized world, migration has become a key driver of opportunity and growth for 
                individuals and businesses alike. The Prosper Conference is your gateway to understanding the 
                transformative power of migration, exploring new horizons, and seizing international opportunities.</p>

                <div className='flex items-center gap-[2rem] sm:gap-[6rem] xl:gap-12 md:gap-8 xlg:gap-16 py-2 xlg:py-4'>
                    <div className='grid'>
                        <h3 className='font-bold text-[26px] sm:text-[28px] md:text-[28px] xl:text-[28px] xlg:text-[28px]'>10+</h3>
                        <p className='text-[#AEB4BE]'><small>Speakers</small></p>
                    </div>
                    <div className='grid'>
                        <h3 className='font-bold text-[26px] sm:text-[28px] md:text-[28px] xl:text-[28px] xlg:text-[28px]'>25+</h3>
                        <p className='text-[#AEB4BE]'><small>Sessions</small></p>
                    </div>
                    <div className='grid'>
                        <h3 className='font-bold text-[24px] sm:text-[28px] md:text-[28px] xl:text-[28px] xlg:text-[28px]'>500+</h3>
                        <p className='text-[#AEB4BE]'><small>Attendees</small></p>
                    </div>
                </div>

                <div className='sm:flex sm:items-center md:flex md:items-center xl:flex xl:items-center xlg:flex xlg:items-center gap-4 xlg:gap-6 pt-4 text-[14px]'>
                    {/* <button className=' border rounded-md border-vestigreen bg-[#FFFFFF] text-vestigreen py-3 px-12 md:px-6 xl:px-8 w-[100%] mb-2'>Partner with us</button> */}

                    <AnchorLink
                    spy={true}
                    smooth={true}
                    to="registerForm"
                    className='bg-vestigreen rounded-md text-[#FFFFFF] py-4 px-12 md:px-6 xl:px-8 w-[50%] mb-2 cursor-pointer'>
                    Register now
                    </AnchorLink>
                </div>
        </div>
        </div>
    </div>
  )
}
