import React from "react";
import "./formatted.css";

const FormattedTextComponent = ({ text }) => {
  const formattedText = text
    .replace(/^(\d+\.)/gm, (match, content) => `${content}`)
    // .replace(
    //   /(?<= )-\s*(.*)/gm,
    //   (match, content) => `<p><strong>- </strong> ${content}</p>`,
    // )
    .replace(/\s*(.*)/gm, (match, content) => `<p>${content}</p>`)

    .replace(/\.(?=\s+-)/gm, '.</p><p style="margin-top: 20px">'); // Add margin-top
  return (
    <div
      className="FormattedTextComponent  bg-[#fff] px-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]"
      dangerouslySetInnerHTML={{ __html: formattedText }}
      // style={{ fontSize: '15px' }}
    />
  );
};

export default FormattedTextComponent;
