import React, { useState } from "react";
import arrow from "../../assets/logo-icons/arrowslant.svg";
const VisaTabComponent = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="flex flex-col justify-center items-center mt-12 w-full">
      <div className="flex flex-row gap-4 p-4 flex-wrap lg:flex-nowrap md:flex-nowrap justify-center rounded-full bg-[#DFFFD0]">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`px-4 py-2 mr-2 rounded ${
              activeTab === tab.id
                ? "bg-[#67A948] text-white rounded-full"
                : "bg-transparent text-black"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            style={{ display: activeTab === tab.id ? "block" : "none" }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisaTabComponent;

export const TabContent = (props) => {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 md:py-20 lg:w-[90%]">
        <div className="flex flex-col lg:w-[100%]">
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-xl md:text-3xl lg:text-2xl pb-6 font-fontReg lg:w-[90%]">
              {props.title}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.description}
            </p>
          </div>
          <div className="flex flex-row justify-start gap-4 items-center cursor-pointer">
            <p className="text-[#67a948] ">Learn more</p>{" "}
            <img className="" src={arrow} alt="img" />
          </div>
        </div>

        <div className="lg:w-[100%]">
          <img src={props.image} alt="img" />
        </div>
      </div>
    </div>
  );
};
