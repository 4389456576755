import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
// import { GoPrimitiveDot } from "react-icons/go";

const MigrationCard = ({ img, date, title, desc, link, category }) => {
  return (
    <div className="bg-[#F7F8FA] py-5 px-7 w-full rounded-[20px]  border border-[#c3bebe] flex flex-col justify-between ">
      {/* <div>{category === "MIGRATION NEWS" ? <div></div> : <img src={img} alt='' className="rounded-md w-full h-[13rem] object-cover" />}</div> */}

      <div className=" h-[13rem] mt-4">
        <h1 className="text-[#67A948] font-fontReg">
          {category === "MIGRATION NEWS" ? "MIGRATION FRIES" : category}
        </h1>

        <h1 className="text-[#14290A] text-base py-3 font-fontReg">{title}</h1>
        <p className="font-fontReg text-[#4F4F4F] text-sm mb-3">{date}</p>

        <div
          className="text-[#2B5219] text-sm font-fontLight"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </div>

      <div className="flex items-center text-[#67A948] text-base font-fontReg pt-4">
        <Link onClick={() => window.open(link)} className="flex items-center">
          Continue reading{" "}
        </Link>
        <BsArrowUpRight />
      </div>
    </div>
  );
};

export default MigrationCard;
