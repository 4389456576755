import React from 'react'
import impactImg from '../../assets/Event-Img/impactImg.png'
import { impacts } from './dataCards'

export const Impact = () => {
  return (
    <div className='bg-[#F7FFF3] mt-10 px-4 md:px-8 lg:px-20 xxl:px-32  vlg:px-64'>
        <div className='flex flex-col lg:flex-row items-center justify-center gap-[6rem] py-20 '>
            <div>
                <img src={impactImg} alt="" className='object-fill rounded-md sm:w-full md:w-auto'/>
            </div>
            <div className='grid'>
                <div className='grid'>
                    <h1 className='font-bold text-3xl'>Milestones and Impact</h1>
                    <p className='text-[20px] w-[80%]'>Some of the milestones and impact of our past events</p>
                </div>
                <div className='grid lg:grid-cols-2 gap-x-14'>
                    {impacts.map(({num, details}, index) => {
                        return <div key={index} className='grid mt-10 leading-[1.5] '>
                                <h2 className='font-semibold text-[26px]'>{num}</h2>
                                <p className='text-[20px]'>{details}</p>
                            </div>
                    })} 
                </div>
            </div>
        </div>
    </div>
  )
}
