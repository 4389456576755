import React from "react";
import Miai from "../../assets/miai/miaiBg.png";
import { FaWindowMinimize } from "react-icons/fa";
import { LuMinimize } from "react-icons/lu";
import { CgMaximize } from "react-icons/cg";

const Miainav = (props) => {
  const minimize = () => {
    props.setHeight("5vh");
    props.setSmallHeight("6vh");
  };

  const maximize = () => {
    props.setHeight("100vh");
    props.setSmallHeight("50vh");
  };
  return (
    <div
      // className="h-[12vh] bg-[#16216B] flex flex-col justify-end pb-3 vlg:pb-8"
      className={`bg-[#16216B] flex flex-col justify-end pb-3 vlg:pb-8 ${
        props.height === "100vh" ? "h-[8vh] md:h-[12vh]" : "h-[8vh] pt-3"
      }   `}
    >
      <div
        className={`px-[15px] ${
          props.height === "100vh" ? "pt-10" : "pt-0"
        }   text-sm text-[#fff] flex justify-between items-center`}
      >
        <div className="flex items-center gap-2">
          <img
            // className="w-[25px] h-[25px]"
            className={`  h-[30px]  w-[30px] `}
            src={Miai}
            alt="Miai"
          />
          {/* <p>Miai (Pronounced Maya)</p> */}
          <p>Miai ™ (Pronounced Maya)</p>
        </div>

        <div className="cursor-pointer">
          {props.height === "100vh" ? (
            <div
              onClick={() => {
                minimize();
              }}
            >
              <div className="bg-[#66AA48] px-2 pb-2">
                <FaWindowMinimize className="" />
                {/* <LuMinimize /> */}
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                maximize();
              }}
            >
              <CgMaximize className="text-[22px]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Miainav;
