// startup review

import avatarImg from '../../assets/Partnership-Img/AvatarCard.png'
import starImg from '../../assets/Partnership-Img/starImg.png'

export const StartRev = [
    {img: avatarImg, company:'Joshua Shittabey', name:'Jane Smith,', location:'XYZ Accelerator', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#F7FFF3'},
    {img: avatarImg, company:'Joshua Shittabey', name:'Mainstack', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#F6F7FF'},
    {img: avatarImg, company:'Joshua Shittabey', name:'Wale Yusuff,', location:'AltSchool Africa', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#FBF3FF'},
    {img: avatarImg, company:'Joshua Shittabey', name:'Gova', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#F6F7FF'},
    {img: avatarImg, company:'Joshua Shittabey', name:'Jane Smith,', location:'XYZ Accelerator', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#FBF3FF'},
    {img: avatarImg, company:'Joshua Shittabey', name:'Jane Smith,', location:'XYZ Accelerator', details:'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.', background:'#F7FFF3'}
]


// Benefits

export const BenefitCard = [
    {image: starImg, title: 'Competitive Edge', details:'Offer a valuable perk that attracts top-tier startups to your program.'},
    {image: starImg, title: 'Expert Guidance', details:'Access to experienced immigration attorneys specializing in startup visas.'},
    {image: starImg, title: 'Enhanced Support', details:'Ensure your startups have the immigration assistance they need to succeed.'},
    {image: starImg, title: 'Customized Solutions', details:'Personalized visa strategies tailored to their specific needs and goals.'},
    {image: starImg, title: 'Reduced Risks', details:'Mitigate legal risks and compliance issues related to immigration for your startups.'},
    {image: starImg, title: 'Increased Growth', details:'Less time spent on immigration issues, more on business growth and innovation.'}
]

// VestiQues

export const Question = [
    {title: 'What is Vesti?', ans: 'Vesti is a migration fintech company focused on global migration, aiming to simplify the movement of humans and capital across borders. Our goal is to become the leading provider of legal and financial services for the next one billion immigrants, from the initial decision to emigrate to their arrival in the destination country and beyond.'},
    {title: 'What types of Visas does Vesti specialize in?', ans: 'Vesti specializes in a range of visas crucial for startups, including H-1B visas, O-1 visas for extraordinary ability, E-2 investor visas, and more.'},
    {title: 'How does Vesti tailor visa strategies for startups?', ans: "Vesti tailors visa strategies for startups by considering the startup's specific needs and the qualifications of its founders and employees. This may involve recommending visa types that are suited to startups, such as EB-5 visas for investment or O-1 visas for individuals with extraordinary ability. Vesti also assists with the complex paperwork and procedures involved in applying for visas."},
    {title: 'How can accelerators and VC firms partner with Vesti?', ans: "Accelerators and VC firms can partner with Vesti to streamline the visa process for their portfolio startups. Vesti's expertise can help startups secure the necessary visas for founders and employees, allowing them to focus on growing their businesses. This partnership can be mutually beneficial, as a smoother visa process can attract more talented individuals to startups.",}
]
    
