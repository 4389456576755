import triple from "../../assets/images/triple.png";

const CuttingEdge = (props) => {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 lg:w-[90%]">
        <div className="flex flex-col ">
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.title}
              <span className="text-[#67A948]">{props.visaName}</span>
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.firstDescription}
            </p>
          </div>
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.secondDescriptionTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.secondDescription}
            </p>
          </div>
          <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.title}
              <span className="text-[#67A948]">{props.visaName}</span>
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.firstDescription}
            </p>
          </div>
          {/* <div className="lg:w-[100%] lg:pt-0 pt-10">
            <h1 className="text-[#14290A] text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.thirdDescriptionTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.thirdDescription}
            </p>
          </div> */}
        </div>

        {/* <div className="lg:w-[100%]"> */}
        <div>
          <img className="" src={triple} alt="techstars" />
        </div>
      </div>
    </div>
  );
};

export default CuttingEdge;
