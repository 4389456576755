import React, { useState } from "react";
import { H1bPetitionHero } from "../H1bPetHero";
import NavBar from "../../../containers/NavBar";
import { H1bUser } from "../H1bUser";
import { PetitionForm } from "./petitionForm";
import Footer from "../../../containers/Footer";
import { Faqs } from "../H1bFaqs";
import Features from "../../Features";
import history from "../../../assets/images/history.svg";
import History from "../../visaComponents/history";

export const OnsuccessH1bPetition = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <NavBar />
      <H1bPetitionHero />
      {/* <div
        className=" lg:hidden my-[5rem] flex flex-col justify-center items-center px-4 sm:px-[6%] md:px-[6%]"
      >
        <p className="text-vestigreen text-[22px] font-[600]">
          Switch to desktop
        </p>
        <p className="text-[#14290a] text-center mt-1">
          This feature is optimized for desktop. Switch to desktop to fill and
          upload petition.
        </p>
      </div> */}

      <div className="">
        <PetitionForm />
        <H1bUser />
        <Faqs />

        <History
          icon={history}
          alt="Img"
          title="We have successfully filled over 240 H1B visa to date and the number continues to grow."
          buttonTitle="See if you Qualify"
          open={open}
          setOpen={setOpen}
          // onClick={}
        />
        <div className="my-12">
          <Features />
        </div>
      </div>

      <Footer />
    </div>
  );
};
