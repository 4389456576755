import React from "react";
import thumbsup from "../../assets/images/doublethumpsup.png";
import { Button } from "@material-tailwind/react";
import "../../components/oeligibility/Oeligibility.scss";
import { openLink } from "../../helpers/helpers";

const NITDABanner = (props) => {
  return (
    <div className="bg-secondary w-full pt-24 md:pt-10 lg:pt-28 text-white">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 gap-20">
          <div className="col-span-1 mt-10 md:mt-0">
            <h1 className="text-2xl font-fontSemi mt-20 lg:text-3xl xl:text-4xl pb-5 lg:w-[100%] lg:font-fontBold !leading-[50px] lg:!leading-[60px]">
              <span className="text-[#67A948]">
                {" "}
                NITDA CO-CREATE WEST AFRICA{" "}
              </span>{" "}
              <span className="text-[#f4f7f3]"> 2024</span>{" "}
            </h1>
            <p className="text-lg pb-12 lg:w-[80%] font-fontLight">
              We are looking to work with you to meet your migration needs. Just
              answer 7 simple questions to unlock valuable resources tailored to
              your immigration journey. Plus, you'll get exclusive access to
              migration expert insights, guides, and a chance to speak with our
              immigration attorneys on a clarity call. Complete the survey in
              just 30 seconds to get started!
            </p>
            <div className="flex flex-row justify-between gap-4">
              {/* <Button
                onClick={() =>
                  openLink(
                    "https://calendly.com/vesti/h1b-visa-2024-stream",
                    "_blank"
                  )
                }
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                Speak to an Attorney
              </Button> */}
              <Button
                onClick={() => props.setOpen(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
              >
                click to Unlock
              </Button>
            </div>
          </div>
          <div className="col-span-1">
            <img className="w-full" src={thumbsup} alt="Big Smile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NITDABanner;
